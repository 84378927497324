import Vue from 'vue'
import VueRouter from 'vue-router'


import Dashboard from "@/views/Dashboard/Dashboard.vue"


import CategoryList from "@/views/Category/List.vue"
import AddCategory from "@/views/Category/Add.vue"
import EditCategory from "@/views/Category/Add.vue"

import StateList from "@/views/State/List.vue"
import AddState from "@/views/State/Add.vue"
import EditState from "@/views/State/Add.vue"

import CityList from "@/views/City/List.vue"
import AddCity from "@/views/City/Add.vue"
import EditCity from "@/views/City/Add.vue"

import Login from "@/views/Users/Login.vue"
import AddUser from "@/views/Users/Add.vue"
import UserList from "@/views/Users/List.vue"
import EditUser from "@/views/Users/Add.vue"
import DisplayUser from "@/views/Users/Display.vue"
import ProfileUser from "@/views/Users/Profile.vue"
import EditProfile from "@/views/Users/EditProfile.vue"

import AddService from "@/views/Service/Add.vue"
import EditService from "@/views/Service/Edit.vue"
import ServiceList from "@/views/Service/List.vue"
import DisplayService from "@/views/Service/Display.vue"


import AttributeList from "@/views/Attributes/List.vue"
import AddAttribute from "@/views/Attributes/Add.vue"
import EditAttribute from "@/views/Attributes/Add.vue"

import AddOccasions from "@/views/Occasions/Add.vue"
import EditOccasions from "@/views/Occasions/Add.vue"
import OccasionsList from "@/views/Occasions/List.vue"
import DisplayOccasions from "@/views/Occasions/Display.vue"

import AddTour from "@/views/Tour/Add.vue"
import EditTour from "@/views/Tour/Add.vue"
import TourList from "@/views/Tour/List.vue"

import AddResturant from "@/views/Resturant/Add.vue"
import EditResturant from "@/views/Resturant/Edit.vue"
import ResturantList from "@/views/Resturant/List.vue"
import DisplayResturant from "@/views/Resturant/Display.vue"

import AddMenu from "@/views/Menu/Add.vue"
import EditMenu from "@/views/Menu/Add.vue"
import MenuList from "@/views/Menu/List.vue"


import AddMeal from "@/views/Meal/Add.vue"
import EditMeal from "@/views/Meal/Add.vue"
import MealList from "@/views/Meal/List.vue"

import AddBanners from "@/views/Banners/Add.vue"
import EditBanners from "@/views/Banners/Add.vue"
import BannersList from "@/views/Banners/List.vue"

import ReviewList from "@/views/Review/List.vue"

import BookingList from "@/views/Booking/List.vue"
import CancelList from "@/views/Booking/CancelList.vue"
import AcceptList from "@/views/Booking/AcceptList.vue"


import CustomerList from "@/views/Customer/List.vue"

import AddRole from "@/views/Role/Add.vue"
import RoleList from "@/views/Role/List.vue"

import NoPermission from "@/views/NoPermission.vue"
import NotFound from "@/views/NotFound.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/no-permission',
    name: 'no-permission',
    component: NoPermission
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/category/list',
    name: 'category-list',
    component: CategoryList,
    meta: {
      permissions: ['serviceCategories_manager']
    }, 
  },
  {
    path: '/category/add',
    name: 'add-category',
    component: AddCategory,
    meta: {
      permissions: ['serviceCategories_manager']
    }, 
  },
  {
    path: '/category/edit/:id',
    name: 'edit-category',
    component: EditCategory,
    meta: {
      permissions: ['serviceCategories_manager']
    }, 
  },



  {
    path: '/state/list',
    name: 'state-list',
    component: StateList,
    meta: {
      permissions: ['countries_manager']
    }, 
  },
  {
    path: '/state/add',
    name: 'add-state',
    component: AddState,
    meta: {
      permissions: ['countries_manager']
    }, 
  },
  {
    path: '/state/edit/:id',
    name: 'edit-state',
    component: EditState,
    meta: {
      permissions: ['countries_manager']
    }, 
  },

  {
    path: '/city/list',
    name: 'city-list',
    component: CityList,
    meta: {
      permissions: ['cities_manager']
    }, 
  },
  {
    path: '/city/add',
    name: 'add-city',
    component: AddCity,
    meta: {
      permissions: ['cities_manager']
    }, 
  },
  {
    path: '/city/edit/:id',
    name: 'edit-city',
    component: EditCity,
    meta: {
      permissions: ['cities_manager']
    }, 
  },


  {
    path: '/user/list',
    name: 'user-list',
    component: UserList,
    meta: {
      permissions: ['users_manager']
    }, 
  },
  {
    path: '/user/add',
    name: 'add-user',
    component: AddUser,
    meta: {
      permissions: ['users_manager']
    }, 
  },
  {
    path: '/user/edit/:id',
    name: 'edit-user',
    component: EditUser,
    meta: {
      permissions: ['users_manager']
    }, 
  },
  {
    path: '/user/display/:id',
    name: 'display-user',
    component: DisplayUser,
    meta: {
      permissions: ['users_manager']
    }, 
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileUser
  },
  {
    path: '/profile/edit',
    name: 'edit-profile',
    component: EditProfile
  },


  {
    path: '/service/list',
    name: 'service-list',
    component: ServiceList,
    meta: {
      permissions: ['services_manager']
    }, 
  },
  {
    path: '/service/add',
    name: 'add-service',
    component: AddService,
    meta: {
      permissions: ['services_manager']
    }, 
  },
  {
    path: '/service/edit/:id',
    name: 'edit-service',
    component: EditService,
    meta: {
      permissions: ['services_manager']
    }, 
  },
  {
    path: '/service/display/:id',
    name: 'display-service',
    component: DisplayService,
    meta: {
      permissions: ['services_manager']
    }, 
  },

  {
    path: '/attributes/list',
    name: 'attributes-list',
    component: AttributeList,
    meta: {
      permissions: ['attributes_manager']
    }, 
  },
  {
    path: '/attributes/add',
    name: 'add-attributes',
    component: AddAttribute,
    meta: {
      permissions: ['attributes_manager']
    }, 
  },
  {
    path: '/attribute/edit/:id',
    name: 'edit-attributes',
    component: EditAttribute,
    meta: {
      permissions: ['attributes_manager']
    }, 
  },

  {
    path: '/occasions/list',
    name: 'occasions-list',
    component: OccasionsList,
    meta: {
      permissions: ['occasions_manager']
    }, 
  },
  {
    path: '/occasions/add',
    name: 'add-occasions',
    component: AddOccasions,
    meta: {
      permissions: ['occasions_manager']
    }, 
  },
  {
    path: '/occasions/edit/:id',
    name: 'edit-occasions',
    component: EditOccasions,
    meta: {
      permissions: ['occasions_manager']
    }, 
  },
  {
    path: '/occasions/display/:id',
    name: 'display-occasions',
    component: DisplayOccasions,
    meta: {
      permissions: ['occasions_manager']
    }, 
  },


  {
    path: '/tour/list',
    name: 'tour-list',
    component: TourList,
    meta: {
      permissions: ['tourGuides_manager']
    }, 
  },
  {
    path: '/tour/add',
    name: 'add-tour',
    component: AddTour,
    meta: {
      permissions: ['tourGuides_manager']
    },
  },
  {
    path: '/tour/edit/:id',
    name: 'edit-tour',
    component: EditTour,
    meta: {
      permissions: ['tourGuides_manager']
    },
  },

  {
    path: '/resturant/list',
    name: 'resturant-list',
    component: ResturantList,
    meta: {
      permissions: ['resturants_manager']
    },
  },
  {
    path: '/resturant/add',
    name: 'add-resturant',
    component: AddResturant,
    meta: {
      permissions: ['resturants_manager']
    },
  },
  {
    path: '/resturant/edit/:id',
    name: 'edit-resturant',
    component: EditResturant,
    meta: {
      permissions: ['resturants_manager']
    },
  },
  {
    path: '/resturant/display/:id',
    name: 'display-resturant',
    component: DisplayResturant,
    meta: {
      permissions: ['resturants_manager']
    },
  },


  {
    path: '/menu/list/:id',
    name: 'menu-list',
    component: MenuList,
    meta: {
      permissions: ['resturants_manager']
    },
  },
  {
    path: '/menu/add/:id',
    name: 'add-menu',
    component: AddMenu,
    meta: {
      permissions: ['resturants_manager']
    },
  },
  {
    path: '/menu/edit/:id',
    name: 'edit-menu',
    component: EditMenu,
    meta: {
      permissions: ['resturants_manager']
    },
  },

  
  {
    path: '/meal/list/:resturant_id/:id',
    name: 'meal-list',
    component: MealList,
    meta: {
      permissions: ['resturants_manager']
    },
  },
  {
    path: '/meal/add/:resturant_id/:id',
    name: 'add-meal',
    component: AddMeal,
    meta: {
      permissions: ['resturants_manager']
    },
  },
  {
    path: '/meal/edit/:resturant_id/:id',
    name: 'edit-meal',
    component: EditMeal,
    meta: {
      permissions: ['resturants_manager']
    },
  },


  {
    path: '/banners/list',
    name: 'banners-list',
    component: BannersList,
    meta: {
      permissions: ['banners_manager']
    },
  },
  {
    path: '/banners/add',
    name: 'add-banners',
    component: AddBanners,
    meta: {
      permissions: ['banners_manager']
    },
  },
  {
    path: '/banners/edit/:id',
    name: 'edit-banners',
    component: EditBanners,
        meta: {
      permissions: ['banners_manager']
    },
  },

  {
    path: '/review/list/:section_id/:id',
    name: 'review-list',
    component: ReviewList
  },


  {
    path: '/booking/list/:section_id/:id',
    name: 'booking-list',
    component: BookingList
  },
  {
    path: '/booking/cancel-list/:section_id/:id',
    name: 'cancel-list',
    component: CancelList
  },
  {
    path: '/booking/accept-list/:section_id/:id',
    name: 'accept-list',
    component: AcceptList
  },


  {
    path: '/customer/list',
    name: 'customer-list',
    component: CustomerList,
    meta: {
      permissions: ['customers_manager']
    },
  },


  {
    path: '/role/add',
    name: 'add-role',
    component: AddRole,
    meta: {
      permissions: ['roles_manager']
    },
  },
  {
    path: '/role/list',
    name: 'role-list',
    component: RoleList,
    meta: {
      permissions: ['roles_manager']
    },
  },

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router



router.beforeEach((to, from, next) => {
  const auth = sessionStorage.getItem("auth")
  // console.log(auth)
  if ( (to.path != '/login' && auth == "false") || (to.path != '/login' && auth == null)) 
      next({ name: 'login' })
  else 
      next()

  let user_permission = JSON.parse(sessionStorage.getItem("permission"))

  if (to.meta.permissions && to.meta.permissions.length > 0) {
    let isAllowed = user_permission.some(p => {
      return to.meta.permissions.includes(p.name);
    });
    if (! isAllowed) return next('/no-permission')
  }

})