<template>
    <div id="list-review" class="list-review list">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('review.list') }}
            </div>
            <div class="card-body">
                <div v-if="load">
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" :reverse="direction"
                            single-line hide-details></v-text-field>
                        <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table :headers="$t('review.headers')" :items="data" :search="search" class="my-table">
                        <template v-slot:top>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-spacer></v-spacer>
                                    <v-card-title class="justify-content-center" style="padding-top: 30px">
                                        {{ $t('confrim_delete') }}
                                    </v-card-title>
                                    <v-card-actions style="padding-bottom: 30px">
                                        <v-spacer></v-spacer>
                                        <v-btn color="gray" text @click="closeDelete">{{ $t('cancel') }}
                                        </v-btn>
                                        <v-btn color="red darken-1" text @click="deleteItemConfirm">{{ $t('confrim') }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                        <template v-slot:[`item.mangement`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="displayItem(item)">
                                    <i class="fa fa-eye"></i>
                                </div>
                                <div @click="deleteItem(item)" v-if="havePermission">
                                    <i class="fa fa-trash"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <div class="row justify-content-center" style="top: 15px; position: relative; direction: ltr;">
                                <v-switch v-model="item.status" inset color="green" @click="statusItem(item)"
                                    :disabled="!havePermission"></v-switch>
                            </div>
                        </template>
                        <template v-slot:[`item.rating`]="{ item }">
                            <div class="row justify-content-center" style="top: 15px; position: relative; direction: ltr;">
                                <v-rating style="top: -10px; position: relative;" :value="Number(item.rating)" color="amber"
                                    length="5" background-color="grey" readonly size="25" half-increments></v-rating>
                            </div>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="displayDialog" max-width="700px" persistent>
                        <v-card>
                            <v-spacer></v-spacer>
                            <v-card-title class="justify-content-start"
                                style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 40px;">
                                {{ $t('review.details') }}
                            </v-card-title>
                            <div>
                                <v-simple-table style="margin: 10px 30px 30px; padding: 0px; text-align: justify;">
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td>{{ $t('review.fname') }}</td>
                                                <td>{{ display.user.first_name }} {{ display.user.last_name }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('review.title') }}</td>
                                                <td>{{ display.title }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('review.content') }}</td>
                                                <td>{{ display.review }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                            <v-card-actions style="justify-content: end; padding-bottom: 30px;margin-left: 20px;">
                                <v-btn color="primary" @click="displayDialog = false">{{ $t('close') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
                <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                    {{ $t('delete_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                    {{ $t('error_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="green" text v-model="change_snackbar" :timeout="3000">
                    {{ $t('change_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="change_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'reviewList',
    components: {
        Breadcrumbs
    },
    data: () => ({
        search: '',
        lang: '',
        direction: false,
        data: [],
        load: false,
        dialogDelete: false,
        editedIndex: -1,
        snackbar: false,
        error_snackbar: false,
        change_snackbar: false,
        delete: '',
        bread_en: [],
        bread_ar: [],
        display: null,
        displayDialog: false,
        section_id: '',
        id: '',
        my_url: '',
        main_name: '',
        my_permission: [],
        havePermission: false,
    }),
    watch: {
        dialogDelete(val) {
            val || this.closeDelete()
        },
        section_id(section) {
            if (section == 1) {
                this.bread_en = [
                    {
                        text: 'Restaurant',
                        disabled: false,
                        href: '/resturant/list',
                    },
                    {
                        text: 'Review',
                        disabled: true,
                        href: '',
                    }
                ]
                this.bread_ar = [
                    {
                        text: 'مطعم',
                        disabled: false,
                        href: '/resturant/list',
                    },
                    {
                        text: 'المراجعات',
                        disabled: true,
                        href: '',
                    }
                ]
            }
            else if (section == 2) {
                this.bread_en = [
                    {
                        text: 'Public Service',
                        disabled: false,
                        href: '/service/list',
                    },
                    {
                        text: 'Review',
                        disabled: true,
                        href: '',
                    }
                ]
                this.bread_ar = [
                    {
                        text: 'خدمات عامة',
                        disabled: false,
                        href: '/service/list',
                    },
                    {
                        text: 'المراجعات',
                        disabled: true,
                        href: '',
                    }
                ]
            }
            else if (section == 3){
                this.bread_en = [
                    {
                        text: "Tour Guide & Translator",
                        disabled: false,
                        href: '/tour/list',
                    },
                    {
                        text: 'Review',
                        disabled: true,
                        href: '',
                    }
                ]
                this.bread_ar = [
                    {
                        text: 'دليل سياحي وترجمة',
                        disabled: false,
                        href: '/tour/list',
                    },
                    {
                        text: 'المراجعات',
                        disabled: true,
                        href: '',
                    }
                ]
            }
        }
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en') {
                this.direction = false
            }
            else {
                this.direction = true
            }
        },
        getData() {
            if (this.section_id == 1)
                this.my_url = "/api/v1/admin/review/resturant/"
            else if (this.section_id == 2)
                this.my_url = "/api/v1/admin/review/service/"
            else
                this.my_url = "/api/v1/admin/review/guide/"

            this.axios.get(this.$store.state.url + this.my_url + this.id + "/get?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    if (res.data.status == "Success") {
                        this.data = res.data.list.reviews.reverse()
                        this.data.forEach(item => item.status = Number(item.status))
                    }
                    // console.log(res.data.list)
                });
        },
        deleteItem(item) {
            console.log("Delete: " + item.id)
            this.editedIndex = this.data.indexOf(item)
            this.delete = item
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.sendIdDeleted()
            this.closeDelete()
        },
        closeDelete() {
            this.dialogDelete = false
        },
        sendIdDeleted() {
            this.axios.delete(this.$store.state.url + this.my_url + this.id + "/remove/" + this.delete.id)
                .then((res) => {
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.data.splice(this.editedIndex, 1)
                        this.snackbar = true
                    }
                })
                .catch(error => {
                    this.error_snackbar = true
                })
        },
        statusItem(item) {
            const formData = new FormData()
            if (this.section_id == 1)
                formData.append("type", "resturant")
            else if (this.section_id == 2)
                formData.append("type", "service")
            else
                formData.append("type", "guide")
            formData.append("status", Number(item.status))
            formData.append("lang", "en")
            this.axios.post(this.$store.state.url + this.my_url + "update-status/" + item.id, formData)
                .then((res) => {
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.change_snackbar = true
                    }
                }).catch(error => {
                    this.error_snackbar = true
                })
        },
        displayItem(item) {
            this.display = item
            this.displayDialog = true
        },
        initPermission() {
            this.my_permission = JSON.parse(sessionStorage.getItem("permission"))
            if (this.section_id == 1) {
                this.havePermission = this.my_permission.some(item => item.name == 'resturants_reviews_manager')
            }
            else if (this.section_id == 2) {
                this.havePermission = this.my_permission.some(item => item.name == 'services_reviews_manager')
            }
            else if (this.section_id == 3) {
                this.havePermission = this.my_permission.some(item => item.name == 'tourGuides_reviews_manager')
            }
        }
    },
    mounted() {
        this.lang = localStorage.getItem('lang')
        this.id = this.$route.params.id
        this.section_id = this.$route.params.section_id
        this.getData()
        this.initDir()
        this.initPermission()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.review {
    border: 1px solid var(--v-secondary-base);
    border-radius: 30px;
    padding: 5px 10px;
    margin: 5px 0px;
}

.translator {
    border: 1px solid var(--v-primary-base);
    border-radius: 30px;
    padding: 5px 10px;
    margin: 5px 0px;
}
</style>
