
<template>
    <div class="dashboard">

        <!-- header -->
        <div class="container">
            <div class="body row" style="margin-top: 30px; row-gap: 30px;">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="card mini-card">
                        <div class="card-body">
                            <div class="row row-header">
                                <h5 class="color"> {{ $t('dashboard.public_service') }}</h5>
                            </div>
                            <div class="row" style="align-items: end">
                                <div class="col-lg-8">
                                    <h4 class="color">{{ public_service }}</h4>
                                </div>
                                <div class="col-lg-4">
                                    <i class="fas fa-umbrella-beach"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="card mini-card">
                        <div class="card-body">
                            <div class="row row-header">
                                <h5 class="color"> {{ $t('dashboard.restaurant') }} </h5>
                            </div>
                            <div class="row" style="align-items: end">
                                <div class="col-lg-8">
                                    <h4 class="color">{{ restaurant }}</h4>
                                </div>
                                <div class="col-lg-4">
                                    <i class="fas fa-coffee"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="card mini-card">
                        <div class="card-body">
                            <div class="row row-header">
                                <h5 class="color"> {{ $t('dashboard.occasion') }}</h5>
                            </div>
                            <div class="row" style="align-items: end">
                                <div class="col-lg-8">
                                    <h4 class="color">{{ occasion }}</h4>
                                </div>
                                <div class="col-lg-4">
                                    <i class="fas fa-birthday-cake"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div class="card mini-card">
                        <div class="card-body">
                            <div class="row row-header">
                                <h5 class="color"> {{ $t('dashboard.tour') }}</h5>
                            </div>
                            <div class="row" style="align-items: end">
                                <div class="col-lg-8">
                                    <h4 class="color">{{ tour }}</h4>
                                </div>
                                <div class="col-lg-4">
                                    <i class="fas fa-user-tie"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="container">
            <div class="body row" style="margin-top: 30px;row-gap: 30px;">
                <div class="col-lg-7">
                    <div class="card mini-card" id="chart">
                        <div class="card-header">
                            <div class="d-flex justify-content-between align-items-center add">
                                {{ $t('dashboard.resturant_booking') }}
                            </div>
                        </div>
                        <div class="card-body">
                            <apexchart ref="chart_resturant" type="area" height="250" :options="chartOptions_resturant"
                                :series="series_resturant">
                            </apexchart>
                            <div class="card-footer">
                                {{ $t('dashboard.annual') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="card mini-card" id="chart2" style=" min-height: 445px;">
                        <div class="card-header">
                            <div class="d-flex justify-content-between align-items-center add">
                                {{ $t('dashboard.booking_title') }}
                            </div>
                        </div>
                        <div class="card-body">
                            <apexchart ref="chart_booking" type="polarArea" height="250" :options="chartOptions_booking"
                                :series="series_booking" style="margin-top:50px">
                            </apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="container">
            <div class="body row" style="margin-top: 30px">
                <div class="col-lg-6">
                    <v-card style=" min-height: 392px;">
                        <v-card-title class="my-title" color="primary">
                            {{ $t('dashboard.top_customers') }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-simple-table style="margin: 0px; padding: 0px;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            #
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.first_name') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.last_name') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.booking') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in data.top_customers" :key="index">
                                        <td class="text-center">{{ index }}</td>
                                        <td class="text-center">{{ item.first_name }}</td>
                                        <td class="text-center">{{ item.last_name }}</td>
                                        <td class="text-center">{{ item.bookings_count }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </div>
                <div class="col-lg-6">
                    <v-card style=" min-height: 392px;">
                        <v-card-title class="my-title">
                            {{ $t('dashboard.top_resturants') }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-simple-table style="margin: 0px; padding: 0px;">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            #
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.resturant_name') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.provider_name') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('dashboard.booking') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in data.top_resturants" :key="index">
                                        <td class="text-center">{{ index }}</td>
                                        <td class="text-center">{{ item.name }}</td>
                                        <td class="text-center">{{ item.provider_first_name }} {{ item.provider_last_name }}
                                        </td>
                                        <td class="text-center">{{ item.bookings_count }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from "vue"
import VueApexCharts from "vue-apexcharts"
Vue.use(VueApexCharts)
Vue.component("apexchart", VueApexCharts)

export default {
    name: 'Dashboard',

    components: {
        apexchart: VueApexCharts,
    },

    data: () => ({
        lang: '',
        data: [],
        public_service: null,
        restaurant: null,
        occasion: null,
        tour: null,


        series_resturant: [],
        chartOptions_resturant: {
            chart: {
                height: 350,
                type: "area",
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: "smooth",
            },
            colors: ['#00c5b1'],
            xaxis: {
                categories: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ],
            },
        },

        series_booking: [],
        chartOptions_booking: {
            chart: {
                type: 'polarArea',
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
        },

    }),

    methods: {
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/config/control-panel-statistics?lang=" + this.lang)
                .then(res => {
                    if (res.data.status == "Success") {
                        this.data = res.data.list
                        this.public_service = this.data.services_count
                        this.restaurant = this.data.restaurants_count
                        this.occasion = this.data.occasions_count
                        this.tour = this.data.guide_translators_count


                        this.series_booking = [this.data.all_booking_count.restaurants, this.data.all_booking_count.services, this.data.all_booking_count.occasions]
                        this.$refs.chart_booking.updateOptions({
                            labels: ['Restaurants', 'Public Services', 'Occasions']
                        });


                        this.$refs.chart_resturant.updateSeries([{
                            data: this.data.annual_restaurants_count
                        }]);
                    }
                    console.log(this.data)
                });
        },
    },
    mounted() {
        this.lang = localStorage.getItem('lang')
        this.getData()
    }
}
</script>

<style>
.dashboard .v-card__title {
    color: var(--v-primary-base)
}

html:lang(en) .dashboard .my-title {
    margin-left: 25px !important
}

html:lang(ar) .dashboard .my-title {
    margin-right: 25px !important
}

.dashboard .v-card {
    box-shadow: 0 0 10px #d0cbcb4d !important;
    margin: 25px 0px !important;
    border-radius: 18px !important;
    text-align: justify;
    border: none !important;
    padding: 20px 0px;
}

.dashboard .apexcharts-text {
    display: none;
}

.dashboard .mini-card {
    margin: 0px !important
}

.dashboard .v-data-table {
    /* box-shadow: 0 0 10px #d0cbcb4d !important; */
    /* border-radius: 18px !important; */
    text-align: justify;
    border: none !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: transparent !important;
}

.dashboard .row-header {
    align-items: center;
    margin-bottom: 20px;
}

.dashboard .card-body {
    padding: 25px 40px !important
}

.dashboard .fa-umbrella-beach {
    color: #353897;
    padding: 10px;
    font-size: 30px;
    border-radius: 15px;
    background-color: #3538972d;
}

.dashboard .fa-mug-saucer {
    color: #09b23d;
    padding: 10px;
    font-size: 30px;
    border-radius: 15px;
    background-color: #09b23c2f;
}

.dashboard .fa-cake-candles {
    color: #00c5b1;
    padding: 10px 13px;
    font-size: 30px;
    border-radius: 15px;
    background-color: #00c5b12a;
}

.dashboard .fa-user-tie {
    color: #353897;
    padding: 10px 13px;
    font-size: 30px;
    border-radius: 15px;
    background-color: #3538972d;
}

.dashboard h5 {
    font-size: 17px !important;
    color: var(--v-primary-base);
}

.dashboard h4 {
    color: var(--v-primary-base);
}

.dashboard .card-header {
    color: var(--v-primary-base) !important;
}
</style>