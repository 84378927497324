
import { BNavItem } from 'bootstrap-vue';
<template>
    <div id="role" class="role list">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('role.list') }}
                <!-- <router-link to="/role/add">
                    <v-btn color="primary" outlined>
                        {{ $t('role.add') }}
                    </v-btn>
                </router-link> -->
            </div>
            <div class="card-body">
                <div v-if="load">
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" :reverse="direction"
                            single-line hide-details></v-text-field>
                        <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table :headers="$t('role.headers')" :items="data" :search="search" class="my-table">
                        <template v-slot:[`item.permission`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="displayItem(item)">
                                    <i class="fa fa-eye"></i>
                                </div>
                            </div>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="dialogPermission">
                        <v-card v-if="load_per">
                            <v-spacer></v-spacer>
                            <v-card-title class="justify-content-start"
                                style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 10px;">
                                {{ $t('role.permission') }}
                            </v-card-title>

                            <v-data-table :items-per-page="20" hide-default-footer v-model="my_permission"
                                :headers="headers_per" :items="permission" item-key="id" show-select class="elevation-1"
                                style=" box-shadow: none !important; border: none !important; margin: 0px 30px;">

                            </v-data-table>

                            <v-card-actions
                                style="justify-content: end; padding-bottom: 30px;margin-left: 20px;margin-top: 20px">
                                <v-btn color="primary" @click="dialogPermission = false">{{ $t('close') }}
                                </v-btn>
                                <v-btn @click="save" :disabled="isSubmit && !response" color="primary" light>
                                    <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                                        style="margin: 0px 5px" color="white"></v-progress-circular>
                                    {{ $t('edit') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card class="progress-container" v-else>
                            <v-progress-circular indeterminate color="primary" :size="50"
                                style="margin-top: 100px;margin-bottom: 100px"></v-progress-circular>
                        </v-card>
                    </v-dialog>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </div>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'Role',
    components: {
        Breadcrumbs
    },
    data: () => ({
        search: '',
        lang: '',
        data: [],
        direction: false,
        load: false,
        bread_en: [
            {
                text: "Roles & Permissions",
                disabled: true,
                href: '',
            },
            {
                text: 'List',
                disabled: false,
                href: '/role/list',
            },
        ],
        bread_ar: [
            {
                text: 'الادوار والصلاحيات',
                disabled: true,
                href: '',
            },
            {
                text: 'عرض',
                disabled: false,
                href: '/role/list',
            },
        ],
        permission: [],
        send_per: [],
        dialogPermission: false,
        load_per: false,
        headers_per: [
            { text: 'Permission', value: 'name' },
        ],
        all_per: [],
        role_id: null,
        snackbar: false,
        error_snackbar: false,
        my_permission: [],
        isSubmit: false,
        response: false,
        resturants_bookings: null,
        services_bookings: null,
        occasions_bookings: null,
        resturants_reviews: null,
        services_reviews: null,
        tourGuides_reviews: null,
    }),
    // watch: {
    //     my_permission(newVal) {
    //         console.log(newVal)
    //         let if_service = newVal.some(item => item.order == 7)
    //         if (this.permission.length != 0)
    //             if (if_service) {
    //                 this.permission.splice(7, 1)
    //                 this.permission.splice(8, 1)

    //                 // this.permission[7].show = true
    //                 // this.permission[8].show = true
    //             }
    //             else {
    //                 this.permission.push(this.services_bookings)
    //                 this.permission.push(this.services_reviews)
    //                 this.permission = this.permission.sort((a, b) => {
    //                         return a.order - b.order;
    //                     });

    //                 // this.permission[7].show = false
    //                 // this.permission[8].show = false
    //             }

    //         let if_res = newVal.some(item => item.order == 10)
    //         if (this.permission.length != 0)
    //             if (if_res) {
    //                 this.permission[10].show = true
    //                 this.permission[11].show = true
    //             }
    //             else {
    //                 this.permission[10].show = false
    //                 this.permission[11].show = false
    //             }

    //         let if_ocassion = newVal.some(item => item.order == 13)
    //         if (this.permission.length != 0)
    //             if (if_ocassion) {
    //                 this.permission[13].show = true
    //             }
    //             else {
    //                 this.permission[13].show = false
    //             }

    //         let if_tour = newVal.some(item => item.order == 15)
    //         if (this.permission.length != 0)
    //             if (if_tour) {
    //                 this.permission[15].show = true
    //             }
    //             else {
    //                 this.permission[15].show = false
    //             }
    //     }
    // },
    methods: {
        save() {
            this.isSubmit = true
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const params = new URLSearchParams()
            this.my_permission.forEach((item, index) => {
                params.append(`permissions[${index}]`, item.id)
            })
            for (var pair of params.entries()) {
                console.log(pair)
            }
            this.axios.put(this.$store.state.url + "/api/v1/roles/" + this.role_id, params, config)
                .then(res => {
                    console.log(res)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.dialogPermission = false
                        this.snackbar = true
                        this.response = false
                        this.isSubmit = false
                    }
                }).catch(error => {
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/roles")
                .then(res => {
                    // console.log(res.data.list)
                    this.load = true
                    if (res.data.status == "Success") {
                        this.data = res.data.list.reverse()
                    }
                });
        },
        displayItem(item) {
            this.dialogPermission = true
            this.role_id = item.id
            this.load_per = false
            this.my_permission = []
            this.axios.get(this.$store.state.url + "/api/v1/roles/" + item.id + "/edit")
                .then(res => {
                    const my_per = res.data.list
                    let final_permission = []

                    if (res.data.status == "Success") {
                        this.load_per = true
                        my_per.forEach(item => {
                            if (item.name == 'roles_manager')
                                final_permission.push({ name: 'Roles & Permissions', id: item.id, order: 1, show: true, status: item.status })

                            else if (item.name == 'users_manager')
                                final_permission.push({ name: 'Users', id: item.id, order: 2, show: true, status: item.status })

                            else if (item.name == 'attributes_manager')
                                final_permission.push({ name: 'Attribute', id: item.id, order: 5, show: true, status: item.status })

                            else if (item.name == 'countries_manager')
                                final_permission.push({ name: 'State', id: item.id, order: 3, show: true, status: item.status })

                            else if (item.name == 'cities_manager')
                                final_permission.push({ name: 'City', id: item.id, order: 4, show: true, status: item.status })

                            else if (item.name == 'resturants_bookings_manager'){
                                final_permission.push({ name: 'Restuarant Booking', id: item.id, order: 11, show: false, status: item.status })
                                this.resturants_bookings = { name: 'Restuarant Booking', id: item.id, order: 11, show: false, status: item.status }
                            }

                            else if (item.name == 'services_bookings_manager'){
                                final_permission.push({ name: 'Public Service Booking', id: item.id, order: 8, show: false, status: item.status })
                                this.services_bookings = { name: 'Public Service Booking', id: item.id, order: 8, show: false, status: item.status }
                            }

                            else if (item.name == 'occasions_bookings_manager'){
                                final_permission.push({ name: 'Occasions Booking', id: item.id, order: 14, show: false, status: item.status })
                                this.occasions_bookings = { name: 'Occasions Booking', id: item.id, order: 14, show: false, status: item.status }
                            }

                            else if (item.name == 'resturants_reviews_manager'){
                                final_permission.push({ name: 'Restuarant Reviews', id: item.id, order: 12, show: false, status: item.status })
                                this.resturants_reviews = { name: 'Restuarant Reviews', id: item.id, order: 12, show: false, status: item.status }
                            }

                            else if (item.name == 'services_reviews_manager'){
                                final_permission.push({ name: 'Public Service Reviews', id: item.id, order: 9, show: false, status: item.status })
                                this.services_reviews = { name: 'Public Service Reviews', id: item.id, order: 9, show: false, status: item.status }
                            }

                            else if (item.name == 'tourGuides_reviews_manager'){
                                final_permission.push({ name: 'Tour Guide & Translator Reviews', id: item.id, order: 16, show: false, status: item.status })
                                this.tourGuides_reviews = { name: 'Tour Guide & Translator Reviews', id: item.id, order: 16, show: false, status: item.status }
                            }

                            else if (item.name == 'resturants_manager')
                                final_permission.push({ name: 'Restuarant', id: item.id, order: 10, show: true, status: item.status })

                            else if (item.name == 'customers_manager')
                                final_permission.push({ name: 'Customer', id: item.id, order: 18, show: true, status: item.status })

                            else if (item.name == 'banners_manager')
                                final_permission.push({ name: 'Banners', id: item.id, order: 17, show: true, status: item.status })

                            else if (item.name == 'serviceCategories_manager')
                                final_permission.push({ name: 'Service Category', id: item.id, order: 6, show: true, status: item.status })

                            else if (item.name == 'services_manager')
                                final_permission.push({ name: 'Public Service', id: item.id, order: 7, show: true, status: item.status })

                            else if (item.name == 'occasions_manager')
                                final_permission.push({ name: 'Occasions', id: item.id, order: 13, show: true, status: item.status })

                            else if (item.name == 'tourGuides_manager')
                                final_permission.push({ name: 'Tour Guide & Translator', id: item.id, order: 15, show: true, status: item.status })

                        })
                        this.permission = final_permission.sort((a, b) => {
                            return a.order - b.order;
                        });
                        console.log(this.permission)
                        this.permission.forEach(item => {
                            if (item.status)
                                this.my_permission.push(item)
                        })
                    }
                });
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
    },
    mounted() {
        this.lang = localStorage.getItem('lang')
        this.getData()
        this.initDir()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.no-per {
    display: flex;
    justify-content: center;
    font-size: 20px;
    border-bottom: 1px solid #aeaeae;
    padding-bottom: 50px;
    margin-bottom: 20px;
}

.v-dialog {
    max-width: 500px !important;
}

@media (max-width: 650px) {
    .v-dialog {
        max-width: 400px !important;
    }
}

@media (max-width: 550px) {
    .v-dialog {
        max-width: 350px !important;
    }
}
</style>