<template>
    <div id="add-user" class="add-user add">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('user.edit_my_profile') }}
                <router-link to="/profile">
                    <v-btn color="primary" outlined>
                        {{ $t('user.profile') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form class="row" v-if="load">
                    <div class="col-lg-6">
                        <v-text-field outlined :reverse="direction" required v-model="fname" :error-messages="fnameErrors"
                            :label="$t('user.fname')"></v-text-field>
                        <v-select outlined v-model="city_id" :reverse="direction" :items="city_list" item-text="name"
                            :error-messages="cityErrors" item-value="id" :label="$t('user.city')"></v-select>
                        <v-text-field outlined :reverse="direction" v-model="phone" :error-messages="phoneErrors"
                            :label="$t('user.phone')"></v-text-field>
                        <v-file-input append-icon="mdi-camera" prepend-icon="" :label="$t('user.image')"
                            :reverse="direction" v-model="image" outlined
                            accept="image/png, image/jpeg, image/bmp"></v-file-input>

                        <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light
                            style="margin-top: 15px">
                            <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                                color="white"></v-progress-circular>
                            {{ $t('submit') }}
                        </v-btn>
                    </div>
                    <div class="col-lg-6">
                        <v-text-field outlined :reverse="direction" required v-model="lname" :error-messages="lnameErrors"
                            :label="$t('user.lname')"></v-text-field>
                        <v-text-field outlined :reverse="direction" required v-model="address"
                            :error-messages="addressErrors" :label="$t('user.address')"></v-text-field>
                        <v-text-field type="email" outlined :reverse="direction" v-model="email"
                            :error-messages="emailErrors" :label="$t('user.email')"></v-text-field>
                        <v-text-field type="password" outlined :reverse="direction" v-model="password"
                            :error-messages="passwordErrors" :label="$t('user.password')"></v-text-field>
                    </div>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar_back" :timeout="3000">
                        {{ content_error }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar_back = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import Breadcrumbs from "@/components/Breadcrumbs.vue"
export default {
    name: 'AddUser',
    components: {
        Breadcrumbs
    },
    mixins: [validationMixin],

    data: () => ({
        fname: '',
        lname: '',
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        error_snackbar_back: false,

        bread_en: [
            {
                text: 'My Profile',
                disabled: true,
                href: '',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        bread_ar: [
            {
                text: 'صفحتي الشخصية',
                disabled: true,
                href: '',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            },
        ],
        direction: false,
        lang: '',
        edit: false,
        route: '',
        id: '',
        load: false,
        roles: [],
        role_name: null,
        email: null,
        password: null,
        phone: null,
        image: null,
        address: '',
        city_list: [],
        city_id: '',
        data: null,
        content_error: '',
        isAdmin: false,


    }),
    validations: {
        fname: { required },
        lname: { required },
        address: { required },
        city_id: { required },
        email: { required, email },
        password: { required },
        phone: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
    },
    computed: {
        fnameErrors() {
            const errors = []
            if (!this.$v.fname.$dirty) return errors
            !this.$v.fname.required && errors.push('This feild is required')
            return errors
        },
        lnameErrors() {
            const errors = []
            if (!this.$v.lname.$dirty) return errors
            !this.$v.lname.required && errors.push('This feild is required')
            return errors
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.required && errors.push('This feild is required')
            !this.$v.email.email && errors.push('Please enter correct email address')
            return errors
        },
        passwordErrors() {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('This feild is required')
            return errors
        },

        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.required && errors.push('This feild is required')
            !this.$v.phone.numeric && errors.push('Please enter correct phone number')
            !this.$v.phone.minLength && errors.push('Please enter correct phone number')
            !this.$v.phone.maxLength && errors.push('Please enter correct phone number')
            return errors
        },
        addressErrors() {
            const errors = []
            if (!this.$v.address.$dirty) return errors
            !this.$v.address.required && errors.push('This field is required')
            return errors
        },
        cityErrors() {
            const errors = []
            if (!this.$v.city_id.$dirty) return errors
            !this.$v.city_id.required && errors.push('This field is required')
            return errors
        },
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('first_name', this.fname)
            formData.append('last_name', this.lname)
            formData.append('address', this.address)
            formData.append('city_id', this.city_id)
            formData.append('email', this.email)
            formData.append('password', this.password)
            formData.append('phone', this.phone)
            if (this.image != null)
                formData.append('image', this.image)
            console.log(this.role_name)
            formData.append('role', this.role_name)
            this.editData(formData)
        },

        editData(formData) {
            console.log(this.id)
            this.axios.post(this.$store.state.url + "/api/v1/users/update-profile/" + this.id, formData)
                .then(res => {
                    console.log(res)
                    this.response = true
                    // console.log(res.response.data.message)
                    if (res.message == "Request failed with status code 400") {
                        this.content_error = res.response.data.message
                        this.error_snackbar_back = true
                        this.isSubmit = false
                        this.response = false
                    }
                    else if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'profile' })
                    }
                })
                .catch(error => {
                    this.error_snackbar = true
                    this.isSubmit = false
                    this.response = false
                });
        },
        initEdit() {
            this.id = sessionStorage.getItem("id")
            this.axios.get(this.$store.state.url + "/api/v1/users/" + this.id)
                .then(res => {
                    this.load = true
                    this.data = res.data.list
                    console.log(res.data.list)
                    this.fname = this.data.first_name
                    this.lname = this.data.last_name
                    this.email = this.data.email
                    this.phone = "0" + this.data.phone
                    this.address = this.data.address
                    this.city_id = parseInt(this.data.city_id)
                    this.role_name = this.data.role

                    // this.image = res.data.data[0].image
                })
        },
        getCity() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/cities?lang=" + this.lang)
                .then(res => {
                    this.city_list = res.data.list
                });
        },
        getRole() {
            this.axios.get(this.$store.state.url + "/api/v1/roles")
                .then(res => {
                    this.roles = res.data.list
                });
        }
    },
    mounted() {
        this.initDir()
        this.initEdit()
        this.getCity()
        // this.role_name = sessionStorage.getItem("name")
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
