<template>
    <div id="add-meal" class="add-meal add">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header" v-if="this.route == 'add-meal'">
                {{ $t('meal.add') }}
                <v-btn color="primary" outlined @click="mealList()">
                    {{ $t('meal.list') }}
                </v-btn>
            </div>
            <div class="card-header" v-else-if="this.route == 'edit-meal'">
                {{ $t('meal.edit') }}
                <v-btn color="primary" outlined @click="mealList()">
                    {{ $t('meal.list') }}
                </v-btn>
            </div>

            <div class="card-body">
                <v-form v-if="(this.route == 'add-meal' && !load) || (this.route == 'edit-meal' && load)">
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_en"
                                :error-messages="nameEnErrors" :label="$t('meal.name_en')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_ar"
                                :error-messages="nameArErrors" :label="$t('meal.name_ar')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="description_en"
                                :error-messages="descriptionEnErrors" :label="$t('meal.description_en')"></v-textarea>
                        </div>
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="description_ar"
                                :error-messages="descriptionArErrors" :label="$t('meal.description_ar')"></v-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-file-input append-icon="mdi-camera" prepend-icon="" :label="$t('meal.image')"
                                accept="image/png, image/jpeg, image/bmp" :reverse="direction" v-model="image" outlined
                                :error-messages="imageErrors" @change="changeImage"></v-file-input>
                            <v-img :src="display_image" max-height="200" contain style="margin-bottom: 30px"
                                v-if="display_image != ''"></v-img>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="price"
                                :error-messages="priceErrors" :label="$t('meal.price')"></v-text-field>
                        </div>
                    </div>
                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 15px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, numeric, requiredIf } from 'vuelidate/lib/validators'
import Breadcrumbs from "@/components/Breadcrumbs.vue"
export default {
    name: 'AddMeal',
    components: {
        Breadcrumbs
    },
    mixins: [validationMixin],

    data: () => ({
        name_en: '',
        name_ar: '',
        image: null,
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_add_en: [
            {
                text: 'Restaurant',
                disabled: true,
                href: '',
            },
            {
                text: 'Menu',
                disabled: true,
                href: '',
            },
            {
                text: 'Meals',
                disabled: true,
                href: '',
            },
            {
                text: 'Add',
                disabled: true,
                href: '/meal/add',
            },
        ],
        bread_en: [],
        bread_ar: [],
        direction: false,
        lang: '',
        edit: false,
        route: '',
        id: '',
        load: false,
        menus_list: [],
        menu_id: '',
        resturant_id: '',
        description_ar: '',
        description_en: '',
        price: '',
        display_image: '',
        orginal_image: ''
    }),

    watch: {
        resturant_id(newVal) {
            this.bread_en = [
                {
                    text: 'Restaurant',
                    disabled: false,
                    href: '/resturant/list',
                },
                {
                    text: 'Menu',
                    disabled: false,
                    href: '/menu/list/' + newVal,
                },
                {
                    text: 'Meal',
                    disabled: true,
                    href: '',
                },
            ]
            this.bread_ar = [
                {
                    text: 'مطعم',
                    disabled: false,
                    href: '/resturant/list',
                },
                {
                    text: 'قائمة الطعام',
                    disabled: false,
                    href: '/menu/list/' + newVal,
                },
                {
                    text: 'الوجبة',
                    disabled: true,
                    href: '',
                },
            ]
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    validations: {
        name_en: { required, minLength: minLength(3) },
        name_ar: { required, minLength: minLength(3) },
        image: {
            required: requiredIf(function (item) {
                return !item.edit
            })
        },
        description_ar: { required },
        description_en: { required },
        price: { required, numeric },
    },

    computed: {
        nameEnErrors() {
            const errors = []
            if (!this.$v.name_en.$dirty) return errors
            !this.$v.name_en.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_en.required && errors.push('Name is required')
            return errors
        },
        nameArErrors() {
            const errors = []
            if (!this.$v.name_ar.$dirty) return errors
            !this.$v.name_ar.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_ar.required && errors.push('Name is required')
            return errors
        },
        imageErrors() {
            const errors = []
            if (!this.$v.image.$dirty) return errors
            !this.$v.image.required && errors.push('This field is required')
            return errors
        },
        descriptionArErrors() {
            const errors = []
            if (!this.$v.description_ar.$dirty) return errors
            !this.$v.description_ar.required && errors.push('This field is required')
            return errors
        },
        descriptionEnErrors() {
            const errors = []
            if (!this.$v.description_en.$dirty) return errors
            !this.$v.description_en.required && errors.push('This field is required')
            return errors
        },
        priceErrors() {
            const errors = []
            if (!this.$v.price.$dirty) return errors
            !this.$v.price.required && errors.push('This field is required')
            !this.$v.price.numeric && errors.push('Please input only numbers ')
            return errors
        },
    },

    methods: {
        changeImage(event) {
            if (event != null) {
                this.display_image = URL.createObjectURL(event);
            }
            else {
                this.display_image = this.orginal_image
            }
        },
        mealList() {
            this.$router.replace({ name: 'meal-list', params: { id: this.menu_id } })
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            // console.log(this.$v.$error)
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('name[en]', this.name_en)
            formData.append('name[ar]', this.name_ar)
            formData.append('description[en]', this.description_en)
            formData.append('description[ar]', this.description_ar)
            formData.append('lang', "en")
            if (this.image != null)
                formData.append('image', this.image)
            formData.append('price', this.price)
            formData.append('menu_id', this.menu_id)
            if (this.edit == true) {
                this.editData(formData)
            }
            else {
                this.addData(formData)
            }
        },
        addData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/meals", formData)
                .then(res => {
                    console.log(res.data)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'meal-list', params: { id: this.menu_id } })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        editData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/meals/" + this.id, formData)
                .then(res => {
                    console.log(res)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'meal-list', params: { id: this.menu_id } })
                    }
                }).catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });

        },
        initEdit() {
            this.route = this.$route.name
            if (this.$route.name == "edit-meal") {
                this.id = this.$route.params.id
                this.edit = true
                this.axios.get(this.$store.state.url + "/api/v1/admin/meals/" + this.id)
                    .then(res => {
                        this.load = true
                        console.log(res.data.list)
                        this.name_ar = res.data.list.name_ar
                        this.name_en = res.data.list.name_en

                        this.description_ar = res.data.list.description_ar
                        this.description_en = res.data.list.description_en

                        this.price = res.data.list.price
                        this.menu_id = res.data.list.menu_id

                        this.display_image = res.data.list.media_urls[0]
                        this.orginal_image = res.data.list.media_urls[0]

                    })
            }
        },

    },
    mounted() {
        this.initDir()
        this.initEdit()
        this.resturant_id = this.$route.params.resturant_id
        if (this.route == "edit-meal")
            this.initEdit()
        if (this.route == "add-meal") {
            this.menu_id = this.$route.params.id
        }
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
