<template>
    <div id="list-tour" class="list-tour list">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('tour.list') }}
                <router-link to="/tour/add">
                    <v-btn color="primary" outlined>
                        {{ $t('tour.add') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <div v-if="load">
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" :reverse="direction"
                            single-line hide-details></v-text-field>
                        <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table :headers="$t('tour.headers')" :items="data" :search="search" class="my-table">
                        <template v-slot:top>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-spacer></v-spacer>
                                    <v-card-title class="justify-content-center" style="padding-top: 30px">
                                        {{ $t('confrim_delete') }}
                                    </v-card-title>
                                    <v-card-actions style="padding-bottom: 30px">
                                        <v-spacer></v-spacer>
                                        <v-btn color="gray" text @click="closeDelete">{{ $t('cancel') }}
                                        </v-btn>
                                        <v-btn color="red darken-1" text @click="deleteItemConfirm">{{ $t('confrim') }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                        <template v-slot:[`item.mangement`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="displayItem(item)">
                                    <i class="fa fa-eye"></i>
                                </div>
                                <div @click="deleteItem(item)">
                                    <i class="fa fa-trash"></i>
                                </div>
                                <div @click="editItem(item)">
                                    <i class="fas fa-pen"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <div class="row justify-content-center" style="top: 15px; position: relative; direction: ltr;">
                                <v-switch v-model="item.status" inset color="green" @click="statusItem(item)"></v-switch>
                            </div>
                        </template>
                        <template v-slot:[`item.review`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="displayReview(item)">
                                    <i class="fa fa-eye"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            <div class="row justify-content-center" v-for="(itemm, index) in item.type" :key="index">
                                <div class="tour" v-if="itemm == 'tourist guide'">
                                    {{ guid }}
                                </div>
                                <div class="translator" v-if="itemm == 'translator'">
                                    {{ translator }}
                                </div>
                            </div>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="displayDialog" max-width="700px" persistent>
                        <v-card>
                            <v-spacer></v-spacer>
                            <v-card-title class="justify-content-start"
                                style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 40px;">
                                {{ $t('tour.display') }}
                            </v-card-title>
                            <div>
                                <v-simple-table style="margin: 10px 30px 30px; padding: 0px; text-align: justify;">
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td>{{ $t('tour.main_image') }}</td>
                                                <td><v-img :src="display.media.url"  max-width="110"></v-img> </td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('tour.fname') }}</td>
                                                <td>{{ display.fname }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('tour.lname') }}</td>
                                                <td>{{ display.lname }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('tour.address') }}</td>
                                                <td>{{ display.address }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('tour.email') }}</td>
                                                <td>{{ display.email }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('tour.phone') }}</td>
                                                <td>{{ display.phone }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('tour.description') }}</td>
                                                <td>{{ display.description }}</td>
                                            </tr>
                                            <tr v-if="display.city != null">
                                                <td>{{ $t('tour.city') }}</td>
                                                <td>{{ display.city.name }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('tour.language') }}</td>
                                                <td>
                                                    <span v-for="(item, index) in display.language" :key="index">
                                                        {{ item.name }}
                                                    </span>
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                            <v-card-actions style="justify-content: end; padding-bottom: 30px;margin-left: 20px;">
                                <v-btn color="primary" @click="displayDialog = false">{{ $t('close') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
                <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                    {{ $t('delete_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                    {{ $t('error_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="green" text v-model="change_snackbar" :timeout="3000">
                    {{ $t('change_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="change_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'tourList',
    components: {
        Breadcrumbs
    },
    data: () => ({
        search: '',
        lang: '',
        direction: false,
        data: [],
        load: false,
        dialogDelete: false,
        editedIndex: -1,
        snackbar: false,
        error_snackbar: false,
        change_snackbar: false,
        delete: '',
        bread_en: [
            {
                text: 'Tour Guide & Translator',
                disabled: true,
                href: '',
            },
            {
                text: 'List',
                disabled: false,
                href: '/tour/list',
            },
        ],
        bread_ar: [
            {
                text: 'دليل سياحي وترجمة',
                disabled: true,
                href: '',
            },
            {
                text: 'عرض',
                disabled: false,
                href: '/tour/list',
            },
        ],
        translator: '',
        guid: '',
        display: null,
        displayDialog: false
    }),
    watch: {
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en') {
                this.translator = "Translation"
                this.guid = "Tour Guides"
                this.direction = false
            }
            else {
                this.translator = "ترجمة"
                this.guid = "دليل سياجي"
                this.direction = true
            }
        },
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/tourGuide?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    if (res.data.status == "Success") {
                        this.data = res.data.list.reverse()
                        this.data.forEach(item => item.status = parseInt(item.status))

                    }
                    console.log(res.data.list)
                });
        },
        displayReview(item) {
            this.$router.replace({ name: 'review-list', params: { section_id: 3, id: item.id } })
        },
        editItem(item) {
            console.log("Edit: " + item.id)
            this.$router.replace({ name: 'edit-tour', params: { id: item.id } })
        },
        deleteItem(item) {
            console.log("Delete: " + item.id)
            this.editedIndex = this.data.indexOf(item)
            this.delete = item
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.sendIdDeleted()
            this.closeDelete()
        },
        closeDelete() {
            this.dialogDelete = false
        },
        sendIdDeleted() {
            this.axios.delete(this.$store.state.url + "/api/v1/admin/tourGuide/" + this.delete.id)
                .then((res) => {
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.data.splice(this.editedIndex, 1)
                        this.snackbar = true
                    }
                })
                .catch(error => {
                    this.error_snackbar = true
                })
        },
        statusItem(item) {
            const formData = new FormData()
            formData.append("status", Number(item.status))
            this.axios.post(this.$store.state.url + "/api/v1/admin/tourGuide/status/" + item.id, formData)
                .then((res) => {
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.change_snackbar = true
                    }
                }).catch(error => {
                    this.error_snackbar = true
                })
        },
        displayItem(item) {
            this.display = item
            this.displayDialog = true
        }
    },
    mounted() {
        this.lang = localStorage.getItem('lang')
        this.getData()
        this.initDir()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.tour {
    border: 1px solid var(--v-secondary-base);
    border-radius: 30px;
    padding: 5px 10px;
    margin: 5px 0px;
}

.translator {
    border: 1px solid var(--v-primary-base);
    border-radius: 30px;
    padding: 5px 10px;
    margin: 5px 0px;
}
</style>
