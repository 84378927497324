<template>
    <div id="add-city" class="add-city add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en' && this.route == 'add-city'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar' && this.route == 'add-city'" />
        <breadcrumbs :breadcrumbs="bread_edit_en" v-if="lang == 'en' && this.route == 'edit-city'" />
        <breadcrumbs :breadcrumbs="bread_edit_ar" v-if="lang == 'ar' && this.route == 'edit-city'" />
        <div class="card">
            <div class="card-header" v-if="this.route == 'add-city'">
                {{ $t('city.add') }}
                <router-link to="/city/list">
                    <v-btn color="primary" outlined>
                        {{ $t('city.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-header" v-else-if="this.route == 'edit-city'">
                {{ $t('city.edit') }}
                <router-link to="/city/list">
                    <v-btn color="primary" outlined>
                        {{ $t('city.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="(this.route == 'add-city' && !load) || (this.route == 'edit-city' && load)">
                    <v-select outlined v-model="state_id" :reverse="direction" :items="state_list" item-text="name"
                        item-value="id" :label="$t('state.name')" :error-messages="stateIdErrors"></v-select>
                    <div class="row" style="align-items: center;" v-if="this.route == 'add-city'">
                        <div class="col-lg-10" v-for="(v, index) in $v.city.$each.$iter" :key="index">
                            <div class="row" >
                                <div class="col-lg-6">
                                    <v-text-field outlined :reverse="direction" required v-model="v.name_en.$model"
                                        :error-messages="nameEnErrors(index)" :label="$t('city.name_en')"></v-text-field>
                                </div>
                                <div class="col-lg-6">
                                    <v-text-field outlined :reverse="direction" required v-model="v.name_ar.$model"
                                        :error-messages="nameArErrors(index)" :label="$t('city.name_ar')"></v-text-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-1" style="margin-bottom: 30px">
                            <v-btn color="blue" text class="plus-button" @click="add()">
                                +
                            </v-btn>
                        </div>
                        <div class="col-lg-1" style="margin-bottom: 30px">
                            <v-btn color="blue" text class="plus-button" @click="subtract()">
                                -
                            </v-btn>
                        </div>
                    </div>



                    <div class="row" style="align-items: center;" v-if="this.route == 'edit-city'">
                        <div class="col-lg-12" v-for="(v, index) in $v.city.$each.$iter" :key="index">
                            <div class="row" >
                                <div class="col-lg-6">
                                    <v-text-field outlined :reverse="direction" required v-model="v.name_en.$model"
                                        :error-messages="nameEnErrors(index)" :label="$t('city.name_en')"></v-text-field>
                                </div>
                                <div class="col-lg-6">
                                    <v-text-field outlined :reverse="direction" required v-model="v.name_ar.$model"
                                        :error-messages="nameArErrors(index)" :label="$t('city.name_ar')"></v-text-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 15px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import Breadcrumbs from "@/components/Breadcrumbs.vue"
export default {
    name: 'AddCity',
    components: {
        Breadcrumbs
    },
    mixins: [validationMixin],

    data: () => ({
        city: [{
            name_en: '',
            name_ar: '',
        }],
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_add_en: [
            {
                text: 'Cities',
                disabled: true,
                href: '',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/city/add',
            },
        ],
        bread_add_ar: [
            {
                text: 'المدن',
                disabled: true,
                href: '',
            },
            {
                text: 'إضافة',
                disabled: false,
                href: '/city/add',
            },
        ],
        bread_edit_en: [
            {
                text: 'Cities',
                disabled: true,
                href: '',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        bread_edit_ar: [
            {
                text: 'المدن',
                disabled: true,
                href: '',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            },
        ],
        direction: false,
        lang: '',
        state_list: [],
        state_id: null,
        edit: false,
        route: '',
        id: '',
        load: false,
    }),

    validations: {
        city: {
            $each: {
                name_en: { required, minLength: minLength(3) },
                name_ar: { required, minLength: minLength(3) },
            }
        },
        state_id: { required }
    },

    computed: {
        stateIdErrors() {
            const errors = []
            if (!this.$v.state_id.$dirty) return errors
            !this.$v.state_id.required && errors.push('State is required')
            return errors
        },
    },

    methods: {
        nameArErrors(index) {
            const errors = []
            if (!this.$v.city.$each[index].name_ar.$dirty) return errors
            !this.$v.city.$each[index].name_ar.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.city.$each[index].name_ar.required && errors.push('Name is required')
            return errors
        },
        nameEnErrors(index) {
            const errors = []
            if (!this.$v.city.$each[index].name_en.$dirty) return errors
            !this.$v.city.$each[index].name_en.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.city.$each[index].name_en.required && errors.push('Name is required')
            return errors
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                if (this.edit == true) {
                    this.editData()
                }
                else {
                    this.addData()
                }
            }
        },
        addData() {
            const formData = new FormData()
            formData.append("country_id", this.state_id)
            this.city.forEach((item, index) => {
                formData.append(`cities[${index}][name_en]`, item.name_en)
                formData.append(`cities[${index}][name_ar]`, item.name_ar)
            })
            this.axios.post(this.$store.state.url + "/api/v1/admin/cities", formData)
            .then(res => {
                console.log(res.data)
                this.response = true
                if (res.data.status == "Success") {
                    this.snackbar = true
                    this.$router.replace({ name: 'city-list' })
                }
            }).catch(error => {
                this.error_snackbar = true
                this.response = false
                this.isSubmit = false
            })
        },
        editData() {
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const params = new URLSearchParams()
            params.append('name_en', this.$v.city.$each[0].name_en.$model)
            params.append('name_ar', this.$v.city.$each[0].name_ar.$model)
            params.append('country_id', this.state_id)

            this.axios.put(this.$store.state.url + "/api/v1/admin/cities/" + this.id, params, config)
            .then(res => {
                    console.log(res)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'city-list' })
                    }
                }) .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        getState() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/countries?lang=" + this.lang)
                .then(res => {
                    // console.log(res.data)
                    if (res.data.status == "Success") 
                        this.state_list = res.data.list
                });
        },
        add() {
            this.city.push({
                name_ar: '',
                name_en: '',
            });
        },
        subtract() {
            if (this.city.length > 1)
                this.city.pop()
        },
        initEdit() {
            this.route = this.$route.name
            if (this.$route.name == "edit-city") {
                this.id = this.$route.params.id
                this.edit = true
                this.axios.get(this.$store.state.url + "/api/v1/admin/cities/" + this.id)
                    .then(res => {
                        this.load = true
                        console.log(res.data.list)
                        this.state_id = res.data.list.country_id
                        this.$v.city.$each[0].name_ar.$model = res.data.list.name_ar
                        this.$v.city.$each[0].name_en.$model = res.data.list.name_en
                    })
            }
        },
    },
    mounted() {
        this.initDir()
        this.getState()
        this.initEdit()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

html:lang(ar) .plus-button {
    font-size: 30px !important;
    padding-bottom: 6px !important;
}

html:lang(en) .plus-button {
    font-size: 30px !important;
    padding-bottom: 0px !important;
}

.my-box {
    border: 1px solid #adadad;
    border-radius: 20px;
    padding: 30px 40px;
    margin-bottom: 40px;
}
</style>
