<template>
    <div id="display-user" class="display-user display">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('user.profile') }}
                    <v-btn color="primary" outlined @click="edit_profile">
                        {{ $t('user.edit_profile') }}
                    </v-btn>
            </div>
            <div class="card-body" style="min-height: 350px;">
                <v-form class="row" style="justify-content: end;" v-if="(load)">
                    <div class="col-lg-2 img-container">
                        <img :src="data.media_urls[0]" style="height: 120px; width: 120px;" />
                    </div>
                    <div class="col-lg-5">

                        <v-text-field outlined :reverse="direction" v-model="data.first_name" readonly
                            :label="$t('user.fname')"></v-text-field>

                        <v-text-field outlined :reverse="direction" v-model="data.city.name" readonly
                            :label="$t('user.city')"></v-text-field>


                        <v-text-field outlined :reverse="direction" v-model="data.phone" readonly
                            :label="$t('user.phone')"></v-text-field>

                        <v-text-field outlined :reverse="direction" v-model="data.roles[0].name" readonly
                            :label="$t('user.role')"></v-text-field>

                    </div>
                    <div class="col-lg-5">

                        <v-text-field outlined :reverse="direction" v-model="data.last_name" readonly
                            :label="$t('user.lname')"></v-text-field>

                            <v-text-field outlined :reverse="direction" required v-model="data.address"
                            readonly :label="$t('user.address')"></v-text-field>


                        <v-text-field type="email" outlined :reverse="direction" v-model="data.email" readonly
                            :label="$t('user.email')"></v-text-field>

                    </div>

                </v-form>
                <div class="progress-container" v-if="!load">
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'Profile',
    components: {
        Breadcrumbs
    },
    data: () => ({
        lang: '',
        load: false,
        direction: null,
        bread_en: [
            {
                text: 'My Profile',
                disabled: true,
                href: '',
            },
        ],
        bread_ar: [
            {
                text: 'صفحتي الشخصية',
                disabled: true,
                href: '',
            },
        ],
        id: null,
        name: null,
        email: null,
        phone: null,
        image: null,
        new_image: null,
        password: null,
        password_confrim: null,
        route: null,
        snackbar: false,
        error_snackbar: false,
        response: false,
        isSubmit: false,
        data: null,
    }),

    methods: {
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/auth/profile-details")
                .then(res => {
                    console.log(res.data)
                    this.load = true
                    this.data = res.data.list
                });
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        edit_profile(){
            this.$router.replace({ name: 'edit-profile' })
        }
    },
    mounted() {
        this.getData()
        this.initDir()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
