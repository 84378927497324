import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"container"},[_c(VCard,{staticClass:"login-card row",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"col-lg-6",staticStyle:{"text-align":"justify"}},[_c('h3',{staticClass:"my-title"},[_vm._v(" "+_vm._s(_vm.$t('login.title')))]),_c(VForm,{staticStyle:{"margin-top":"70px"}},[_c(VTextField,{attrs:{"type":"email","outlined":"","reverse":_vm.direction,"error-messages":_vm.emailErrors,"label":_vm.$t('login.email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"type":"password","outlined":"","reverse":_vm.direction,"error-messages":_vm.passwordErrors,"label":_vm.$t('login.password')},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VBtn,{staticStyle:{"margin-top":"15px"},attrs:{"disabled":_vm.isSubmit && !_vm.response,"color":"primary","light":""},on:{"click":_vm.submit}},[(_vm.isSubmit && !_vm.response)?_c(VProgressCircular,{attrs:{"size":20,"indeterminate":"","color":"white"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('login.submit'))+" ")],1),_c(VSnackbar,{attrs:{"left":"","top":"","color":"red","text":"","timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"red ","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.$t('login.error_message'))+" ")])],1)],1),_c('div',{staticClass:"col-lg-6 img-container"},[_c('img',{staticStyle:{"width":"80%"},attrs:{"src":require("@/assets/img/login.svg")}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }