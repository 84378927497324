<template>
    <div id="add-occasion" class="add-occasion add">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('occasion.display') }}
                <router-link to="/occasions/list">
                    <v-btn color="primary" outlined>
                        {{ $t('occasion.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="load">
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_ar" readonly
                                :label="$t('occasion.name_ar')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_en" readonly
                                :label="$t('occasion.name_en')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="address_ar" readonly
                                :label="$t('occasion.address_ar')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="address_en" readonly
                                :label="$t('occasion.address_en')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="price" readonly
                                :label="$t('occasion.price')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-select outlined v-model="city_id" :reverse="direction" :items="city_list" item-text="name"
                                readonly item-value="id" :label="$t('occasion.city')"></v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" v-model="data.phone" readonly
                                :label="$t('occasion.phone')"></v-text-field>
                        </div>
                        <div class="col-lg-6" v-if="data.service != null">
                            <v-select outlined v-model="service_id" :reverse="direction"
                                :items="service_list" item-text="name" item-value="id" readonly></v-select>
                        </div>
                        <div class="col-lg-6" v-if="data.resturant != null">
                            <v-select outlined v-model="resturant_id" :reverse="direction"
                                :items="resturant_list" item-text="name" item-value="id" readonly></v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="description_ar" readonly
                                :label="$t('occasion.description_ar')"></v-textarea>
                        </div>
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="description_en" readonly
                                :label="$t('occasion.description_en')"></v-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-img :src="data.media_urls.url" aspect-ratio="1.7" style="border-radius: 15px"></v-img>
                        </div>
                        <div class="col-lg-6">
                            <v-carousel cycle height="300" hide-delimiter-background show-arrows-on-hover>
                                <v-carousel-item v-for="(item, index) in data.media" :key="index">
                                    <v-img :src="item.url" aspect-ratio="1.7" style="border-radius: 15px"></v-img>
                                </v-carousel-item>
                            </v-carousel>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" v-model="start_date"
                                :label="$t('occasion.start_date')" readonly class="my-date"
                                append-icon="mdi-calendar"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field class="my-time" outlined :reverse="direction" v-model="start_time"
                                :label="$t('occasion.start_time')" append-icon="mdi-clock-time-four-outline"
                                readonly></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" v-model="end_date" :label="$t('occasion.end_date')"
                                readonly class="my-date" append-icon="mdi-calendar"></v-text-field>

                        </div>
                        <div class="col-lg-6">
                            <v-text-field class="my-time" outlined :reverse="direction" v-model="end_time"
                                :label="$t('occasion.end_time')" append-icon="mdi-clock-time-four-outline"
                                readonly></v-text-field>
                        </div>
                    </div>

                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'DisplayOccasions',
    components: {
        Breadcrumbs,
    },

    data: () => ({
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_en: [
            {
                text: 'Occasions',
                disabled: true,
                href: '',
            },
            {
                text: 'Display',
                disabled: false,
                href: '/occasions/display',
            },
        ],
        bread_ar: [
            {
                text: 'مناسبات',
                disabled: true,
                href: '',
            },

            {
                text: 'عرض',
                disabled: false,
                href: '/occasions/display',
            },
        ],
        direction: false,
        lang: '',
        route: '',
        name_ar: '',
        name_en: '',
        description_ar: '',
        description_en: '',
        price: '',
        status: 1,
        images: [],
        main_image: null,
        id: null,
        edit: false,
        load: false,
        city_id: '',
        city_list: [],
        address_ar: '',
        address_en: '',
        resturant_list: [],
        resturant_id: '',
        service_list: [],
        service_id: '',

        start_date_modal: false,
        start_date: '',
        end_date_modal: false,
        end_date: '',

        start_time_modal: false,
        start_time: '',
        end_time_modal: false,
        end_time: '',
        data: null
    }),
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getCity() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/cities?lang=" + this.lang)
                .then(res => {
                    this.city_list = res.data.list
                });
        },
        getResturant() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/resturants?lang=" + this.lang)
                .then(res => {
                    this.resturant_list = res.data.list
                });
        },
        getService() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/services?lang=" + this.lang)
                .then(res => {
                    this.service_list = res.data.list
                });
        },
        getData() {
            this.id = this.$route.params.id
            this.axios.get(this.$store.state.url + "/api/v1/admin/occasion/" + this.id + "?lang=ar")
                .then(res => {
                    this.load = true
                    this.data = res.data.list
                    this.name_en = this.data.title_en
                    this.name_ar = this.data.title_ar
                    this.description_en = this.data.description_en
                    this.description_ar = this.data.description_ar
                    this.address_en = this.data.location_en
                    this.address_ar = this.data.location_ar
                    this.phone = this.data.phone
                    this.price = parseInt(this.data.price)
                    this.city_id = this.data.city.id
                    if (this.data.resturant != null)
                        this.resturant_id = this.data.resturant.id
                    else if (this.data.service != null)
                        this.service_id = this.data.service.id


                    this.start_date = this.data.start_date.slice(0, 10)
                    this.end_date = this.data.end_date.slice(0, 10)

                    this.start_time = this.data.start_date.slice(11, 16)
                    this.end_time = this.data.end_date.slice(11, 16)
                    // this.main_image = this.data.media_urls.url

                    console.log(res.data.list)
                })
        },
    },
    mounted() {
        this.initDir()
        this.getData()
        this.getCity()
        this.getResturant()
        this.getService()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.vue-map {
    border-radius: 20px;
}
</style>
