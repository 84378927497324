<template>
    <div id="no-permission" class="no-permission">
        <div class="d-flex align-items-center justify-content-center" style="margin: 30px 0px;flex-direction: column">
            <v-img src="@/assets/img/no-permission2.svg" style="min-width:35%"></v-img>
            <h5>You don't have access to this feature,</h5>
            <h6>Please, contact your administrator.</h6>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoPermission',
    components: {
    },
    data: () => ({
        lang: '',
    }),

    watch: {
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en') {
                this.direction = false
            }
            else {
                this.direction = true
            }
        },
    },
    mounted() {
        this.lang = localStorage.getItem('lang')
        this.initDir()
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);
</style>
