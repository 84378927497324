<template>
    <div id="add-role" class="add-role add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en' && this.route == 'add-role'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar' && this.route == 'add-role'" />
        <div class="card">
            <div class="card-header">
                {{ $t('role.add') }}
                <router-link to="/role/list">
                    <v-btn color="primary" outlined>
                        {{ $t('role.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form>
                    <v-text-field outlined :reverse="direction" required v-model="name" :error-messages="nameErrors"
                        :label="$t('role.name')"></v-text-field>
                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 15px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Breadcrumbs from "@/components/Breadcrumbs.vue"
export default {
    name: 'Addrole',
    components: {
        Breadcrumbs
    },
    mixins: [validationMixin],

    data: () => ({
        name: '',
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        timeout: 3000,
        bread_add_en: [
            {
                text: "Roles & Permissions",
                disabled: true,
                href: '',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/role/add',
            },
        ],
        bread_add_ar: [
            {
                text: 'الادوار والصلاحيات',
                disabled: true,
                href: '',
            },
            {
                text: 'إضافة',
                disabled: false,
                href: '/role/add',
            },
        ],
        direction: false,
        lang: '',
        edit: false,
        route: '',
        id: '',
        load: false,
    }),

    validations: {
        name: { required },
    },
    computed: {
        nameErrors() {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.required && errors.push('Name is required')
            return errors
        },
    },

    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('name', this.name)
            this.addData(formData)
        },
        addData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/roles", formData)
                .then(res => {
                    console.log(res.data)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'role-list' })
                    }
                }).catch(error => {
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
    },
    mounted() {
        this.initDir()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
