<template>
    <div id="list-attributes" class="list-attributes list">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('attributes.list') }}
                <router-link to="/attributes/add">
                    <v-btn color="primary" outlined>
                        {{ $t('attributes.add') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <div v-if="load">
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" :reverse="direction"
                            single-line hide-details></v-text-field>
                        <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table :headers="$t('attributes.headers')" :items="data" :search="search" class="my-table">
                        <template v-slot:top>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-spacer></v-spacer>
                                    <v-card-title class="justify-content-center" style="padding-top: 30px">
                                        {{ $t('confrim_delete') }}
                                    </v-card-title>
                                    <v-card-actions style="padding-bottom: 30px">
                                        <v-spacer></v-spacer>
                                        <v-btn color="gray" text @click="closeDelete">{{ $t('cancel') }}
                                        </v-btn>
                                        <v-btn color="red darken-1" text @click="deleteItemConfirm">{{ $t('confrim') }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                        <template v-slot:[`item.values`]="{ item }">
                            <div @click="displayItem(item)">
                                <i class="fa fa-eye"></i>
                            </div>
                        </template>
                        <template v-slot:[`item.icon`]="{ item }">
                            <v-img :src="item.icon" :aspect-ratio="16 / 9" max-width="110"></v-img>
                        </template>
                        <template v-slot:[`item.mangement`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="deleteItem(item)">
                                    <i class="fa fa-trash"></i>
                                </div>
                                <div @click="editItem(item)">
                                    <i class="fas fa-pen"></i>
                                </div>
                            </div>
                        </template>
                    </v-data-table>
                </div>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
                <v-dialog v-model="dialogValues" max-width="700px">
                    <v-card>
                        <v-spacer></v-spacer>
                        <v-card-title class="justify-content-start"
                            style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 40px;">
                            Values
                        </v-card-title>
                        <div>
                            <v-simple-table style="margin: 10px 30px 30px; padding: 0px; text-align: justify;">
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="(item, index) in values" :key="index">
                                            <td>{{ item.name }}</td>
                                            <!-- <td>Value (ar)</td> -->
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </div>
                        <v-card-actions style="justify-content: end; padding-bottom: 30px;margin-left: 20px;">
                            <v-btn color="primary" @click="dialogValues = false">{{ $t('close') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                    {{ $t('delete_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                    {{ $t('error_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="red" text v-model="delete_snackbar" :timeout="3000">
                    {{ $t('can_not_delete_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="delete_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'attributesList',
    components: {
        Breadcrumbs
    },
    data: () => ({
        search: '',
        lang: '',
        direction: false,
        data: [],
        load: false,
        dialogDelete: false,
        editedIndex: -1,
        snackbar: false,
        error_snackbar: false,
        delete_snackbar: false,
        delete: '',
        bread_en: [
            {
                text: 'Attributes',
                disabled: true,
                href: '',
            },
            {
                text: 'List',
                disabled: false,
                href: '/attributes/list',
            },
        ],
        bread_ar: [
            {
                text: 'المواصفات',
                disabled: true,
                href: '',
            },
            {
                text: 'عرض',
                disabled: false,
                href: '/attributes/list',
            },
        ],
        dialogValues: false,
        values: []
    }),
    watch: {
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/attributes?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    if (res.data.status == "Success")
                        this.data = res.data.list.reverse()
                    console.log(res.data.list)
                });
        },
        editItem(item) {
            console.log("Edit: " + item.id)
            this.$router.replace({ name: 'edit-attributes', params: { id: item.id } })
        },
        deleteItem(item) {
            console.log("Delete: " + item.id)
            this.editedIndex = this.data.indexOf(item)
            this.delete = item
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.sendIdDeleted()
            this.closeDelete()
        },
        closeDelete() {
            this.dialogDelete = false
        },
        sendIdDeleted() {
            this.axios.delete(this.$store.state.url + "/api/v1/admin/attributes/" + this.delete.id)
                .then((res) => {
                    console.log(res.response.data.message)
                    if (res.response.data.message == "data cannot be deleted")
                    {
                        this.delete_snackbar = true
                    }
                    if (res.data.status == "Success") {
                        this.data.splice(this.editedIndex, 1)
                        this.snackbar = true
                    }
                })
                .catch(error => {
                    this.error_snackbar = true
                })
        },
        displayItem(item) {
            this.dialogValues = true
            this.values = item.core_terms
            console.log(this.values)
        }
    },
    mounted() {
        this.lang = localStorage.getItem('lang')
        this.getData()
        this.initDir()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
