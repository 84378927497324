<template>
    <div class="login">
        <div class="container">
            <v-card class="login-card row" style="align-items: center;">
                <div class="col-lg-6" style="text-align: justify">
                    <h3 class="my-title"> {{ $t('login.title') }}</h3>
                    <v-form style="margin-top: 70px">
                        <v-text-field type="email" outlined :reverse="direction" v-model="email"
                            :error-messages="emailErrors" :label="$t('login.email')"></v-text-field>
                        <v-text-field type="password" outlined :reverse="direction" v-model="password"
                            :error-messages="passwordErrors" :label="$t('login.password')"></v-text-field>
                        <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light
                            style="margin-top: 15px">
                            <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                                color="white"></v-progress-circular>
                            {{ $t('login.submit') }}
                        </v-btn>
                        <v-snackbar left top color="red" text v-model="snackbar" timeout="3000">
                            {{ $t('login.error_message') }}
                            <template v-slot:action="{ attrs }">
                                <v-btn color="red " text v-bind="attrs" @click="snackbar = false">
                                    {{ $t('close') }}
                                </v-btn>
                            </template>
                        </v-snackbar>
                    </v-form>
                </div>
                <div class="col-lg-6 img-container">
                    <img src="@/assets/img/login.svg" style="width: 80%" />
                </div>
            </v-card>
        </div>
    </div>
</template>


<script>

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapMutations } from "vuex";
export default {
    name: 'Login',
    components: {
    },
    mixins: [validationMixin],
    validations: {
        email: { required, email },
        password: { required },
    },

    data: () => ({
        response: false,
        isSubmit: false,
        snackbar: false,
        email: null,
        password: null,
        lang: null,
        direction: null,
        permission: [],
    }),

    computed: {
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.required && errors.push('This feild is required')
            !this.$v.email.email && errors.push('Please enter correct email address')
            return errors
        },
        passwordErrors() {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('This feild is required')
            return errors
        }
    },
    methods: {
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            console.log(this.email)
            console.log(this.password)

            this.axios.post(this.$store.state.url + "/api/v1/auth/login?email=" + this.email + "&password=" + this.password)
                .then(res => {
                    this.response = true
                    console.log(res.data.list)
                    // console.log(res)
                    if (res.data.status != "Success") {
                        // console.log("000000000000000")
                        this.email = ''
                        this.password = ''
                        this.error_snackbar = true
                        this.isSubmit = false
                        this.response = false
                        this.$v.$reset()
                    }
                    else {
                        // console.log("1111111111111")
                        this.axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.list.token}`
                        this.addsessionStorage(res.data.list.token, res.data.list.roles[0].pivot.model_id, res.data.list.roles[0].name, res.data.list.roles[0].permissions)
                        if (res.data.list.roles[0].name == 'administrator')
                            this.$router.replace({ name: 'dashboard' })
                        else
                            this.$router.replace({ name: 'profile' })
                    }
                }).catch(error => {
                    this.email = ''
                    this.password = ''
                    this.$v.$reset()
                    // console.log(error)
                    this.error_snackbar = true
                    this.isSubmit = false
                    this.response = false
                })
        },
        addsessionStorage(token, id, name, permissions) {
            // console.log("2222222222222222222")
            sessionStorage.setItem("token", token)
            sessionStorage.setItem("id", id)
            sessionStorage.setItem("name", name)
            sessionStorage.setItem("permission", JSON.stringify(permissions))
            console.log(permissions)
            sessionStorage.setItem("auth", true)
            // console.log("33333333333333")

        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
    },
    mounted() {
        this.initDir()
    }
};
</script>

<style lang="scss">
@import url(@/assets/Css/main.css);

.login .my-title {
    color: var(--v-primary-base);
    font-weight: bold;
}

.login .login-card {
    border: none;
    border-radius: 20px;
    padding: 80px;
    margin: 60px 0px 50px;
    box-shadow: 0 0 10px #c5c5c5 !important;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // row-gap: 50px;
}

.login .left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .v-messages__message {
    text-align: justify;
}

@media (max-width: 992px) {
    .login .img-container {
        display: none
    }
}

.login .img-container {
    display: flex;
    justify-content: center;
}
</style>
