<template>
    <div id="list-meal" class="list-meal list">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('meal.list') }}
                <v-btn color="primary" outlined @click="AddMeal()">
                    {{ $t('meal.add') }}
                </v-btn>
            </div>
            <div class="card-body">
                <div v-if="load">
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" :reverse="direction"
                            single-line hide-details></v-text-field>
                        <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table :headers="$t('meal.headers')" :items="data" :search="search" class="my-table">
                        <template v-slot:top>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-spacer></v-spacer>
                                    <v-card-title class="justify-content-center" style="padding-top: 30px">
                                        {{ $t('confrim_delete') }}
                                    </v-card-title>
                                    <v-card-actions style="padding-bottom: 30px">
                                        <v-spacer></v-spacer>
                                        <v-btn color="gray" text @click="closeDelete">{{ $t('cancel') }}
                                        </v-btn>
                                        <v-btn color="red darken-1" text @click="deleteItemConfirm">{{ $t('confrim') }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>

                        <template v-slot:[`item.mangement`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="deleteItem(item)">
                                    <i class="fa fa-trash"></i>
                                </div>
                                <div @click="editItem(item)">
                                    <i class="fas fa-pen"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.media_urls`]="{ item }">
                            <v-img :src="item.media_urls[0]" max-width="80" @click="displayImage(item)"
                                style="cursor: pointer"></v-img>
                            <!-- <v-img :src="item.media_urls[0]" :aspect-ratio="16 / 9" max-width="110"></v-img> -->
                        </template>
                    </v-data-table>
                    <v-dialog v-model="dialogImage">
                        <v-card>
                            <v-spacer></v-spacer>
                            <v-card-title class="justify-content-center;" style="padding: 0px">
                                <v-img :src="image"></v-img>
                            </v-card-title>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
                <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                    {{ $t('delete_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                    {{ $t('error_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'menuList',
    components: {
        Breadcrumbs
    },
    data: () => ({
        search: '',
        lang: '',
        direction: false,
        data: [],
        load: false,
        dialogDelete: false,
        editedIndex: -1,
        snackbar: false,
        error_snackbar: false,
        change_snackbar: false,
        delete: '',
        bread_en: [
        ],
        bread_ar: [
        ],
        menu_id: '',
        resturant_id: '',
        dialogImage: false,
        image: null
    }),
    watch: {
        resturant_id(newVal) {
            this.bread_en = [
                {
                    text: 'Restaurant',
                    disabled: false,
                    href: '/resturant/list',
                },
                {
                    text: 'Menu',
                    disabled: false,
                    href: '/menu/list/' + newVal,
                },
                {
                    text: 'Meal',
                    disabled: true,
                    href: '',
                },
            ]
            this.bread_ar = [
                {
                    text: 'مطعم',
                    disabled: false,
                    href: '/resturant/list',
                },
                {
                    text: 'قائمة الطعام',
                    disabled: false,
                    href: '/menu/list/' + newVal,
                },
                {
                    text: 'الوجبة',
                    disabled: true,
                    href: '',
                },
            ]
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    methods: {
        displayImage(item) {
            this.image = item.media_urls[0]
            this.dialogImage = true
        },
        AddMeal() {
            this.$router.replace({ name: 'add-meal', params: { id: this.menu_id } })
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/menus/" + this.menu_id + "/meals?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    if (res.data.status == "Success") {
                        this.data = res.data.list.reverse()
                    }
                    console.log(res.data.list)
                });
        },
        editItem(item) {
            console.log("Edit: " + item.id)
            this.$router.replace({ name: 'edit-meal', params: { id: item.id } })
        },
        deleteItem(item) {
            console.log("Delete: " + item.id)
            this.editedIndex = this.data.indexOf(item)
            this.delete = item
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.sendIdDeleted()
            this.closeDelete()
        },
        closeDelete() {
            this.dialogDelete = false
        },
        sendIdDeleted() {
            this.axios.delete(this.$store.state.url + "/api/v1/admin/meals/" + this.delete.id)
                .then((res) => {
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.data.splice(this.editedIndex, 1)
                        this.snackbar = true
                    }
                })
                .catch(error => {
                    this.error_snackbar = true
                })
        },

    },
    mounted() {
        this.lang = localStorage.getItem('lang')
        this.initDir()
        this.menu_id = this.$route.params.id
        this.resturant_id = this.$route.params.resturant_id

        console.log(this.menu_id)
        this.getData()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
