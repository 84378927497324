<template>
    <div id="add-banners" class="add-banners add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en' && this.route == 'add-banners'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar' && this.route == 'add-banners'" />
        <breadcrumbs :breadcrumbs="bread_edit_en" v-if="lang == 'en' && this.route == 'edit-banners'" />
        <breadcrumbs :breadcrumbs="bread_edit_ar" v-if="lang == 'ar' && this.route == 'edit-banners'" />
        <div class="card">
            <div class="card-header" v-if="this.route == 'add-banners'">
                {{ $t('banners.add') }}
                <router-link to="/banners/list">
                    <v-btn color="primary" outlined>
                        {{ $t('banners.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-header" v-else-if="this.route == 'edit-banners'">
                {{ $t('banners.edit') }}
                <router-link to="/banners/list">
                    <v-btn color="primary" outlined>
                        {{ $t('banners.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="(this.route == 'add-banners' && !load) || (this.route == 'edit-banners' && load)">
                    <div class="row">
                        <div class="col-lg-12">
                            <v-text-field outlined :reverse="direction" required v-model="url" :error-messages="urlErrors"
                                :label="$t('banners.url')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <v-select outlined v-model="type" :reverse="direction" :items="type_en" v-if="lang == 'en'"
                                item-text="title" item-value="id" :label="$t('banners.type')"
                                :error-messages="typeErrors"></v-select>
                            <v-select outlined v-model="type" :reverse="direction" :items="type_ar" v-if="lang == 'ar'"
                                item-text="title" item-value="id" :label="$t('banners.type')"
                                :error-messages="typeErrors"></v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <v-file-input append-icon="mdi-camera" prepend-icon="" :label="$t('banners.image')" accept="image/png, image/jpeg, image/bmp"
                                :reverse="direction" v-model="image" outlined :error-messages="imageErrors" @change="changeImage"></v-file-input>
                                <v-img :src="display_image" max-height="200" style="margin-bottom: 30px" contain v-if="display_image != ''">
                            </v-img>
                        </div>
                    </div>
                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 15px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, url, requiredIf } from 'vuelidate/lib/validators'
import Breadcrumbs from "@/components/Breadcrumbs.vue"
export default {
    name: 'Addbanners',
    components: {
        Breadcrumbs
    },
    mixins: [validationMixin],

    data: () => ({
        url: '',
        image: null,
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_add_en: [
            {
                text: 'Banners',
                disabled: true,
                href: '',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/banners/add',
            },
        ],
        bread_add_ar: [
            {
                text: 'اعلانات',
                disabled: true,
                href: '',
            },
            {
                text: 'إضافة',
                disabled: false,
                href: '/banners/add',
            },
        ],
        bread_edit_en: [
            {
                text: 'Banners',
                disabled: true,
                href: '',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        bread_edit_ar: [
            {
                text: 'اعلانات',
                disabled: true,
                href: '',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            },
        ],
        direction: false,
        lang: '',
        edit: false,
        route: '',
        id: '',
        load: false,
        type_en: [
            {
                title: "Rstaurants",
                id: 1
            },
            {
                title: "Public Service",
                id: 2
            },
            {
                title: "Occasions",
                id: 3
            }
        ],
        type_ar: [
            {
                title: "مطاعم",
                id: 1
            },
            {
                title: "خدمات عامة",
                id: 2
            },
            {
                title: "مناسبات",
                id: 3
            }
        ],
        type: null,
        display_image: '',
        orginal_image: ''
    }),

    validations: {
        url: { required, url },
        type: { required },
        image: {
            required: requiredIf(function (item) {
                return !item.edit
            })
        },
    },

    computed: {
        urlErrors() {
            const errors = []
            if (!this.$v.url.$dirty) return errors
            !this.$v.url.url && errors.push('Please enter correct url')
            !this.$v.url.required && errors.push('This field is required')
            return errors
        },
        imageErrors() {
            const errors = []
            if (!this.$v.image.$dirty) return errors
            !this.$v.image.required && errors.push('This field is required')
            return errors
        },
        typeErrors() {
            const errors = []
            if (!this.$v.type.$dirty) return errors
            !this.$v.type.required && errors.push('This field is required')
            return errors
        },
    },

    methods: {
        changeImage(event){
            if (event != null)
                this.display_image = URL.createObjectURL(event);
            else {
                this.display_image = this.orginal_image
            }
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('url_link', this.url)
            formData.append('lang', "en")
            if (this.image != null)
                formData.append('image', this.image)
            if (this.type == 1)
                formData.append('service', 'restaurant')
            else if (this.type == 2)
                formData.append('service', 'service')
            else
                formData.append('service', 'occasion')

            if (this.edit == true) {
                this.editData(formData)
            }
            else {
                this.addData(formData)
            }
        },
        addData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/banners", formData)
                .then(res => {
                    console.log(res.data)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'banners-list' })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        editData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/banners/" + this.id, formData)
                .then(res => {
                    console.log(res)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'banners-list' })
                    }
                }).catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        initEdit() {
            this.route = this.$route.name
            if (this.$route.name == "edit-banners") {
                this.id = this.$route.params.id
                this.edit = true
                this.axios.get(this.$store.state.url + "/api/v1/admin/banners/" + this.id)
                    .then(res => {
                        this.load = true
                        console.log(res.data.list)
                        this.url = res.data.list.url_link
                        this.provider_id = res.data.list.provider_id
                        this.display_image = res.data.list.media_urls.url
                        this.orginal_image = res.data.list.media_urls.url

                        if (res.data.list.service == 'restaurant')
                            this.type = 1
                        else if (res.data.list.service == 'service')
                            this.type = 2
                        else
                            this.type = 3
                    })
            }
        },

    },
    mounted() {
        this.initDir()
        this.initEdit()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
