<template>
    <div id="add-service" class="add-service add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('service.display') }}
                <router-link to="/service/list">
                    <v-btn color="primary" outlined>
                        {{ $t('service.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="load">
                    <h5>{{ $t('service.main_details') }}</h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="data.name" readonly
                                :label="$t('service.name')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <p>{{ $t('service.rate') }}</p>
                            <v-rating style="top: -10px; position: relative;" :value="Number(data.star_rate)" color="amber"
                                length="5" background-color="grey" readonly size="25"></v-rating>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="data.price" readonly
                                :label="$t('service.price')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined v-model="data.category.name_en" :reverse="direction" readonly
                                v-if="lang == 'en'" :label="$t('service.cat')"></v-text-field>
                            <v-text-field outlined v-model="data.category.name_ar" :reverse="direction" readonly
                                v-if="lang == 'ar'" :label="$t('service.cat')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="data.content" readonly
                                :label="$t('service.description')"></v-textarea>
                        </div>
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="data.policy" readonly
                                :label="$t('service.policy')"></v-textarea>
                        </div>
                    </div>

                    <h5>{{ $t('service.images2') }}</h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-img :src="data.mainImage.url" aspect-ratio="1.7" style="border-radius: 15px"></v-img>
                        </div>
                        <div class="col-lg-6">
                            <v-carousel cycle height="300" hide-delimiter-background show-arrows-on-hover>
                                <v-carousel-item v-for="(item, index) in data.media" :key="index">
                                    <v-img :src="item.url" aspect-ratio="1.7" style="border-radius: 15px"></v-img>
                                </v-carousel-item>
                            </v-carousel>
                        </div>
                    </div>
                    <div v-if="data.terms.length != 0" style="margin-top:30px">
                        <h5>{{ $t('service.attribute') }}</h5>
                        <v-divider></v-divider>
                        <div v-for="(item, index) in attribute" :key="index">
                            <div class="row">
                                <div class="col-lg-6">
                                    <v-select outlined v-model="item.id" :reverse="direction" :items="attribute_list"
                                        readonly item-text="name" item-value="id"
                                        :label="$t('service.attribute_name')"></v-select>
                                </div>
                                <div class="col-lg-6">
                                    <v-select outlined v-model="item.value_id" :reverse="direction" multiple readonly
                                        :items="att_valuesFiltered(index)" item-text="name" item-value="id"
                                        :label="$t('service.attribute_value')"></v-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="data.features.length != 0" style="margin-top:30px">
                        <h5>{{ $t('service.feature') }}</h5>
                        <v-divider></v-divider>
                        <div v-for="(item, index) in data.features" :key="index">
                            <div class="row">
                                <div class="col-lg-6">
                                    <v-text-field readonly outlined v-model="item.title" :reverse="direction"
                                        :label="$t('service.feature_title')"></v-text-field>
                                </div>
                                <div class="col-lg-6">
                                    <v-text-field readonly outlined v-model="item.content" :reverse="direction"
                                        :label="$t('service.feature_content')"></v-text-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5>{{ $t('service.connection') }} </h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" v-model="data.phone" readonly
                                :label="$t('service.phone')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" v-model="data.email" readonly
                                :label="$t('service.email')"></v-text-field>
                        </div>
                    </div>
                    <h5>{{ $t('service.location_details') }} </h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" v-model="data.location.name" readonly
                                :label="$t('service.city')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" v-model="data.address" readonly
                                :label="$t('service.address')"></v-text-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <GmapMap :center="{ lat: marker.lat, lng: marker.lng }" :zoom="12" map-type-id="terrain"
                                style="height: 200px">
                                <GmapMarker :position="marker" />
                            </GmapMap>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="data.map_lat" readonly
                                :label="$t('service.latitude')"></v-text-field>
                            <v-text-field outlined :reverse="direction" required v-model="data.map_lng" readonly
                                :label="$t('service.longtitude')"></v-text-field>
                        </div>
                    </div>

                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'DisplayService',
    components: {
        Breadcrumbs,
    },

    data: () => ({
        damas_lat: 33.5138,
        damas_lng: 36.2765,
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_add_en: [
            {
                text: 'Public Service',
                disabled: true,
                href: '',
            },
            {
                text: 'Display',
                disabled: false,
                href: '/service/display',
            },
        ],
        bread_add_ar: [
            {
                text: 'خدمات عامة',
                disabled: true,
                href: '',
            },

            {
                text: 'عرض',
                disabled: false,
                href: '/service/display',
            },
        ],
        direction: false,
        lang: '',
        load: false,
        route: '',
        user_id: '',
        categories: [],
        name: '',
        description: '',
        price: '',
        latitude: null,
        longtitude: null,
        status: 1,
        tags_list: [],
        images: [],
        main_image: null,
        category_id: '',
        attribute: [],
        attribute_list: [],
        id: null,
        city_id: '',
        city_list: [],
        address: '',
        marker: {
            lat: null,
            lng: null
        },
        att_values: [],
        feature: [],
        policy: '',
        phone: '',
        email: '',
        rating: 0,
        data: null

    }),

    methods: {
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/services/" + this.id + "?lang=" + this.lang + "&is_active=1")
                .then(res => {
                    this.load = true
                    this.data = res.data.list
                    console.log(res.data.list)
                    this.marker.lat = parseFloat(this.data.map_lat)
                    this.marker.lng = parseFloat(this.data.map_lng)
                    if (this.data.terms.length != 0) {
                        this.data.terms.forEach(item => {
                            item.core_terms.forEach(item2 => {
                                if (item2.status == true) {
                                    this.attribute.push({
                                        id: item.id,
                                        value_id: item2.id
                                    });
                                }
                            })
                        })
                    }
                    // console.log(this.attribute)

                    let mergedAttribute = [];
                    this.attribute.forEach(item => {
                        let existingItem = mergedAttribute.find(a => a.id === item.id);
                        if (existingItem) {
                            existingItem.value_id.push(item.value_id);
                        } else {
                            mergedAttribute.push({ id: item.id, value_id: [item.value_id] });
                        }
                    });
                    this.attribute = mergedAttribute

                    // console.log(mergedAttribute)
                });
        },
        att_valuesFiltered(index) {
            return this.att_values.filter(item => item.core_attribute_id == this.attribute[index].id)
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getCategory() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/services-categories?lang=" + this.lang + "&is_active=1")
                .then(res => {
                    // console.log(res.data)
                    // if (res.data.error == false)
                    this.categories = res.data.list
                    // console.log(res)
                });
        },
        getCity() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/cities?lang=" + this.lang)
                .then(res => {
                    // console.log(res.data)
                    // if (res.data.error == false)
                    this.city_list = res.data.list
                    // console.log(this.city_list)
                });
        },
        getAttribute() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/attributes?lang=" + this.lang)
                .then(res => {
                    this.attribute_list = res.data.list
                    console.log(this.attribute_list)
                    this.attribute_list = this.attribute_list.filter(item => item.service == "service")
                    console.log(this.attribute_list)
                    let arr = []
                    this.attribute_list.forEach(item => {
                        arr.push(item.core_terms)
                    })
                    this.att_values = [].concat(...arr)
                    // console.log(this.att_values)
                });
        },
    },
    mounted() {
        this.initDir()
        this.route = this.$route.name
        this.id = this.$route.params.id
        this.getCategory()
        this.getCity()
        this.getAttribute()
        this.getData()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.vue-map {
    border-radius: 20px;
}
</style>
