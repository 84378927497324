<template>
    <div id="list-occasion" class="list-occasion list">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('occasion.list') }}
                <router-link to="/occasions/add">
                    <v-btn color="primary" outlined>
                        {{ $t('occasion.add') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <div v-if="load">
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" :reverse="direction"
                            single-line hide-details></v-text-field>
                        <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table :headers="$t('occasion.headers')" :items="data" :search="search" class="my-table">
                        <template v-slot:top>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-spacer></v-spacer>
                                    <v-card-title class="justify-content-center" style="padding-top: 30px">
                                        {{ $t('confrim_delete') }}
                                    </v-card-title>
                                    <v-card-actions style="padding-bottom: 30px">
                                        <v-spacer></v-spacer>
                                        <v-btn color="gray" text @click="closeDelete">{{ $t('cancel') }}
                                        </v-btn>
                                        <v-btn color="red darken-1" text @click="deleteItemConfirm">{{ $t('confrim') }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>
                        <template v-slot:[`item.mangement`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="deleteItem(item)">
                                    <i class="fa fa-trash"></i>
                                </div>
                                <div @click="displayItem(item)">
                                    <i class="fa fa-eye"></i>
                                </div>
                                <div @click="editItem(item)">
                                    <i class="fas fa-pen"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.booking`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="displayBooking(item)">
                                    <i class="fa fa-eye"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <div class="row justify-content-center" style="top: 15px; position: relative; direction: ltr;">
                                <v-switch v-model="item.status" inset color="green"
                                @click="statusItem(item)"></v-switch>
                            </div>
                        </template>
                        <template v-slot:[`item.featured`]="{ item }">
                            <div class="row justify-content-center" style="top: 15px; position: relative; direction: ltr;">
                                <v-switch v-model="item.featured" inset color="green" :disabled="!isAdmin"
                                    @click="featuredItem(item)"></v-switch>
                            </div>
                        </template>
                    </v-data-table>
                </div>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
                <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                    {{ $t('delete_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                    {{ $t('error_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="green" text v-model="change_snackbar" :timeout="3000">
                    {{ $t('change_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="change_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'occasionList',
    components: {
        Breadcrumbs
    },
    data: () => ({
        direction: false,
        search: '',
        lang: '',
        data: [],
        natural_data: [],
        load: false,
        dialogDelete: false,
        dialogDetails: false,
        editedIndex: -1,
        delete: '',
        snackbar: false,
        error_snackbar: false,
        change_snackbar: false,
        bread_en: [
            {
                text: 'Occasion',
                disabled: true,
                href: '',
            },
            {
                text: 'List',
                disabled: false,
                href: '/occasions/list',
            },
        ],
        bread_ar: [
            {
                text: 'المناسبات ',
                disabled: true,
                href: '',
            },
            {
                text: 'عرض',
                disabled: false,
                href: '/occasions/list',
            },
        ],
        details: null,
        isAdmin: false,
    }),
    watch: {
        dialogDelete(val) {
            val || this.closeDelete()
        },
        
    },
    computed: {
    },
    methods: {
        displayBooking(item) {
            this.$router.replace({ name: 'booking-list', params: { section_id: 3, id: item.id } })
        },
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/occasion?lang=" + this.lang)
                .then(res => {
                    console.log(res.data.list)
                    this.load = true
                    if (res.data.status == "Success") {
                        this.data = res.data.list.reverse()
                        this.data.forEach(item => item.featured = Number(item.featured))
                        this.data.forEach(item => item.status = Number(item.status))
                    }
                });
        },
        editItem(item) {
            console.log("Edit: " + item.id)
            this.$router.replace({ name: 'edit-occasions', params: { id: item.id } })
        },
        deleteItem(item) {
            console.log("Delete: " + item.id)
            this.editedIndex = this.data.indexOf(item)
            this.delete = item
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.sendIdDeleted()
            this.closeDelete()
        },
        closeDelete() {
            this.dialogDelete = false
        },
        sendIdDeleted() {
            this.axios.delete(this.$store.state.url + "/api/v1/admin/occasion/" + this.delete.id )
                .then((res) => {
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.data.splice(this.editedIndex, 1)
                        this.snackbar = true
                    }
                })
                .catch(error => {
                    this.error_snackbar = true
                })
        },
        displayItem(item) {
            this.$router.replace({ name: 'display-occasions', params: { id: item.id } })
        },
        statusItem(item) {
            const formData = new FormData()
            formData.append("status", Number(item.status))
            this.axios.post(this.$store.state.url + "/api/v1/admin/occasion/status/" + item.id, formData).then((res) => {
                console.log(res.data)
                if (res.data.status == "Success") {
                    this.change_snackbar = true
                }
            })
                .catch(error => {
                    this.error_snackbar = true
                })
        },
        featuredItem(item) {
            const formData = new FormData()
            formData.append("featured", Number(item.featured))
            this.axios.post(this.$store.state.url + "/api/v1/admin/occasion/featured/" + item.id, formData).then((res) => {
                console.log(res.data)
                if (res.data.status == "Success") {
                    this.change_snackbar = true
                }
            })
                .catch(error => {
                    this.error_snackbar = true
                })
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getListFilter() {
            if (this.filter_id == 1)
                this.filter_list = this.category_list
            else if (this.filter_id == 2)
                this.filter_list = this.sub_list
            else if (this.filter_id == 3)
                this.filter_list = this.partial_list
            else if (this.filter_id == 4)
                this.filter_list = this.state_list
            else if (this.filter_id == 5)
                this.filter_list = this.city_list
        },
        getData2() {
            this.axios.get(this.$store.state.url + "/api/category?lang=" + this.lang)
                .then(res => {
                    this.category_list = res.data.data
                });
            this.axios.get(this.$store.state.url + "/api/get_sub?lang=" + this.lang)
                .then(res => {
                    this.sub_list = res.data.data
                });
            this.axios.get(this.$store.state.url + "/api/get_child?lang=" + this.lang)
                .then(res => {
                    this.partial_list = res.data.data
                });
            this.axios.get(this.$store.state.url + "/api/get_state?lang=" + this.lang)
                .then(res => {
                    this.state_list = res.data.data
                });
            this.axios.get(this.$store.state.url + "/api/get_city?lang=" + this.lang)
                .then(res => {
                    this.city_list = res.data.data
                });
        }
    },
    mounted() {
        this.lang = localStorage.getItem('lang')
        this.getData()
        // this.getData2()
        this.initDir()
        const role_name = sessionStorage.getItem("name")
        if (role_name == "administrator")
            this.isAdmin = true
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

</style>
