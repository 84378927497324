<template>
    <div id="list-booking" class="list-booking list">
        <breadcrumbs :breadcrumbs="bread_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('booking.accept_list') }}
                <div class="row justify-content-end" style="column-gap: 10px;">
                    <v-btn color="primary" outlined @click="cancel_list()">
                        {{ $t('booking.cancel_list') }}
                    </v-btn>
                    <v-btn color="primary" outlined @click="request_list()">
                        {{ $t('booking.request_list') }}
                    </v-btn>
                </div>

            </div>
            <div class="card-body">
                <div v-if="load">
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" :reverse="direction"
                            single-line hide-details></v-text-field>
                        <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table :headers="$t('booking.headers2')" :items="data" :search="search" class="my-table">
                        <template v-slot:[`item.mangement`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="displayItem(item)">
                                    <i class="fa fa-eye"></i>
                                </div>
                            </div>
                        </template>
                        <template v-slot:[`item.notes`]="{ item }">
                            <div class="row justify-content-center">
                                <div @click="addNotes(item)" v-if="item.booking_notes == null">
                                    <i class="fa fa-plus"></i>
                                </div>
                                <div @click="displayNotes(item)" v-if="item.booking_notes != null">
                                    <i class="fa fa-eye"></i>
                                </div>
                            </div>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="displayDialog" max-width="700px" persistent>
                        <v-card>
                            <v-spacer></v-spacer>
                            <v-card-title class="justify-content-start"
                                style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 40px;">
                                {{ $t('booking.details') }}
                            </v-card-title>
                            <div>
                                <v-simple-table style="margin: 10px 30px 30px; padding: 0px; text-align: justify;">
                                    <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <td>{{ $t('booking.customer_name') }}</td>
                                                <td>{{ display.first_name }} {{ display.last_name }}</td>
                                            </tr>
                                            <tr v-if="display.check_in_date != null">
                                                <td>{{ $t('booking.check_in_date') }}</td>
                                                <td>{{ display.check_in_date }}</td>
                                            </tr>
                                            <tr v-if="display.check_out_date != null">
                                                <td>{{ $t('booking.check_out_date') }}</td>
                                                <td>{{ display.check_out_date }}</td>
                                            </tr>
                                            <tr v-if="display.total_guests != null">
                                                <td>{{ $t('booking.total_guests') }}</td>
                                                <td>{{ display.total_guests }}</td>
                                            </tr>
                                            <tr v-if="display.total_price != null">
                                                <td>{{ $t('booking.total_price') }}</td>
                                                <td>{{ display.total_price }}</td>
                                            </tr>
                                            <tr v-if="display.customer_notes != null">
                                                <td>{{ $t('booking.customer_notes') }}</td>
                                                <td>{{ display.customer_notes }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                            <v-card-actions style="justify-content: end; padding-bottom: 30px;margin-left: 20px;">
                                <v-btn color="primary" @click="displayDialog = false">{{ $t('close') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="noteDialog" max-width="700px" persistent>
                        <v-card>
                            <v-spacer></v-spacer>
                            <v-card-title class="justify-content-start" v-if="!edit_toggle"
                                style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 40px;">
                                {{ $t('booking.add_notes') }}
                            </v-card-title>
                            <v-card-title class="justify-content-start" v-if="edit_toggle"
                                style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 40px;">
                                {{ $t('booking.edit_notes') }}
                            </v-card-title>
                            <div class="container">
                                <v-textarea outlined :reverse="direction" required v-model="note"
                                    :label="$t('booking.note')"></v-textarea>
                            </div>
                            <v-divider></v-divider>
                            <v-card-actions style="justify-content: end; padding-bottom: 30px;margin-left: 20px;">
                                <v-btn color="gray" @click="noteDialog = false">{{ $t('cancel') }}
                                </v-btn>
                                <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light>
                                    <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate color="white"
                                        style="margin: 0px 5px"></v-progress-circular>
                                    {{ $t('submit') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="displayNoteDialog" max-width="500px" persistent>
                        <v-card>
                            <v-spacer></v-spacer>
                            <v-card-title class="justify-content-start"
                                style="border-bottom: 1px solid #aeaeae;padding-top: 30px;font-weight: bold;margin-bottom: 40px;">
                                {{ $t('booking.note') }}
                            </v-card-title>
                            <div class="container" v-if="displayNoteDialog">
                                <div style="margin-bottom: 40px;margin-left: 10px;text-align: justify;">{{
                                    display_note_item.booking_notes }}</div>
                                <!-- <v-textarea outlined :reverse="direction" required v-model="display_note_item.booking_notes"
                                    readonly :label="$t('booking.note')"></v-textarea> -->
                            </div>
                            <v-divider></v-divider>
                            <v-card-actions style="justify-content: end; padding-bottom: 30px;margin-left: 20px;">
                                <v-btn color="gray" @click="displayNoteDialog = false">{{ $t('ok') }}
                                </v-btn>
                                <v-btn color="green" @click="edit()" v-if="havePermission">{{ $t('edit') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="permissionDialog" max-width="500px">
                        <v-card>
                            <div class="container">
                                <div
                                    style="display: flex; justify-content: center; align-items: center;  flex-direction: column; row-gap: 20px; padding: 40px 20px;">
                                    <b-icon icon="exclamation-circle" style="color: #dc3545"></b-icon>
                                    {{ $t('booking.no_permission') }}
                                </div>
                            </div>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
                <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                    {{ $t('success_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                    {{ $t('error_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar right top color="green" text v-model="change_snackbar" :timeout="3000">
                    {{ $t('change_message') }}
                    <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs" @click="change_snackbar = false">
                            {{ $t('close') }}
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'BookingList',
    components: {
        Breadcrumbs
    },
    data: () => ({
        search: '',
        lang: '',
        direction: false,
        data: [],
        load: false,
        dialogDelete: false,
        editedIndex: -1,
        snackbar: false,
        error_snackbar: false,
        change_snackbar: false,
        delete: '',
        bread_en: [],
        bread_ar: [],
        display: null,
        displayDialog: false,
        section_id: '',
        id: '',
        my_url: '',
        main_name: '',
        noteDialog: false,
        note_item: null,

        displayNoteDialog: false,
        display_note_item: null,

        note: '',
        isSubmit: false,
        response: false,
        edit_toggle: false,

        my_permission: [],
        havePermission: false,
        permissionDialog: false

    }),
    watch: {
        section_id(section) {
            if (section == 1) {
                this.bread_en = [
                    {
                        text: 'Restaurant',
                        disabled: false,
                        href: '/resturant/list',
                    },
                    {
                        text: 'Booking',
                        disabled: true,
                        href: '',
                    },
                    {
                        text: 'Accepted',
                        disabled: true,
                        href: '',
                    }
                ]
                this.bread_ar = [
                    {
                        text: 'مطعم',
                        disabled: false,
                        href: '/resturant/list',
                    },
                    {
                        text: 'الحجوزات',
                        disabled: true,
                        href: '',
                    },
                    {
                        text: 'المقبولة',
                        disabled: true,
                        href: '',
                    }
                ]
            }
            else if (section == 2) {
                this.bread_en = [
                    {
                        text: 'Public Service',
                        disabled: false,
                        href: '/service/list',
                    },
                    {
                        text: 'Booking',
                        disabled: true,
                        href: '',
                    },
                    {
                        text: 'Accepted',
                        disabled: true,
                        href: '',
                    }
                ]
                this.bread_ar = [
                    {
                        text: 'خدمات عامة',
                        disabled: false,
                        href: '/service/list',
                    },
                    {
                        text: 'الحجوزات',
                        disabled: true,
                        href: '',
                    },
                    {
                        text: 'المقبولة',
                        disabled: true,
                        href: '',
                    }
                ]
            }
            else if (section == 3){
                this.bread_en = [
                    {
                        text: "Occasions",
                        disabled: false,
                        href: '/occasions/list',
                    },
                    {
                        text: 'Booking',
                        disabled: true,
                        href: '',
                    },
                    {
                        text: 'Accepted',
                        disabled: true,
                        href: '',
                    }
                ]
                this.bread_ar = [
                    {
                        text: 'مناسبات',
                        disabled: false,
                        href: '/occasions/list',
                    },
                    {
                        text: 'الحجوزات',
                        disabled: true,
                        href: '',
                    },
                    {
                        text: 'المقبولة',
                        disabled: true,
                        href: '',
                    }
                ]
            }
        }
    },
    methods: {
        edit() {
            this.displayNoteDialog = false
            this.noteDialog = true
            this.edit_toggle = true
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en') {
                this.direction = false
            }
            else {
                this.direction = true
            }
        },
        addNotes(item) {
            if (this.havePermission) {
                this.noteDialog = true
                this.note_item = item
                this.edit_toggle = false
            }
            else {
                this.permissionDialog = true
            }
        },
        displayNotes(item) {
            this.displayNoteDialog = true
            this.display_note_item = item
        },
        submit() {
            if (this.note != '') {
                this.isSubmit = true
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
                const params = new URLSearchParams()
                params.append('lang', "en")
                params.append('booking_notes', this.note)
                let id
                if (this.edit_toggle)
                    id = this.display_note_item.id
                else
                    id = this.note_item.id
                this.axios.put(this.$store.state.url + "/api/v1/admin/bookings/" + this.my_url + "/" + id + "/update", params, config).then((res) => {
                    console.log(res.data)
                    this.response = true
                    this.noteDialog = false
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.isSubmit = false
                        this.response = false
                        this.note = ''
                        this.load = false
                        this.getData()
                    }
                }).catch(error => {
                    this.error_snackbar = true
                    this.isSubmit = false
                    this.response = false
                    this.note = ''
                })
            }
        },
        getData() {
            this.initPermission()
            if (this.section_id == 1)
                this.my_url = "resturant"
            else if (this.section_id == 2)
                this.my_url = "service"
            else
                this.my_url = "occasion"
            this.axios.get(this.$store.state.url + "/api/v1/admin/bookings/" + this.id + "/get?lang=" + this.lang + "&service_type=" + this.my_url + "&status=confirmed")
                .then(res => {
                    this.load = true
                    if (res.data.status == "Success") {
                        this.data = res.data.list.reverse()
                        this.data.forEach(item => item.status = Number(item.status))
                        // this.main_name = res.data.list[0].restaurant_details.name
                    }
                    console.log(res.data.list)
                });
        },
        displayItem(item) {
            this.display = item
            this.displayDialog = true
        },
        cancel_list(){
            this.$router.replace({ name: 'cancel-list', params: { section_id: this.section_id, id: this.id } })
        },
        request_list(){
            this.$router.replace({ name: 'booking-list', params: { section_id: this.section_id, id: this.id } })
        },
        initPermission() {
            this.my_permission = JSON.parse(sessionStorage.getItem("permission"))
            if (this.section_id == 1) {
                this.havePermission = this.my_permission.some(item => item.name == 'resturants_bookings_manager')
            }
            else if (this.section_id == 2) {
                this.havePermission = this.my_permission.some(item => item.name == 'services_bookings_manager')
            }
            else if (this.section_id == 3) {
                this.havePermission = this.my_permission.some(item => item.name == 'occasions_bookings_manager')
            }
        }
    },
    mounted() {
        this.initDir()
        this.id = this.$route.params.id
        this.section_id = this.$route.params.section_id
        this.getData()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.booking {
    border: 1px solid var(--v-secondary-base);
    border-radius: 30px;
    padding: 5px 10px;
    margin: 5px 0px;
}


.accept {
    color: green;
    font-size: 25px;
    border-radius: 8px;
    border: 1px solid green;
    margin-right: 10px;
    transition: all .3s;
}

.accept:hover {
    color: #fff;
    background-color: green;
    transition: all .3s;
    cursor: pointer;
}

.cancel {
    color: #dc3545;
    font-size: 25px;
    border-radius: 8px;
    border: 1px solid #dc3545;
    transition: all .3s;
}

.cancel:hover {
    color: #fff;
    background-color: #dc3545;
    transition: all .3s;
    cursor: pointer;
}
</style>
