<template>
    <div id="add-tour" class="add-tour add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en' && this.route == 'add-tour'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar' && this.route == 'add-tour'" />
        <breadcrumbs :breadcrumbs="bread_edit_en" v-if="lang == 'en' && this.route == 'edit-tour'" />
        <breadcrumbs :breadcrumbs="bread_edit_ar" v-if="lang == 'ar' && this.route == 'edit-tour'" />
        <div class="card">
            <div class="card-header" v-if="this.route == 'add-tour'">
                {{ $t('tour.add') }}
                <router-link to="/tour/list">
                    <v-btn color="primary" outlined>
                        {{ $t('tour.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-header" v-else-if="this.route == 'edit-tour'">
                {{ $t('tour.edit') }}
                <router-link to="/tour/list">
                    <v-btn color="primary" outlined>
                        {{ $t('tour.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="(this.route == 'add-tour' && !load) || (this.route == 'edit-tour' && load)">
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="fname"
                                :error-messages="fnameErrors" :label="$t('tour.fname')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="lname"
                                :error-messages="lnameErrors" :label="$t('tour.lname')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-select outlined v-model="city_id" :reverse="direction" :items="city_list" item-text="name"
                                :error-messages="cityErrors" item-value="id" :label="$t('resturant.city')"></v-select>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="address"
                                :error-messages="addressErrors" :label="$t('tour.address')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-select v-if="lang == 'en'" outlined v-model="type" :reverse="direction" :items="type_list_en"
                                item-text="name" item-value="id" :label="$t('tour.type')" multiple
                                :error-messages="typeErrors"></v-select>
                            <v-select v-if="lang == 'ar'" outlined v-model="type" :reverse="direction" :items="type_list_ar"
                                item-text="name" item-value="id" :label="$t('tour.type')" multiple
                                :error-messages="typeErrors"></v-select>
                        </div>
                        <div class="col-lg-6">
                            <v-select outlined v-model="language" :reverse="direction" :items="language_list"
                                item-text="name" item-value="id" :label="$t('tour.language')" multiple
                                :error-messages="languageErrors"></v-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="phone"
                                :error-messages="phoneErrors" :label="$t('tour.phone')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="email"
                                :error-messages="emailErrors" :label="$t('tour.email')"></v-text-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <v-file-input chips truncate-length="15" append-icon="mdi-camera" prepend-icon=""
                                :error-messages="main_imageErrors" :label="$t('tour.main_image')" :reverse="direction"
                                v-model="main_image" outlined accept="image/png, image/jpeg, image/bmp"
                                @change="changeImage"></v-file-input>

                            <v-img :src="display_image" max-height="200" contain style="margin-bottom: 30px"
                                v-if="display_image != ''">
                            </v-img>
                        </div>
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="description"
                                :error-messages="descriptionErrors" :label="$t('tour.description')"></v-textarea>
                        </div>
                    </div>

                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 30px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>

                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"
import { validationMixin } from 'vuelidate'
import { required, numeric, email, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'

export default {
    name: 'Addtour',
    components: {
        Breadcrumbs,
    },
    mixins: [validationMixin],

    data: () => ({
        damas_lat: 33.5138,
        damas_lng: 36.2765,
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_add_en: [
            {
                text: 'Tour Guide & Translator',
                disabled: true,
                href: '',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/tour/add',
            },
        ],
        bread_add_ar: [
            {
                text: 'دليل سياحي وترجمة',
                disabled: true,
                href: '',
            },
            {
                text: 'إضافة',
                disabled: false,
                href: '/tour/add',
            },
        ],
        bread_edit_en: [
            {
                text: 'Tour Guide & Translator',
                disabled: true,
                href: '',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        bread_edit_ar: [
            {
                text: 'دليل سياحي وترجمة',
                disabled: true,
                href: '',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            },
        ],
        direction: false,
        lang: '',
        route: '',
        user_id: '',
        fname: '',
        lname: '',
        description: '',
        status: 1,
        main_image: null,
        language: [],
        language_list: [],
        id: null,
        edit: false,
        load: false,
        address: '',
        phone: '',
        email: '',
        type_list_en: [
            {
                name: "Tour Guide",
                id: 1
            },
            {
                name: "Translator",
                id: 2
            },
        ],
        type_list_ar: [
            {
                name: "دليل سياحي",
                id: 1
            },
            {
                name: "مترجم",
                id: 2
            },
        ],
        type: [],
        city_list: [],
        city_id: '',
        display_image: ''
    }),
    validations: {
        fname: { required },
        lname: { required },
        type: { required },
        language: { required },
        address: { required },
        main_image: {
            required: requiredIf(function (item) {
                return !item.edit
            })
        },
        description: { required },
        phone: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
        email: { required, email },
        city_id: { required },
    },

    computed: {
        fnameErrors() {
            const errors = []
            if (!this.$v.fname.$dirty) return errors
            !this.$v.fname.required && errors.push('This field is required')
            return errors
        },
        lnameErrors() {
            const errors = []
            if (!this.$v.lname.$dirty) return errors
            !this.$v.lname.required && errors.push('This field is required')
            return errors
        },
        typeErrors() {
            const errors = []
            if (!this.$v.type.$dirty) return errors
            !this.$v.type.required && errors.push('This field is required')
            return errors
        },
        languageErrors() {
            const errors = []
            if (!this.$v.language.$dirty) return errors
            !this.$v.language.required && errors.push('This field is required')
            return errors
        },
        descriptionErrors() {
            const errors = []
            if (!this.$v.description.$dirty) return errors
            !this.$v.description.required && errors.push('This field is required')
            return errors
        },
        main_imageErrors() {
            const errors = []
            if (!this.$v.main_image.$dirty) return errors
            !this.$v.main_image.required && errors.push('This field is required')
            return errors
        },
        addressErrors() {
            const errors = []
            if (!this.$v.address.$dirty) return errors
            !this.$v.address.required && errors.push('This field is required')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.required && errors.push('This feild is required')
            !this.$v.phone.numeric && errors.push('Please enter correct phone number')
            !this.$v.phone.minLength && errors.push('Please enter correct phone number')
            !this.$v.phone.maxLength && errors.push('Please enter correct phone number')
            return errors
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.required && errors.push('This feild is required')
            !this.$v.email.email && errors.push('Please enter correct email')
            return errors
        },
        cityErrors() {
            const errors = []
            if (!this.$v.city_id.$dirty) return errors
            !this.$v.city_id.required && errors.push('This field is required')
            return errors
        },
    },

    methods: {
        changeImage(event) {
            if (event != null)
                this.display_image = URL.createObjectURL(event);
            else {
                if (this.edit)
                    this.display_image = this.data.media.url
                else
                    this.display_image = ''
            }
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append("lang", "en")
            formData.append("fname", this.fname)
            formData.append("lname", this.lname)
            formData.append("description", this.description)
            formData.append("address", this.address)
            formData.append("phone", this.phone)
            formData.append("email", this.email)
            if (this.main_image != null)
                formData.append("media", this.main_image)
            // console.log(this.city_id)
            formData.append("city_id", this.city_id)
            formData.append("status", 1)

            this.type.forEach((item, index) => {
                if (item == 1)
                    formData.append(`type[${index}]`, "tourist guide")
                else if (item == 2)
                    formData.append(`type[${index}]`, "translator")
            })
            this.language.forEach((item, index) => {
                formData.append(`langsId[${index}]`, item)
            })
            if (this.edit == true) {
                this.editData(formData)
            }
            else {
                this.addData(formData)
            }
        },
        addData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/tourGuide", formData)
                .then(res => {
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'tour-list' })
                    }
                    console.log(res.data)
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                })
        },
        getLanguage() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/languages?lang=" + this.lang)
                .then(res => {
                    this.language_list = res.data.list
                    // console.log(this.language_list)
                });
        },
        initEdit() {
            this.route = this.$route.name
            if (this.$route.name == "edit-tour") {
                this.id = this.$route.params.id
                this.edit = true
                this.axios.get(this.$store.state.url + "/api/v1/admin/tourGuide/" + this.id + "?lang=ar")
                    .then(res => {
                        this.load = true
                        console.log(res.data.list)
                        this.data = res.data.list
                        this.fname = this.data.fname
                        this.lname = this.data.lname
                        this.description = this.data.description
                        this.address = this.data.address
                        this.phone = this.data.phone
                        this.email = this.data.email
                        this.city_id = this.data.city.id
                        this.display_image = this.data.media.url
                        this.data.language.forEach(item => {
                            this.language.push(item.id)
                        })
                        this.data.type.forEach(item => {
                            if (item == "translator")
                                this.type.push(2)
                            else
                                this.type.push(1)
                        })
                    })
            }
        },
        editData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/tourGuide/" + this.id, formData)
                .then(res => {
                    this.response = true
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.$router.replace({ name: 'tour-list' })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false

                })
        },
        getCity() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/cities?lang=" + this.lang)
                .then(res => {
                    this.city_list = res.data.list
                });
        },
    },
    mounted() {
        this.initDir()
        this.getLanguage()
        this.initEdit()
        this.getCity()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.vue-map {
    border-radius: 20px;
}
</style>
