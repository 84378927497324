<template>
    <div id="add-category" class="add-category add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en' && this.route == 'add-category'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar' && this.route == 'add-category'" />
        <breadcrumbs :breadcrumbs="bread_edit_en" v-if="lang == 'en' && this.route == 'edit-category'" />
        <breadcrumbs :breadcrumbs="bread_edit_ar" v-if="lang == 'ar' && this.route == 'edit-category'" />
        <div class="card">
            <div class="card-header" v-if="this.route == 'add-category'">
                {{ $t('category.add') }}
                <router-link to="/category/list">
                    <v-btn color="primary" outlined>
                        {{ $t('category.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-header" v-else-if="this.route == 'edit-category'">
                {{ $t('category.edit') }}
                <router-link to="/category/list">
                    <v-btn color="primary" outlined>
                        {{ $t('category.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="(this.route == 'add-category' && !load) || (this.route == 'edit-category' && load)">
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_en"
                                :error-messages="nameEnErrors" :label="$t('category.name_en')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_ar"
                                :error-messages="nameArErrors" :label="$t('category.name_ar')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <!-- <v-file-input append-icon="mdi-camera" prepend-icon="" :error-messages="iconErrors"
                                accept="image/png, image/jpeg, image/bmp" :label="$t('category.icon')" :reverse="direction"
                                v-model="icon" outlined @change="addImage">
                                <template v-slot:selection="{ file }">
                                    <v-col cols="6" class="col-image">
                                        <v-img :src="file" height="100">
                                        </v-img>
                                    </v-col>
                                </template>
                            </v-file-input> -->
                            <v-file-input append-icon="mdi-camera" prepend-icon="" :label="$t('category.icon')"
                                accept="image/png, image/jpeg, image/bmp" :reverse="direction" v-model="icon" outlined
                                :error-messages="iconErrors" @change="changeIcon"></v-file-input>
                            <v-img :src="display_icon" max-height="200" contain style="margin-bottom: 30px"
                                v-if="display_icon != ''">
                            </v-img>
                        </div>
                        <div class="col-lg-6">
                            <v-file-input append-icon="mdi-camera" prepend-icon="" :label="$t('category.background')"
                                :reverse="direction" v-model="background" outlined accept="image/png, image/jpeg, image/bmp"
                                :error-messages="backgroundErrors" @change="changeBackground"></v-file-input>
                            <v-img :src="display_background" max-height="200" style="margin-bottom: 30px"
                                v-if="display_background != ''">
                            </v-img>
                        </div>
                    </div>
                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 15px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, requiredIf } from 'vuelidate/lib/validators'
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'AddCategory',
    components: {
        Breadcrumbs,
    },
    mixins: [validationMixin],

    data: () => ({
        name_en: '',
        name_ar: '',
        icon: null,
        background: null,
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_add_en: [
            {
                text: 'Service Category',
                disabled: true,
                href: '',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/category/add',
            },
        ],
        bread_add_ar: [
            {
                text: 'تصنيف الخدمة',
                disabled: true,
                href: '',
            },
            {
                text: 'إضافة',
                disabled: false,
                href: '/category/add',
            },
        ],
        bread_edit_en: [
            {
                text: 'Service Category',
                disabled: true,
                href: '',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        bread_edit_ar: [
            {
                text: 'تصنيف الخدمة',
                disabled: true,
                href: '',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            },
        ],
        data: null,
        direction: false,
        lang: '',
        edit: false,
        route: '',
        id: '',
        load: false,
        display_icon: '',
        display_background: '',
    }),

    validations: {
        name_en: { required, minLength: minLength(3) },
        name_ar: { required, minLength: minLength(3) },
        icon: {
            required: requiredIf(function (item) {
                return !item.edit
            })
        },
        background: {
            required: requiredIf(function (item) {
                return !item.edit
            })
        },
    },

    computed: {
        nameEnErrors() {
            const errors = []
            if (!this.$v.name_en.$dirty) return errors
            !this.$v.name_en.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_en.required && errors.push('Name is required')
            return errors
        },
        nameArErrors() {
            const errors = []
            if (!this.$v.name_ar.$dirty) return errors
            !this.$v.name_ar.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_ar.required && errors.push('Name is required')
            return errors
        },
        iconErrors() {
            const errors = []
            if (!this.$v.icon.$dirty) return errors
            !this.$v.icon.required && errors.push('This field is required')
            return errors
        },
        backgroundErrors() {
            const errors = []
            if (!this.$v.background.$dirty) return errors
            !this.$v.background.required && errors.push('This field is required')
            return errors
        }
    },

    methods: {
        changeIcon(event) {
            if (event != null)
                this.display_icon = URL.createObjectURL(event);
            else {
                if (this.edit)
                    this.display_icon = this.data.icon
                else
                    this.display_icon = ''
            }
        },
        changeBackground(event) {
            if (event != null)
                this.display_background = URL.createObjectURL(event);
            else {
                if (this.edit)
                    this.display_background = this.data.thumbnail
                else
                    this.display_background = ''
            }
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            // console.log(this.$v.$error)
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('name_en', this.name_en)
            formData.append('name_ar', this.name_ar)
            formData.append('lang', "en")
            if (this.icon != null)
                formData.append('icon', this.icon)
            if (this.background != null)
                formData.append('thumbnail', this.background)
            formData.append('is_active', 1)
            if (this.edit == true) {
                this.editData(formData)
            }
            else {
                this.addData(formData)
            }
        },
        addData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/services-categories", formData)
                .then(res => {
                    console.log(res.data)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'category-list' })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        editData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/services-categories/" + this.id, formData)
                .then(res => {
                    console.log(res)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'category-list' })
                    }
                }).catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        initEdit() {
            this.route = this.$route.name
            if (this.$route.name == "edit-category") {
                this.id = this.$route.params.id
                this.edit = true
                this.axios.get(this.$store.state.url + "/api/v1/admin/services-categories/" + this.id)
                    .then(res => {
                        this.load = true
                        this.data = res.data.list

                        this.name_ar = res.data.list.name_ar
                        this.name_en = res.data.list.name_en
                        this.display_icon = res.data.list.icon
                        this.display_background = res.data.list.thumbnail

                        console.log(res.data.list)

                    })
            }
        },

    },
    mounted() {
        this.initDir()
        this.initEdit()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
