import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapsed: false,
    url: "https://tourism.peaklink.site",
    token: null,
    permission:[],
    user: null,
  },
  getters: {
  },
  mutations: {

  },
  actions: {

  },
  modules: {
  }
})
