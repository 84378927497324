<template>
    <div id="add-attributes" class="add-attributes add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en' && this.route == 'add-attributes'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar' && this.route == 'add-attributes'" />
        <breadcrumbs :breadcrumbs="bread_edit_en" v-if="lang == 'en' && this.route == 'edit-attributes'" />
        <breadcrumbs :breadcrumbs="bread_edit_ar" v-if="lang == 'ar' && this.route == 'edit-attributes'" />
        <div class="card">
            <div class="card-header" v-if="this.route == 'add-attributes'">
                {{ $t('attributes.add') }}
                <router-link to="/attributes/list">
                    <v-btn color="primary" outlined>
                        {{ $t('attributes.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-header" v-else-if="this.route == 'edit-attributes'">
                {{ $t('attributes.edit') }}
                <router-link to="/attributes/list">
                    <v-btn color="primary" outlined>
                        {{ $t('attributes.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="(this.route == 'add-attributes' && !load) || (this.route == 'edit-attributes' && load)">
                    <div class="row">
                        <div class="col-lg-10">
                            <v-select outlined v-model="section" :reverse="direction" :items="section_en"
                                :disabled="sectionDisable" v-if="lang == 'en'" item-text="title" item-value="id"
                                :label="$t('attributes.section')" :error-messages="sectionErrors"></v-select>
                            <v-select outlined v-model="section" :reverse="direction" :items="section_ar"
                                :disabled="sectionDisable" v-if="lang == 'ar'" item-text="title" item-value="id"
                                :label="$t('attributes.section')" :error-messages="sectionErrors"></v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5">
                            <v-text-field outlined :reverse="direction" required v-model="name_en"
                                v-if="route == 'edit-attributes'" :error-messages="nameEnErrors" :disabled="nameDisable"
                                :label="$t('attributes.name_en')"></v-text-field>
                            <v-text-field outlined :reverse="direction" required v-model="name_en"
                                v-if="route == 'add-attributes'" :error-messages="nameEnErrors"
                                :label="$t('attributes.name_en')"></v-text-field>
                        </div>
                        <div class="col-lg-5">
                            <v-text-field outlined :reverse="direction" required v-model="name_ar"
                                v-if="route == 'edit-attributes'" :error-messages="nameArErrors" :disabled="nameDisable"
                                :label="$t('attributes.name_ar')"></v-text-field>
                            <v-text-field outlined :reverse="direction" required v-model="name_ar"
                                v-if="route == 'add-attributes'" :error-messages="nameArErrors"
                                :label="$t('attributes.name_ar')"></v-text-field>
                        </div>
                    </div>
                    <div class="row" style="align-items: center;" v-for="(v, index) in $v.values.$each.$iter" :key="index">
                        <div class="col-lg-10">
                            <div class="row">
                                <div class="col-lg-6">
                                    <v-text-field outlined :reverse="direction" required v-model="v.en.$model"
                                        v-if="route == 'edit-attributes'" :error-messages="enErrors(index)"
                                        :disabled="valueDisable(index)" :label="$t('attributes.value_en')"></v-text-field>
                                    <v-text-field outlined :reverse="direction" required v-model="v.en.$model"
                                        v-if="route == 'add-attributes'" :error-messages="enErrors(index)"
                                        :label="$t('attributes.value_en')"></v-text-field>
                                </div>
                                <div class="col-lg-6">
                                    <v-text-field outlined :reverse="direction" required v-model="v.ar.$model"
                                        v-if="route == 'edit-attributes'" :error-messages="arErrors(index)"
                                        :disabled="valueDisable(index)" :label="$t('attributes.value_ar')"></v-text-field>
                                    <v-text-field outlined :reverse="direction" required v-model="v.ar.$model"
                                        v-if="route == 'add-attributes'" :error-messages="arErrors(index)"
                                        :label="$t('attributes.value_ar')"></v-text-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1" style="margin-bottom: 30px" v-if="route == 'edit-attributes'">
                            <v-btn color="blue" text class="plus-button" @click="subtract(index)"
                                v-if="!valueDisable(index)">
                                -
                            </v-btn>
                        </div>
                        <div class="col-lg-1" style="margin-bottom: 30px" v-if="route == 'add-attributes'">
                            <v-btn color="blue" text class="plus-button" @click="subtract(index)">
                                -
                            </v-btn>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-10" style="margin-bottom: 30px;display: flex; justify-content: center">
                            <v-btn color="blue" text class="plus-button" @click="add()">
                                +
                            </v-btn>
                        </div>
                    </div>
                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 15px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import Breadcrumbs from "@/components/Breadcrumbs.vue"
export default {
    name: 'AddAttributes',
    components: {
        Breadcrumbs
    },
    mixins: [validationMixin],

    data: () => ({
        name_en: '',
        name_ar: '',
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        values: [{
            en: '',
            ar: '',
            id: 0
        }],
        bread_add_en: [
            {
                text: 'Attributes',
                disabled: true,
                href: '',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/attributes/add',
            },
        ],
        bread_add_ar: [
            {
                text: 'المواصفات',
                disabled: true,
                href: '',
            },
            {
                text: 'إضافة',
                disabled: false,
                href: '/attributes/add',
            },
        ],
        bread_edit_en: [
            {
                text: 'Attributes',
                disabled: true,
                href: '',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        bread_edit_ar: [
            {
                text: 'المواصفات',
                disabled: true,
                href: '',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            },
        ],
        direction: false,
        lang: '',
        edit: false,
        route: '',
        id: '',
        load: false,
        count_name: '',
        count_value: [],
        section_en: [
            {
                title: "Rstaurants",
                id: 1
            },
            {
                title: "Public Service",
                id: 2
            }
        ],
        section_ar: [
            {
                title: "مطاعم",
                id: 1
            },
            {
                title: "خدمات عامة",
                id: 2
            }
        ],
        section: null
    }),

    validations: {
        name_en: { required, minLength: minLength(3) },
        name_ar: { required, minLength: minLength(3) },
        values: {
            $each: {
                ar: { required },
                en: { required },
            }
        },
        section: { required }
    },
    computed: {
        nameEnErrors() {
            const errors = []
            if (!this.$v.name_en.$dirty) return errors
            !this.$v.name_en.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_en.required && errors.push('Name is required')
            return errors
        },
        nameArErrors() {
            const errors = []
            if (!this.$v.name_ar.$dirty) return errors
            !this.$v.name_ar.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_ar.required && errors.push('Name is required')
            return errors
        },
        sectionErrors() {
            const errors = []
            if (!this.$v.section.$dirty) return errors
            !this.$v.section.required && errors.push('Section is required')
            return errors
        },
        nameDisable() {
            return this.count_name
        },
        sectionDisable() {
            return this.edit
        }
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                if (this.edit == true) {
                    this.editData()
                }
                else {
                    this.addData()
                }
            }
        },
        addData() {
            const formData = new FormData()
            formData.append('name_en', this.name_en)
            formData.append('name_ar', this.name_ar)
            if (this.section == 1)
                formData.append('service', 'restaurant')
            else
                formData.append('service', 'service')

            let delete_ids = []
            this.count_value.forEach((item, index) => {
                if (item.count > 0)
                    delete_ids.push(item.id)
            })
            // console.log(delete_ids)
            const send_value = this.values.filter(value => !delete_ids.includes(value.id));
            // console.log(send_value)

            send_value.forEach((item, index) => {
                formData.append(`terms[${index}][name_en]`, item.en)
                formData.append(`terms[${index}][name_ar]`, item.ar)
            })
            // for (let [key, value] of formData.entries()) {
            //     console.log(key + ': ' + value);
            // }
            this.axios.post(this.$store.state.url + "/api/v1/admin/attributes", formData)
                .then(res => {
                    console.log(res.data)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'attributes-list' })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        editData() {
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const params = new URLSearchParams()
            params.append('name_en', this.name_en)
            params.append('name_ar', this.name_ar)
            // if (this.section == 1)
            //     params.append('service', 'restaurant')
            // else
            //     params.append('service', 'service')
            let delete_ids = []
            this.count_value.forEach((item, index) => {
                if (item.count > 0)
                    delete_ids.push(item.id)
            })
            // console.log(delete_ids)
            const send_value = this.values.filter(value => !delete_ids.includes(value.id));
            // console.log(send_value)

            send_value.forEach((item, index) => {
                params.append(`terms[${index}][name_en]`, item.en)
                params.append(`terms[${index}][name_ar]`, item.ar)
            })
            this.axios.put(this.$store.state.url + "/api/v1/admin/attributes/" + this.id, params, config)
                .then(res => {
                    console.log(res)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'attributes-list' })
                    }
                }) .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        initEdit() {
            this.route = this.$route.name
            if (this.$route.name == "edit-attributes") {
                this.id = this.$route.params.id
                this.edit = true
                this.axios.get(this.$store.state.url + "/api/v1/admin/attributes/" + this.id)
                    .then(res => {
                        this.load = true
                        const data = res.data.list
                        console.log(data)
                        this.name_en = data.name_en
                        this.name_ar = data.name_ar
                        if (data.service == 'restaurant')
                            this.section = 1
                        else
                            this.section = 2
                        this.values.pop()
                        data.core_terms.forEach((item) => {
                            this.values.push({
                                en: item.name_en,
                                ar: item.name_ar,
                                id: item.id
                            })
                            this.count_value.push({
                                id: item.id,
                                count: item.linked_to_model
                            })
                        })
                        this.count_name = this.checkName(data.core_terms)
                        console.log(this.count_name)
                    })
            }
        },
        checkName(array) {
            return array.some((item) => item.linked_to_model == 1)
        },
        valueDisable(index) {
            if (this.count_value[index] != null)
                return !this.count_value[index].count == 0
            else
                return false
        },
        add() {
            this.values.push({
                ar: '',
                en: '',
                id: 0
            });
        },
        subtract(index) {
            if (this.values.length > 1)
                this.values.splice(index, 1);
        },
        enErrors(index) {
            const errors = []
            if (!this.$v.values.$each[index].en.$dirty) return errors
            !this.$v.values.$each[index].en.required && errors.push('This field required')
            return errors
        },
        arErrors(index) {
            const errors = []
            if (!this.$v.values.$each[index].ar.$dirty) return errors
            !this.$v.values.$each[index].ar.required && errors.push('This field required')
            return errors
        },
    },
    mounted() {
        this.initDir()
        this.initEdit()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
