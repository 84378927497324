<template>
    <div id="add-service" class="add-service add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('service.edit') }}
                <router-link to="/service/list">
                    <v-btn color="primary" outlined>
                        {{ $t('service.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="load">
                    {{ $t('select_lang') }}
                    <v-btn-toggle v-model="language" tile color="accent" group style="margin-bottom: 30px">
                        <v-btn value="en">
                            English
                        </v-btn>

                        <v-btn value="ar">
                            العربية
                        </v-btn>
                    </v-btn-toggle>
                    <h5>{{ $t('service.main_details') }}</h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name" :error-messages="nameErrors"
                                :label="$t('service.name')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <p>{{ $t('service.rate') }}</p>
                            <v-rating v-model="rating" style="top: -10px; position: relative;" color="amber" hover
                                length="5" size="25" background-color="grey"></v-rating>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="price"
                                :error-messages="priceErrors" :label="$t('service.price')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-select outlined v-model="category_id" :reverse="direction" :items="categories"
                                :error-messages="categoryErrors" item-text="name" item-value="id"
                                :label="$t('service.cat')"></v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="description"
                                :error-messages="descriptionErrors" :label="$t('service.description')"></v-textarea>
                        </div>
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="policy"
                                :error-messages="policyErrors" :label="$t('service.policy')"></v-textarea>
                        </div>
                    </div>
                    <h5>{{ $t('service.images2') }}</h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-file-input chips truncate-length="15" append-icon="mdi-camera" prepend-icon=""
                                accept="image/png, image/jpeg, image/bmp" v-model="main_image" outlined
                                :label="$t('service.main_image')" :reverse="direction"
                                @change="changeMainImage"></v-file-input>
                            <v-img :src="display_main_image" max-height="200"
                                style="margin-bottom: 30px;border-radius: 15px" v-if="display_main_image != ''">
                            </v-img>
                        </div>
                        <div class="col-lg-6">
                            <v-file-input chips multiple counter append-icon="mdi-camera" prepend-icon=""
                                :label="$t('service.images')" :reverse="direction" v-model="images"
                                accept="image/png, image/jpeg, image/bmp" outlined @change="changeImages"></v-file-input>
                            <v-carousel cycle height="200" show-arrows-on-hover style="border-radius: 15px"
                                v-if="display_images.length != 0">
                                <v-carousel-item v-for="(item, index) in display_images" :key="index">
                                    <v-img :src="item.url" aspect-ratio="1.7" max-height="200" style="border-radius: 15px">
                                        <v-icon class="close-icon" small v-if="item.id != -1"
                                            @click="deleteImage(item, index)">mdi-close</v-icon>
                                    </v-img>
                                </v-carousel-item>
                            </v-carousel>
                        </div>
                    </div>
                    <h5>{{ $t('service.attribute') }}</h5>
                    <v-divider></v-divider>
                    <div class="row" style="align-items: center;">
                        <div class="col-lg-10" v-for="(v, index) in $v.attribute.$each.$iter" :key="index">
                            <div class="row">
                                <div class="col-lg-6">
                                    <v-select outlined v-model="v.id.$model" :reverse="direction" :items="attribute_list"
                                        :error-messages="idErrors(index)" item-text="name" item-value="id"
                                        :label="$t('service.attribute_name')"></v-select>
                                </div>
                                <div class="col-lg-6">
                                    <v-select outlined v-model="v.value_id.$model" :reverse="direction" multiple
                                        :items="att_valuesFiltered(index)" :error-messages="idErrors(index)"
                                        item-text="name" item-value="id" :label="$t('service.attribute_value')"></v-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-1" style="margin-bottom: 30px">
                            <v-btn color="blue" text class="plus-button" @click="add()">
                                +
                            </v-btn>
                        </div>
                        <div class="col-lg-1" style="margin-bottom: 30px">
                            <v-btn color="blue" text class="plus-button" @click="subtract()">
                                -
                            </v-btn>
                        </div>
                    </div>
                    <h5>{{ $t('service.feature') }}</h5>
                    <v-divider></v-divider>
                    <div class="row" style="align-items: center;">
                        <div class="col-lg-10" v-for="(v, index) in $v.feature.$each.$iter" :key="index">
                            <div class="row">
                                <div class="col-lg-6">
                                    <v-text-field outlined v-model="v.title.$model" :reverse="direction"
                                        :error-messages="titleErrors(index)"
                                        :label="$t('service.feature_title')"></v-text-field>
                                </div>
                                <div class="col-lg-6">
                                    <v-text-field outlined v-model="v.content.$model" :reverse="direction"
                                        :error-messages="contentErrors(index)"
                                        :label="$t('service.feature_content')"></v-text-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-1" style="margin-bottom: 30px">
                            <v-btn color="blue" text class="plus-button" @click="add_feature()">
                                +
                            </v-btn>
                        </div>
                        <div class="col-lg-1" style="margin-bottom: 30px">
                            <v-btn color="blue" text class="plus-button" @click="subtract_feature()">
                                -
                            </v-btn>
                        </div>
                    </div>
                    <h5>{{ $t('service.connection') }} </h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="phone"
                                :error-messages="phoneErrors" :label="$t('service.phone')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="email"
                                :error-messages="emailErrors" :label="$t('service.email')"></v-text-field>
                        </div>
                    </div>
                    <h5>{{ $t('service.location_details') }} </h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-select outlined v-model="city_id" :reverse="direction" :items="city_list" item-text="name"
                                :error-messages="cityErrors" item-value="id" :label="$t('service.city')"></v-select>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="address"
                                :error-messages="addressErrors" :label="$t('service.address')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <GmapMap :center="{ lat: marker.lat, lng: marker.lng }" :zoom="12" map-type-id="terrain"
                                style="height: 200px" @click="addMarker">
                                <GmapMarker :position="marker" :clickable="true" />
                            </GmapMap>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="latitude" readonly
                                :error-messages="latitudeErrors" :label="$t('service.latitude')"></v-text-field>
                            <v-text-field outlined :reverse="direction" required v-model="longtitude" readonly
                                :error-messages="longtitudeErrors" :label="$t('service.longtitude')"></v-text-field>
                        </div>
                    </div>
                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 50px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"
import { validationMixin } from 'vuelidate'
import { required, numeric, email, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
    name: 'AddService',
    components: {
        Breadcrumbs,
    },
    mixins: [validationMixin],

    data: () => ({
        language: 'en',
        damas_lat: 33.5138,
        damas_lng: 36.2765,
        load: false,
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_add_en: [
            {
                text: 'Public Service',
                disabled: true,
                href: '',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '/service/add',
            },
        ],
        bread_add_ar: [
            {
                text: 'خدمات عامة',
                disabled: true,
                href: '',
            },

            {
                text: 'تعديل',
                disabled: true,
                href: '/service/add',
            },
        ],
        direction: false,
        lang: '',
        route: '',
        user_id: '',
        categories: [],
        name: '',
        description: '',
        price: '',
        latitude: null,
        longtitude: null,
        status: 1,
        tags_list: [],
        images: [],
        main_image: null,
        category_id: '',
        attribute: [],
        attribute_list: [],
        id: null,
        city_id: '',
        city_list: [],
        address: '',
        marker: {
            lat: null,
            lng: null
        },
        att_values: [],
        feature: [],
        policy: '',
        phone: '',
        email: '',
        rating: 1,

        display_main_image: '',
        display_images: [],
        orginal_images: []

    }),
    validations: {
        name: { required },
        price: { required, numeric },
        description: { required },
        category_id: { required },
        city_id: { required },
        address: { required },
        latitude: { required },
        longtitude: { required },
        attribute: {
            $each: {
                id: { required },
                value_id: { required },
            }
        },
        feature: {
            $each: {
                title: { required },
                content: { required },
            }
        },
        policy: { required },
        phone: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
        email: { required, email },
        language: { required }
    },

    computed: {
        langErrors() {
            const errors = []
            if (!this.$v.language.$dirty) return errors
            !this.$v.language.required && errors.push('This field is required')
            return errors
        },
        nameErrors() {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.required && errors.push('This field is required')
            return errors
        },
        priceErrors() {
            const errors = []
            if (!this.$v.price.$dirty) return errors
            !this.$v.price.required && errors.push('This field is required')
            !this.$v.price.numeric && errors.push('Please input only numbers ')
            return errors
        },
        descriptionErrors() {
            const errors = []
            if (!this.$v.description.$dirty) return errors
            !this.$v.description.required && errors.push('This field is required')
            return errors
        },
        categoryErrors() {
            const errors = []
            if (!this.$v.category_id.$dirty) return errors
            !this.$v.category_id.required && errors.push('This field is required')
            return errors
        },
        cityErrors() {
            const errors = []
            if (!this.$v.city_id.$dirty) return errors
            !this.$v.city_id.required && errors.push('This field is required')
            return errors
        },
        addressErrors() {
            const errors = []
            if (!this.$v.address.$dirty) return errors
            !this.$v.address.required && errors.push('This field is required')
            return errors
        },
        latitudeErrors() {
            const errors = []
            if (!this.$v.latitude.$dirty) return errors
            !this.$v.latitude.required && errors.push('This field is required')
            return errors
        },
        longtitudeErrors() {
            const errors = []
            if (!this.$v.longtitude.$dirty) return errors
            !this.$v.longtitude.required && errors.push('This field is required')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.required && errors.push('This feild is required')
            !this.$v.phone.numeric && errors.push('Please enter correct phone number')
            !this.$v.phone.minLength && errors.push('Please enter correct phone number')
            !this.$v.phone.maxLength && errors.push('Please enter correct phone number')
            return errors
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.required && errors.push('This feild is required')
            !this.$v.email.email && errors.push('Please enter correct email')
            return errors
        },
        policyErrors() {
            const errors = []
            if (!this.$v.policy.$dirty) return errors
            !this.$v.policy.required && errors.push('This field is required')
            return errors
        },

    },

    methods: {
        deleteImage(image, index) {
            console.log("delete image: " + image.id + " item : " + this.id)
            this.axios.delete(this.$store.state.url + "/api/v1/admin/services/" + this.id + "/media/" + image.id + "?lang=en")
                .then(res => {
                    console.log(res.data)
                    this.display_images.splice(index, 1)
                    this.data.media.splice(index, 1)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        changeImages(event) {
            console.log(event)
            this.display_images = [...this.data.media]
            if (event.length != 0) {
                event.forEach(item => {
                    this.display_images.push({
                        url: URL.createObjectURL(item),
                        id: -1
                    })
                })
            }
            else {
                this.display_images = [...this.data.media]
            }
        },
        changeMainImage(event) {
            if (event != null)
                this.display_main_image = URL.createObjectURL(event);
            else {
                this.display_main_image = this.data.mainImage.url
            }
        },
        addMarker(event) {
            this.marker = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            }
            this.latitude = this.marker.lat
            this.longtitude = this.marker.lng
            // console.log(this.marker)
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendImages() {
            console.log("images send ...")
            const formData = new FormData()
            this.images.forEach((item, index) => {
                formData.append(`media[${index}]`, item)
            })
            if (this.main_image != null)
                formData.append('main', this.main_image)
            formData.append('lang', "en")

            // for (var pair of formData.entries()) {
            //     console.log(pair)
            // }
            this.axios.post(this.$store.state.url + "/api/v1/admin/services/" + this.id + "/media", formData)
                .then(res => {
                    this.response = true
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'service-list' })
                    }
                })
                .catch(error => {
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                })
        },
        sendData() {
            const formData = new FormData()
            formData.append("lang", this.language)
            formData.append("name", this.name)
            formData.append("title", this.name)
            formData.append("content", this.description)
            formData.append("price", this.price)
            formData.append("location_id", this.city_id)
            formData.append("map_lat", this.latitude)
            formData.append("map_lng", this.longtitude)
            formData.append("map_zoom", 5)
            formData.append("address", this.address)
            formData.append("phone", this.phone)
            formData.append("email", this.email)
            formData.append("is_featured", 0)
            formData.append("policy", this.policy)
            formData.append("star_rate", this.rating)
            formData.append("status", 1)
            formData.append("category_id", this.category_id)
            this.feature.forEach((item, index) => {
                if (item.id != null) {
                    formData.append(`old_features[${index}][id]`, item.id)
                    formData.append(`old_features[${index}][title]`, item.title)
                    formData.append(`old_features[${index}][content]`, item.content)
                }
                else {
                    formData.append(`new_features[${index}][title]`, item.title)
                    formData.append(`new_features[${index}][content]`, item.content)
                }
            })

            let mergedArray = []
            this.attribute.forEach(item => {
                mergedArray = mergedArray.concat(item.value_id);
            });
            // console.log(mergedArray)

            mergedArray.forEach((item, index) => {
                formData.append(`termIds[${index}]`, item)
            })


            for (var pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }

            this.axios.post(this.$store.state.url + "/api/v1/admin/services/" + this.id, formData)
                .then(res => {
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        console.log("edit service: " + this.id)
                        this.sendImages()
                    }
                    console.log(res.data)
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                })
        },
        initImages() {
            this.images = this.images.map((item) => {
                return {
                    "name": item,
                    "alt": this.name
                }
            })
            // console.log(this.images)
        },
        getCategory() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/services-categories?lang=" + this.lang + "&is_active=1")
                .then(res => {
                    // console.log(res.data)
                    // if (res.data.error == false)
                    this.categories = res.data.list
                    // console.log(res)
                });
        },
        getCity() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/cities?lang=" + this.lang)
                .then(res => {
                    // console.log(res.data)
                    // if (res.data.error == false)
                    this.city_list = res.data.list
                    // console.log(this.city_list)
                });
        },
        getAttribute() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/attributes?lang=" + this.lang)
                .then(res => {
                    this.attribute_list = res.data.list
                    // console.log(this.attribute_list)
                    this.attribute_list = this.attribute_list.filter(item => item.service == "service")
                    // console.log(this.attribute_list)
                    let arr = []
                    this.attribute_list.forEach(item => {
                        arr.push(item.core_terms)
                    })
                    this.att_values = [].concat(...arr)
                    // console.log(this.attribute_list)
                });
        },
        add() {
            this.attribute.push({
                id: '',
                value_id: []
            });
        },
        subtract() {
            // if (this.attribute.length > 1)
            this.attribute.pop()
        },
        idErrors(index) {
            const errors = []
            if (!this.$v.attribute.$each[index].id.$dirty) return errors
            !this.$v.attribute.$each[index].id.required && errors.push('This field required')
            return errors
        },
        value_idErrors(index) {
            const errors = []
            if (!this.$v.attribute.$each[index].value_id.$dirty) return errors
            !this.$v.attribute.$each[index].value_id.required && errors.push('This field required')
            return errors
        },
        att_valuesFiltered(index) {
            return this.att_values.filter(item => item.core_attribute_id == this.attribute[index].id)
        },
        titleErrors(index) {
            const errors = []
            if (!this.$v.feature.$each[index].title.$dirty) return errors
            !this.$v.feature.$each[index].title.required && errors.push('This field required')
            return errors
        },
        contentErrors(index) {
            const errors = []
            if (!this.$v.feature.$each[index].content.$dirty) return errors
            !this.$v.feature.$each[index].content.required && errors.push('This field required')
            return errors
        },
        add_feature() {
            this.feature.push({
                id: null,
                title: '',
                content: ''
            });
        },
        subtract_feature() {
            this.feature.pop()
        },
        getData() {
            this.id = this.$route.params.id
            this.axios.get(this.$store.state.url + "/api/v1/admin/services/" + this.id + "?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    this.data = res.data.list
                    console.log(res.data.list)
                    this.marker.lat = parseFloat(this.data.map_lat)
                    this.marker.lng = parseFloat(this.data.map_lng)
                    this.name = this.data.name
                    this.description = this.data.content
                    this.city_id = this.data.location.id
                    this.latitude = this.data.map_lat
                    this.longtitude = this.data.map_lng
                    this.address = this.data.address
                    this.price = this.data.price
                    this.policy = this.data.policy
                    this.email = this.data.email
                    this.phone = this.data.phone
                    this.rating = parseInt(this.data.star_rate)
                    this.category_id = this.data.category.id



                    this.display_main_image = this.data.mainImage.url
                    this.display_images = [...this.data.media]



                    if (this.data.features.length != 0)
                        this.data.features.forEach(item => {
                            this.feature.push({
                                id: item.id,
                                title: item.title,
                                content: item.content
                            });
                        })


                    if (this.data.terms.length != 0) {
                        this.data.terms.forEach(item => {
                            item.core_terms.forEach(item2 => {
                                if (item2.status == true) {
                                    this.attribute.push({
                                        id: item.id,
                                        value_id: item2.id
                                    });
                                }
                            })
                        })
                    }
                    // console.log(this.attribute)

                    let mergedAttribute = [];
                    this.attribute.forEach(item => {
                        let existingItem = mergedAttribute.find(a => a.id === item.id);
                        if (existingItem) {
                            existingItem.value_id.push(item.value_id);
                        } else {
                            mergedAttribute.push({ id: item.id, value_id: [item.value_id] });
                        }
                    });
                    this.attribute = mergedAttribute

                    // console.log(mergedAttribute)
                })
        }
    },
    mounted() {
        this.initDir()
        this.getData()
        this.route = this.$route.name
        this.getCategory()
        this.getCity()
        this.getAttribute()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.vue-map {
    border-radius: 20px;
}

.close-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    color: rgba(0, 0, 0, 0.5);
    background-color: #fff;
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
    box-shadow: 0 0 10px #808080a9;
}

.col-image {
    max-width: 10% !important;
}
</style>
