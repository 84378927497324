<template>
    <div id="display-resturant" class="display-resturant display add">
        <breadcrumbs :breadcrumbs="bread_display_en" v-if="lang == 'en'" />
        <breadcrumbs :breadcrumbs="bread_display_ar" v-if="lang == 'ar'" />
        <div class="card">
            <div class="card-header">
                {{ $t('resturant.display') }}
                <router-link to="/resturant/list">
                    <v-btn color="primary" outlined>
                        {{ $t('resturant.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="load">
                    <h5>{{ $t('resturant.main_details') }}</h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name" readonly
                                :label="$t('resturant.name')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <p>{{ $t('resturant.rate') }}</p>
                            <v-rating style="top: -10px; position: relative;" :value="Number(data.star_rate)" color="amber"
                                length="5" background-color="grey" readonly size="25"  half-increments></v-rating>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="description" readonly
                                :label="$t('resturant.description')"></v-textarea>
                        </div>
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="policy" readonly
                                :label="$t('resturant.policy')"></v-textarea>
                        </div>
                    </div>

                    <h5>{{ $t('resturant.images2') }}</h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-img :src="data.media_urls[0]" aspect-ratio="1.7" style="border-radius: 15px"></v-img>
                        </div>
                        <div class="col-lg-6">
                            <v-carousel cycle height="300" hide-delimiter-background show-arrows-on-hover>
                                <v-carousel-item v-for="(item, index) in data.sub_media_urls" :key="index">
                                    <v-img :src="item.url" aspect-ratio="1.7" style="border-radius: 15px"></v-img>
                                </v-carousel-item>
                            </v-carousel>
                        </div>
                    </div>
                    <div v-if="data.terms.length != 0" style="margin-top:30px">
                        <h5>{{ $t('resturant.attribute') }}</h5>
                        <v-divider></v-divider>
                        <div v-for="(item, index) in attribute" :key="index">
                            <div class="row">
                                <div class="col-lg-6">
                                    <v-select outlined v-model="item.id" :reverse="direction" :items="attribute_list"
                                        readonly item-text="name" item-value="id"
                                        :label="$t('resturant.attribute_name')"></v-select>
                                </div>
                                <div class="col-lg-6">
                                    <v-select outlined v-model="item.value_id" :reverse="direction" multiple readonly
                                        :items="att_valuesFiltered(index)" item-text="name" item-value="id"
                                        :label="$t('resturant.attribute_value')"></v-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h5>{{ $t('resturant.connection') }} </h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="phone" readonly
                                :label="$t('resturant.phone')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="email" readonly
                                :label="$t('resturant.email')"></v-text-field>
                        </div>
                    </div>
                    <h5>{{ $t('resturant.open') }}</h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field class="my-time" outlined :reverse="direction" v-model="check_in_time"
                                :label="$t('resturant.check_in_time')" append-icon="mdi-clock-time-four-outline"
                                readonly></v-text-field>

                        </div>
                        <div class="col-lg-6">
                            <v-text-field class="my-time" outlined :reverse="direction" v-model="check_out_time"
                                :label="$t('resturant.check_out_time')" append-icon="mdi-clock-time-four-outline"
                                readonly></v-text-field>
                        </div>
                    </div>
                    <h5>{{ $t('resturant.location_details') }} </h5>
                    <v-divider></v-divider>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-select outlined v-model="city_id" readonly :reverse="direction" :items="city_list"
                                item-text="name" item-value="id" :label="$t('resturant.city')"></v-select>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="address" readonly
                                :label="$t('resturant.address')"></v-text-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <GmapMap :center="{ lat: marker.lat, lng: marker.lng }" :zoom="12" map-type-id="terrain"
                                style="height: 200px">
                                <GmapMarker :position="marker" />
                            </GmapMap>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="latitude" readonly
                                :label="$t('resturant.latitude')"></v-text-field>
                            <v-text-field outlined :reverse="direction" required v-model="longtitude" readonly
                                :label="$t('resturant.longtitude')"></v-text-field>
                        </div>
                    </div>

                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"

export default {
    name: 'DisplayResturant',
    components: {
        Breadcrumbs,
    },

    data: () => ({
        language: 'en',
        load: false,
        damas_lat: 33.5138,
        damas_lng: 36.2765,
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_display_en: [
            {
                text: 'Restuarant',
                disabled: true,
                href: '',
            },
            {
                text: 'Display',
                disabled: true,
                href: '',
            },
        ],
        bread_display_ar: [
            {
                text: 'مطاعم',
                disabled: true,
                href: '',
            },

            {
                text: 'عرض',
                disabled: true,
                href: '',
            },
        ],
        direction: false,
        lang: '',
        route: '',
        user_id: '',
        categories: [],
        name: '',
        description: '',
        price: '',
        latitude: null,
        longtitude: null,
        status: 1,
        tags_list: [],
        images: [],
        main_image: null,
        category_id: '',
        attribute: [],
        attribute_list: [],
        id: null,
        city_id: '',
        city_list: [],
        address: '',
        marker: {
            lat: null,
            lng: null
        },
        att_values: [],
        policy: '',
        phone: '',
        email: '',
        rating: 1,
        check_out_time_modal: false,
        check_out_time: '',

        check_in_time_modal: false,
        check_in_time: '',
    }),
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getCity() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/cities?lang=" + this.lang)
                .then(res => {
                    this.city_list = res.data.list
                });
        },
        getAttribute() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/attributes?lang=" + this.lang)
                .then(res => {
                    this.attribute_list = res.data.list
                    console.log(this.attribute_list)
                    this.attribute_list = this.attribute_list.filter(item => item.service == "restaurant")
                    console.log(this.attribute_list)
                    let arr = []
                    this.attribute_list.forEach(item => {
                        arr.push(item.core_terms)
                    })
                    this.att_values = [].concat(...arr)
                    // console.log(this.att_values)
                });
        },
        att_valuesFiltered(index) {
            return this.att_values.filter(item => item.core_attribute_id == this.attribute[index].id)
        },
        getData() {
            this.id = this.$route.params.id
            this.axios.get(this.$store.state.url + "/api/v1/admin/resturants/" + this.id + "?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    this.data = res.data.list
                    console.log(res.data.list)
                    this.marker.lat = parseFloat(this.data.map_lat)
                    this.marker.lng = parseFloat(this.data.map_lng)
                    this.name = this.data.name
                    this.description = this.data.content
                    this.city_id = this.data.location_id
                    this.latitude = this.data.map_lat
                    this.longtitude = this.data.map_lng
                    this.address = this.data.address
                    this.policy = this.data.policy
                    this.email = this.data.email
                    this.phone = this.data.phone
                    this.check_in_time = this.data.check_in_time
                    this.check_out_time = this.data.check_out_time
                    this.rating = parseInt(this.data.star_rate)
                    if (this.data.terms.length != 0) {
                        this.data.terms.forEach(item => {
                            item.core_terms.forEach(item2 => {
                                if (item2.status == true) {
                                    this.attribute.push({
                                        id: item.id,
                                        value_id: item2.id
                                    });
                                }
                            })
                        })
                    }
                    // console.log(this.attribute)

                    let mergedAttribute = [];
                    this.attribute.forEach(item => {
                        let existingItem = mergedAttribute.find(a => a.id === item.id);
                        if (existingItem) {
                            existingItem.value_id.push(item.value_id);
                        } else {
                            mergedAttribute.push({ id: item.id, value_id: [item.value_id] });
                        }
                    });
                    this.attribute = mergedAttribute

                    // console.log(mergedAttribute)

                })
        }
    },
    mounted() {
        this.initDir()
        this.route = this.$route.name
        this.getCity()
        this.getAttribute()
        this.getData()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.vue-map {
    border-radius: 20px;
}
</style>
