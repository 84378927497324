<template>
    <div id="add-occasion" class="add-occasion add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en' && this.route == 'add-occasions'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar' && this.route == 'add-occasions'" />
        <breadcrumbs :breadcrumbs="bread_edit_en" v-if="lang == 'en' && this.route == 'edit-occasions'" />
        <breadcrumbs :breadcrumbs="bread_edit_ar" v-if="lang == 'ar' && this.route == 'edit-occasions'" />
        <div class="card">
            <div class="card-header" v-if="this.route == 'add-occasions'">
                {{ $t('occasion.add') }}
                <router-link to="/occasions/list">
                    <v-btn color="primary" outlined>
                        {{ $t('occasion.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-header" v-else-if="this.route == 'edit-occasions'">
                {{ $t('occasion.edit') }}
                <router-link to="/occasions/list">
                    <v-btn color="primary" outlined>
                        {{ $t('occasion.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="(this.route == 'add-occasions' && !load) || (this.route == 'edit-occasions' && load)">
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_ar"
                                :error-messages="nameArErrors" :label="$t('occasion.name_ar')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_en"
                                :error-messages="nameEnErrors" :label="$t('occasion.name_en')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="phone"
                                :error-messages="phoneErrors" :label="$t('occasion.phone')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-select outlined v-model="city_id" :reverse="direction" :items="city_list" item-text="name"
                                :error-messages="cityErrors" item-value="id" :label="$t('occasion.city')"></v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="address_ar"
                                :error-messages="addressArErrors" :label="$t('occasion.address_ar')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="address_en"
                                :error-messages="addressEnErrors" :label="$t('occasion.address_en')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-select outlined v-model="service_id" :reverse="direction"
                                :items="service_list" item-text="name" item-value="id"
                                :label="$t('occasion.service')"></v-select>
                        </div>
                        <div class="col-lg-6">
                            <v-select outlined v-model="resturant_id" :reverse="direction"
                                :items="resturant_list" item-text="name" item-value="id"
                                :label="$t('occasion.restaurant')"></v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="description_ar"
                                :error-messages="descriptionArErrors" :label="$t('occasion.description_ar')"></v-textarea>
                        </div>
                        <div class="col-lg-6">
                            <v-textarea outlined :reverse="direction" required v-model="description_en"
                                :error-messages="descriptionEnErrors" :label="$t('occasion.description_en')"></v-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-file-input chips truncate-length="15" append-icon="mdi-camera" prepend-icon=""
                                accept="image/png, image/jpeg, image/bmp" :error-messages="main_imageErrors"
                                :label="$t('occasion.main_image')" :reverse="direction" v-model="main_image" outlined
                                @change="changeMainImage"></v-file-input>
                            <v-img :src="display_main_image" max-height="200"
                                style="margin-bottom: 30px;border-radius: 15px" v-if="display_main_image != ''">
                            </v-img>
                        </div>
                        <div class="col-lg-6">
                            <v-file-input chips multiple counter append-icon="mdi-camera" prepend-icon=""
                                :label="$t('occasion.images')" :reverse="direction" v-model="images"
                                accept="image/png, image/jpeg, image/bmp" outlined @change="changeImages"></v-file-input>
                            <v-carousel cycle height="200" show-arrows-on-hover style="border-radius: 15px"
                                v-if="display_images.length != 0">
                                <v-carousel-item v-for="(item, index) in display_images" :key="index">
                                    <v-img :src="item.url" aspect-ratio="1.7" max-height="200" style="border-radius: 15px">
                                        <v-icon class="close-icon" small v-if="item.id != -1"
                                            @click="deleteImage(item, index)">mdi-close</v-icon>
                                    </v-img>
                                </v-carousel-item>
                            </v-carousel>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-dialog ref="start_date_dialog" v-model="start_date_modal" :return-value.sync="start_date"
                                persistent width="290px" color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined :reverse="direction" v-model="start_date"
                                        :label="$t('occasion.start_date')" readonly v-bind="attrs" class="my-date" v-on="on"
                                        append-icon="mdi-calendar" :error-messages="startDateErrors"></v-text-field>
                                </template>
                                <v-date-picker v-model="start_date" scrollable :allowed-dates="allowedDates">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="start_date_modal = false">
                                        {{ $t('close') }}
                                    </v-btn>
                                    <v-btn text color="primary" @click="start_date_btn()">
                                        {{ $t('ok') }}
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </div>
                        <div class="col-lg-6">
                            <v-dialog ref="start_time_dialog" v-model="start_time_modal" :return-value.sync="start_time"
                                persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="my-time" outlined :reverse="direction" v-model="start_time"
                                        :label="$t('occasion.start_time')" append-icon="mdi-clock-time-four-outline"
                                        readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-time-picker format="24hr" v-if="start_time_modal" v-model="start_time" full-width>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="start_time_modal = false">
                                        {{ $t('close') }}
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.start_time_dialog.save(start_time)">
                                        {{ $t('ok') }}
                                    </v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-dialog ref="end_date_dialog" v-model="end_date_modal" :return-value.sync="end_date"
                                persistent width="290px" color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined :reverse="direction" v-model="end_date"
                                        :disabled="end_date_disabled" :label="$t('occasion.end_date')" readonly
                                        v-bind="attrs" class="my-date" v-on="on" append-icon="mdi-calendar"
                                        :error-messages="endDateErrors"></v-text-field>
                                </template>
                                <v-date-picker v-model="end_date" scrollable :allowed-dates="allowedDates2">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="end_date_modal = false">
                                        {{ $t('close') }}
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.end_date_dialog.save(end_date)">
                                        {{ $t('ok') }}
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </div>
                        <div class="col-lg-6">
                            <v-dialog ref="end_time_dialog" v-model="end_time_modal" :return-value.sync="end_time"
                                persistent width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="my-time" outlined :reverse="direction" v-model="end_time"
                                        :disabled="end_time_disabled" :label="$t('occasion.end_time')"
                                        append-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-time-picker format="24hr" v-if="end_time_modal" v-model="end_time" full-width>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="end_time_modal = false">
                                        {{ $t('close') }}
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.end_time_dialog.save(end_time)">
                                        {{ $t('ok') }}
                                    </v-btn>
                                </v-time-picker>
                            </v-dialog>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="price"
                                :error-messages="priceErrors" :label="$t('occasion.price')"></v-text-field>
                        </div>
                    </div>
                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 30px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue"
import { validationMixin } from 'vuelidate'
import { required, numeric, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'

export default {
    name: 'AddOccasions',
    components: {
        Breadcrumbs,
    },
    mixins: [validationMixin],

    data: () => ({
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        bread_add_en: [
            {
                text: 'Occasions',
                disabled: true,
                href: '',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/occasions/add',
            },
        ],
        bread_add_ar: [
            {
                text: 'مناسبات',
                disabled: true,
                href: '',
            },

            {
                text: 'إضافة',
                disabled: false,
                href: '/occasions/add',
            },
        ],
        bread_edit_en: [
            {
                text: 'Occasions',
                disabled: true,
                href: '',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        bread_edit_ar: [
            {
                text: 'مناسبات',
                disabled: true,
                href: '',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            },
        ],
        direction: false,
        lang: '',
        route: '',
        name_ar: '',
        name_en: '',
        description_ar: '',
        description_en: '',
        price: '',
        status: 1,
        images: [],
        main_image: null,
        id: null,
        edit: false,
        load: false,
        city_id: '',
        city_list: [],
        address_ar: '',
        address_en: '',
        resturant_list: [],
        resturant_id: '',
        service_list: [],
        service_id: '',

        start_date_modal: false,
        start_date: '',
        end_date_modal: false,
        end_date: '',

        start_time_modal: false,
        start_time: '',
        end_time_modal: false,
        end_time: '',
        data: null,
        phone: '',
        display_main_image: '',
        display_images: [],
        orginal_images: []
    }),
    validations: {
        name_ar: { required },
        name_en: { required },
        price: { required, numeric },
        description_ar: { required },
        description_en: { required },
        main_image: {
            required: requiredIf(function (item) {
                return !item.edit
            })
        },
        city_id: { required },
        address_ar: { required },
        address_en: { required },
        start_date: { required },
        end_date: { required },
        start_time: { required },
        end_time: { required },
        phone: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },

    },
    watch:{
        service_id(val) {
            if (val != '')
                this.resturant_id = ''
        },
        resturant_id(val){
            if (val != '')
                this.service_id = ''
        }
    },

    computed: {
        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.required && errors.push('This feild is required')
            !this.$v.phone.numeric && errors.push('Please enter correct phone number')
            !this.$v.phone.minLength && errors.push('Please enter correct phone number')
            !this.$v.phone.maxLength && errors.push('Please enter correct phone number')
            return errors
        },
        nameArErrors() {
            const errors = []
            if (!this.$v.name_ar.$dirty) return errors
            !this.$v.name_ar.required && errors.push('This field is required')
            return errors
        },
        nameEnErrors() {
            const errors = []
            if (!this.$v.name_en.$dirty) return errors
            !this.$v.name_en.required && errors.push('This field is required')
            return errors
        },
        priceErrors() {
            const errors = []
            if (!this.$v.price.$dirty) return errors
            !this.$v.price.required && errors.push('This field is required')
            !this.$v.price.numeric && errors.push('Please input only numbers ')
            return errors
        },
        descriptionArErrors() {
            const errors = []
            if (!this.$v.description_ar.$dirty) return errors
            !this.$v.description_ar.required && errors.push('This field is required')
            return errors
        },
        descriptionEnErrors() {
            const errors = []
            if (!this.$v.description_en.$dirty) return errors
            !this.$v.description_en.required && errors.push('This field is required')
            return errors
        },
        main_imageErrors() {
            const errors = []
            if (!this.$v.main_image.$dirty) return errors
            !this.$v.main_image.required && errors.push('This field is required')
            return errors
        },
        cityErrors() {
            const errors = []
            if (!this.$v.city_id.$dirty) return errors
            !this.$v.city_id.required && errors.push('This field is required')
            return errors
        },
        addressArErrors() {
            const errors = []
            if (!this.$v.address_ar.$dirty) return errors
            !this.$v.address_ar.required && errors.push('This field is required')
            return errors
        },
        addressEnErrors() {
            const errors = []
            if (!this.$v.address_en.$dirty) return errors
            !this.$v.address_en.required && errors.push('This field is required')
            return errors
        },
        startDateErrors() {
            const errors = []
            if (!this.$v.start_date.$dirty) return errors
            !this.$v.start_date.required && errors.push('This field is required')
            return errors
        },
        endDateErrors() {
            const errors = []
            if (!this.$v.end_date.$dirty) return errors
            !this.$v.end_date.required && errors.push('This field is required')
            return errors
        },
        startTimeErrors() {
            const errors = []
            if (!this.$v.start_time.$dirty) return errors
            !this.$v.start_time.required && errors.push('This field is required')
            return errors
        },
        endTimeErrors() {
            const errors = []
            if (!this.$v.end_time.$dirty) return errors
            !this.$v.end_time.required && errors.push('This field is required')
            return errors
        },
        // resturant_disabled() {
        //     return !this.service_id == '';
        // },
        // service_disabled() {
        //     return !this.resturant_id == '';
        // },
        end_date_disabled() {
            return this.start_date == '';
        },
        end_time_disabled() {
            return this.start_time == '';
        },
    },

    methods: {
        deleteImage(image, index) {
            console.log("delete image: " + image.id + " item : " + this.id)
            this.axios.delete(this.$store.state.url + "/api/v1/admin/occasion/" + this.id + "/media/" + image.id + "?lang=en")
                .then(res => {
                    console.log(res.data)
                    this.display_images.splice(index, 1)
                    this.data.media.splice(index, 1)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        changeImages(event) {
            console.log(event)
            if (this.edit) {
                this.display_images = [...this.data.media]
                if (event.length != 0) {
                    event.forEach(item => {
                        this.display_images.push({
                            url: URL.createObjectURL(item),
                            id: -1
                        })
                    })
                }
                else {
                    this.display_images = [...this.data.media]
                }
            }
            else {
                this.display_images = []
                if (event.length != 0) {
                    event.forEach(item => {
                        this.display_images.push({
                            url: URL.createObjectURL(item),
                            id: -1
                        })
                    })
                }
                else {
                    this.display_images = []
                }
            }
        },
        changeMainImage(event) {
            if (event != null)
                this.display_main_image = URL.createObjectURL(event);
            else {
                if (this.edit)
                    this.display_main_image = this.data.media_urls.url
                else
                    this.display_main_image = ''
            }
        },
        start_date_btn() {
            this.$refs.start_date_dialog.save(this.start_date)
            this.end_date = ''
        },
        allowedDates: val => val >= new Date().toJSON().slice(0, 10),
        allowedDates2(val) {
            return val >= this.start_date
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                this.initData()
            }
        },
        sendImages() {
            console.log("images send ...")
            const formData = new FormData()
            this.images.forEach((item, index) => {
                formData.append(`media[${index}]`, item)
            })
            if (this.main_image != null)
                formData.append('main', this.main_image)
            formData.append('lang', "en")
            this.axios.post(this.$store.state.url + "/api/v1/admin/occasion/" + this.id + "/media", formData)
                .then(res => {
                    this.response = true
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'occasions-list' })
                    }
                })
                .catch(error => {
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                })
        },
        initData() {
            const formData = new FormData()
            formData.append("lang", "en")

            formData.append("title[en]", this.name_en)
            formData.append("title[ar]", this.name_ar)

            formData.append("description[en]", this.description_en)
            formData.append("description[ar]", this.description_ar)

            formData.append("price", this.price)
            formData.append("city_id", this.city_id)
            formData.append("phone", this.phone)


            formData.append("location[en]", this.address_en)
            formData.append("location[ar]", this.address_ar)

            formData.append("featured", 0)
            formData.append("status", 1)

            formData.append("resturant_id", this.resturant_id)
            formData.append("service_id", this.service_id)

            const start = this.start_date + " " + this.start_time
            const end = this.end_date + " " + this.end_time
            console.log(start)
            console.log(end)
            formData.append("start_date", start)
            formData.append("end_date", end)
            if (this.edit == true) {
                this.editData(formData)
            }
            else {
                this.addData(formData)
            }
        },
        addData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/occasion", formData)
                .then(res => {
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        this.id = res.data.list.id
                        console.log("occasion: " + this.id)
                        this.sendImages()
                    }
                    console.log(res.data)
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                })
        },
        getCity() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/cities?lang=" + this.lang)
                .then(res => {
                    this.city_list = res.data.list
                });
        },
        getResturant() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/resturants?lang=" + this.lang)
                .then(res => {
                    this.resturant_list = res.data.list
                });
        },
        getService() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/services?lang=" + this.lang)
                .then(res => {
                    this.service_list = res.data.list
                });
        },
        initEdit() {
            this.route = this.$route.name
            if (this.$route.name == "edit-occasions") {
                this.id = this.$route.params.id
                this.edit = true
                this.axios.get(this.$store.state.url + "/api/v1/admin/occasion/" + this.id + "?lang=ar")
                    .then(res => {
                        this.load = true
                        this.data = res.data.list
                        this.name_en = this.data.title_en
                        this.name_ar = this.data.title_ar
                        this.description_en = this.data.description_en
                        this.description_ar = this.data.description_ar
                        this.address_en = this.data.location_en
                        this.address_ar = this.data.location_ar
                        this.price = parseInt(this.data.price)
                        this.city_id = this.data.city.id
                        if (this.data.resturant != null)
                            this.resturant_id = this.data.resturant.id
                        else if (this.data.service != null)
                            this.service_id = this.data.service.id


                        this.start_date = this.data.start_date.slice(0, 10)
                        this.end_date = this.data.end_date.slice(0, 10)

                        this.start_time = this.data.start_date.slice(11, 16)
                        this.end_time = this.data.end_date.slice(11, 16)
                        this.phone = this.data.phone

                        this.display_main_image = this.data.media_urls.url
                        this.display_images = [...this.data.media]

                        console.log(res.data.list)
                    })
            }
        },
        editData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/occasion/" + this.id, formData)
                .then(res => {
                    console.log(res.data)
                    if (res.data.status == "Success") {
                        console.log("edit occasion: " + this.id)
                        this.sendImages()
                    }
                    console.log(res.data)
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                })
        }
    },
    mounted() {
        this.initDir()
        this.initEdit()
        this.getCity()
        this.getResturant()
        this.getService()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);

.vue-map {
    border-radius: 20px;
}
</style>
