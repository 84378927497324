<template>
  <v-app :class="[isAr ? 'ar' : 'en']">
    <div :class="[isCollapsed ? 'collapsed' : 'not-collapsed']" v-if="!['login'].includes($route.name)">
      <v-main class="internal-main">
        <sidebar />
        <navbar />
        <router-view />
      </v-main>
    </div>
    <div v-else>
      <v-main class="external-main">
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import Navbar from '@/components/Navbar.vue';
export default {
  name: 'App',
  components: {
    Sidebar,
    Navbar
  },
  data: () => ({
    lang: '',
  }),
  computed: {
    isAr() {
      this.lang = localStorage.getItem("lang")
      let htmlElement = document.documentElement
      if (this.lang == 'ar') {
        htmlElement.setAttribute('lang', 'ar')
        return true
      }
      else {
        htmlElement.setAttribute('lang', 'en')
        return false
      }
    },
    isCollapsed() {
      return this.$store.state.isCollapsed
    }
  },
  created() {
    this.lang = localStorage.getItem("lang")
    this.$store.state.token = sessionStorage.getItem("token")
    // console.log(this.$store.state.token)
    let self = this
    if (this.lang == null)
      localStorage.setItem("lang", "en")
    this.axios.defaults.headers.common["Authorization"] = `Bearer ${this.$store.state.token}`
    this.axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      console.log(error.response.data.returnedCode)
      if (error.response.data.returnedCode == 404) {
        self.$router.push({ name: 'not-found' });
        // window.location.href = "/not-found"
      }
      if (error.response.data.message === "UnAuthenticated") {
        self.$router.push({ name: 'login' });
        // window.location.href = "/login"
      }
      else if (error.response.data.message == 'You do not have permissions to perform this action'){
        self.$router.push({ name: 'no-permission' });
        // window.location.href = "/no-permission"
      }
      else return error
    })
  },
};
</script>

<style lang="scss">
html:lang(ar) * {
  letter-spacing: 0 !important;
}

.theme--light.v-application {
  background: #f2f2f2 !important;
}

// .v-main__wrap {
//   background-color: #f2f2f2;
// }

@media (max-width: 767px) {
  html:lang(en) .internal-main {
    padding-left: 65px !important;
  }

  html:lang(ar) .internal-main {
    padding-right: 65px !important;
  }
}

@media (min-width: 767px) {
  html:lang(en) .collapsed {
    padding-left: 65px;
    transition: 0.3s padding-left ease;
  }

  html:lang(en) .not-collapsed {
    padding-left: 300px;
    transition: 0.3s padding-left ease;
  }

  html:lang(ar) .collapsed {
    padding-right: 65px;
    transition: 0.3s padding-right ease;
  }

  html:lang(ar) .not-collapsed {
    padding-right: 300px;
    transition: 0.3s padding-right ease;
  }
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(assets/font/Poppins-Regular.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Almarai";
  src: local("Almarai"),
    url(assets/font/Almarai-Regular.ttf) format("truetype");
}

.en {
  font-family: "Poppins-Regular", sans-serif !important;
  direction: ltr;
}

.ar {
  font-family: 'Almarai', sans-serif !important;
  direction: rtl;
}
</style>
