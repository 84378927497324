<template>
    <div id="add-state" class="add-state add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en' && this.route == 'add-state'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar' && this.route == 'add-state'" />
        <breadcrumbs :breadcrumbs="bread_edit_en" v-if="lang == 'en' && this.route == 'edit-state'" />
        <breadcrumbs :breadcrumbs="bread_edit_ar" v-if="lang == 'ar' && this.route == 'edit-state'" />
        <div class="card">
            <div class="card-header" v-if="this.route == 'add-state'">
                {{ $t('state.add') }}
                <router-link to="/state/list">
                    <v-btn color="primary" outlined>
                        {{ $t('state.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-header" v-else-if="this.route == 'edit-state'">
                {{ $t('state.edit') }}
                <router-link to="/state/list">
                    <v-btn color="primary" outlined>
                        {{ $t('state.list') }}
                    </v-btn>
                </router-link>
            </div>
            <div class="card-body">
                <v-form v-if="(this.route == 'add-state' && !load) || (this.route == 'edit-state' && load)">
                    <v-text-field outlined :reverse="direction" required v-model="name_en" :error-messages="nameEnErrors"
                        :label="$t('state.name_en')"></v-text-field>
                    <v-text-field outlined :reverse="direction" required v-model="name_ar" :error-messages="nameArErrors"
                        :label="$t('state.name_ar')"></v-text-field>
                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 15px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import Breadcrumbs from "@/components/Breadcrumbs.vue"
export default {
    name: 'AddState',
    components: {
        Breadcrumbs
    },
    mixins: [validationMixin],

    data: () => ({
        name_en: '',
        name_ar: '',
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,
        timeout: 3000,
        bread_add_en: [
            {
                text: 'States',
                disabled: true,
                href: '',
            },
            {
                text: 'Add',
                disabled: false,
                href: '/state/add',
            },
        ],
        bread_add_ar: [
            {
                text: 'الولايات',
                disabled: true,
                href: '',
            },
            {
                text: 'إضافة',
                disabled: false,
                href: '/state/add',
            },
        ],
        bread_edit_en: [
            {
                text: 'States',
                disabled: true,
                href: '',
            },
            {
                text: 'Edit',
                disabled: true,
                href: '',
            },
        ],
        bread_edit_ar: [
            {
                text: 'الولايات',
                disabled: true,
                href: '',
            },
            {
                text: 'تعديل',
                disabled: true,
                href: '',
            },
        ],
        direction: false,
        lang: '',
        edit: false,
        route: '',
        id: '',
        load: false,
    }),

    validations: {
        name_en: { required, minLength: minLength(3) },
        name_ar: { required, minLength: minLength(3) },
    },
    computed: {
        nameEnErrors() {
            const errors = []
            if (!this.$v.name_en.$dirty) return errors
            !this.$v.name_en.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_en.required && errors.push('Name is required')
            return errors
        },
        nameArErrors() {
            const errors = []
            if (!this.$v.name_ar.$dirty) return errors
            !this.$v.name_ar.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_ar.required && errors.push('Name is required')
            return errors
        },
    },

    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('name_en', this.name_en)
            formData.append('name_ar', this.name_ar)
            if (this.edit == true) {
                this.editData()
            }
            else {
                this.addData(formData)
            }
        },
        addData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/countries", formData)
                .then(res => {
                    console.log(res.data)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'state-list' })
                    }
                }).catch(error => {
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        editData() {
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const params = new URLSearchParams()
            params.append('name_en', this.name_en)
            params.append('name_ar', this.name_ar)
            this.axios.put(this.$store.state.url + "/api/v1/admin/countries/" + this.id, params, config)
                .then(res => {
                    console.log(res)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'state-list' })
                    }
                }).catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        initEdit() {
            this.route = this.$route.name
            if (this.$route.name == "edit-state") {
                this.id = this.$route.params.id
                this.edit = true
                this.axios.get(this.$store.state.url + "/api/v1/admin/countries/" + this.id)
                    .then(res => {
                        this.load = true
                        console.log(res.data.list)
                        this.name_ar = res.data.list.name_ar
                        this.name_en = res.data.list.name_en
                    })
            }
        },
    },
    mounted() {
        this.initDir()
        this.initEdit()
    }
};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
