<template>
    <b-navbar style=" justify-content: space-between;">
        <div class="row" style="padding: 0 10px;">
            <b-nav-item-dropdown>
                <template #button-content>
                    <b-icon icon="person-fill"></b-icon>
                </template>
                <b-dropdown-item @click="profile()">{{$t('profile')}}</b-dropdown-item>
                <b-dropdown-item @click="logout()">{{$t('logout')}}</b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- <b-nav-item-dropdown class="notification">
                <template #button-content>
                    <v-badge v-if="count != 0" :content="count" style="top: -22px;left: 35px;" bordered overlap
                        color="green"></v-badge>
                    <b-icon icon="bell-fill" @click="see()"></b-icon>
                </template>
                <div class="row" style="justify-content: space-between; flex-wrap: nowrap; align-items: center;">
                    <h5 style="margin: 0 37px">{{ $t('notification.notification') }}</h5>
                    <router-link to="/notification/my-notification">
                        <v-btn style="margin: 0 37px;" text color="secondary">{{ $t('notification.see_all') }}</v-btn>
                    </router-link>
                </div>
                <v-divider></v-divider>
                <b-dropdown-item v-for="(item, index) in data" :key="index" v-if="data.length != 0">
                    <div>
                        <h6 style="font-weight: bold">{{ item.title }}</h6>
                        <p style=" margin-bottom: 0px;font-size: 15px">
                            {{ item.body }}
                        </p>
                    </div>
                </b-dropdown-item>
                <div class="row" v-if="this.data.length == 0" style=" margin: 0 25px 10px; flex-direction: column; align-items: center;justify-content: center;">
                    <b-icon icon="exclamation-circle"></b-icon>
                    <p style="margin-top: 15px">
                        {{ $t('notification.no_data') }}
                    </p>
                </div>
            </b-nav-item-dropdown> -->
        </div>
        <b-nav-item-dropdown>
            <template #button-content>
                <div style="display: flex; align-items: center; justify-content: center; column-gap: 5px;">
                    <b-icon icon="globe"></b-icon>
                    {{ display_lang }}
                </div>
            </template>
            <b-dropdown-item @click="handleChange('ar')" v-if="lang == 'en'">العربية</b-dropdown-item>
            <b-dropdown-item @click="handleChange('en')" v-if="lang == 'ar'">English</b-dropdown-item>
        </b-nav-item-dropdown>
    </b-navbar>
</template>

<script>

export default {
    name: 'Navbar',

    data: () => ({
        lang: "",
        display_lang: "",
        id: null,
        load: false,
        data: '',
        count: 0,
    }),
    methods: {
        handleChange(selectedLang) {
            localStorage.setItem('lang', selectedLang)
            window.location.reload()
        },
        logout() {
            // const formData = new FormData()
            // formData.append('id', this.id)
            // console.log(this.id)
            this.axios.post(this.$store.state.url + "/api/v1/auth/logout")
                .then(res => {
                    console.log(res.data)
                    if (res.data.message == 'User successfully signed out') {
                        this.removesessionStorage()
                        this.$router.replace({ name: 'login' })
                    }
                });
        },
        removesessionStorage() {
            sessionStorage.removeItem("token")
            sessionStorage.removeItem("id")
            sessionStorage.removeItem("name")
            sessionStorage.removeItem("email")
            sessionStorage.removeItem("role_id")
            sessionStorage.removeItem("permission")
            sessionStorage.setItem("auth", false)
        },
        profile() {
            this.$router.replace({ name: 'profile' })
        },
        getNotification() {
            this.axios.get(this.$store.state.url + "/api/user-notifications")
                .then(res => {
                    // console.log(res.data)
                    this.load = true
                    if (res.data.error == false) {
                        this.data = res.data.data
                        this.data = this.data.filter(item => item.pivot.seen == 0)
                        // console.log(this.data.length)
                        // console.log(this.data)
                    }
                });
        },
        count_unread() {
            this.axios.get(this.$store.state.url + "/api/notification-unseen-count")
                .then(res => {
                    this.count = res.data.data
                    // console.log(res.data.data)
                });
        },
        see() {
            this.axios.post(this.$store.state.url + "/api/notification-seeAll")
                .then(res => {
                    // console.log(res.data)
                });
        }
    },
    mounted() {
        // this.count_unread()
        // this.getNotification()
        this.lang = localStorage.getItem("lang")
        this.id = sessionStorage.getItem('id')
        if (this.lang == "ar")
            this.display_lang = "العربية"
        else
            this.display_lang = "English"
        // console.log(this.lang)
    }
};
</script>

<style lang="scss">
.bi-exclamation-circle{
    color: var(--v-secondary-base);
    font-size: 45px;
    margin-top: 20px
}
.notification .dropdown-item {
    background-color: #eee;
    padding: 10px 25px;
}

.notification .dropdown-menu {
    width: 320px !important
}
.navbar .dropdown-menu{
    text-align: justify;
}
.navbar li {
    display: unset
}

.navbar .dropdown-item.active,
.navbar .dropdown-item:active {
    background-color: var(--v-primary-base);
}

.navbar {
    background-color: white;
    border-bottom: 1px solid #e6e6e6;
    box-shadow: 0 0 10px #e4e4e4;
    padding: 15px 40px !important;
}

.navbar .bi-person-fill {
    font-size: 25px !important;
}

.navbar .bi-bell-fill {
    font-size: 25px !important;
}


.navbar .nav-link {
    padding: 6px 8px !important;
}

.navbar .bi-globe {
    font-size: 25px !important;
    // padding-top: 1px;
}

.navbar-nav .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-size: 18px;
}

.navbar .nav-item {
    margin: 0px 10px;
}

html:lang(ar) .navbar-nav .nav-link {
    direction: rtl;
}

.navbar .dropdown-menu {
    padding: 10px 0px
}

.navbar .dropdown-toggle::after {
    display: none !important;
}

.navbar .nav-link {
    background-color: #e9e9e9;
    border-radius: 20px;
}
</style>