<template>
    <div id="add-menus" class="add-menus add">
        <breadcrumbs :breadcrumbs="bread_add_en" v-if="lang == 'en' && this.route == 'add-menu'" />
        <breadcrumbs :breadcrumbs="bread_add_ar" v-if="lang == 'ar' && this.route == 'add-menu'" />
        <breadcrumbs :breadcrumbs="bread_edit_en" v-if="lang == 'en' && this.route == 'edit-menu'" />
        <breadcrumbs :breadcrumbs="bread_edit_ar" v-if="lang == 'ar' && this.route == 'edit-menu'" />
        <div class="card">
            <div class="card-header" v-if="this.route == 'add-menu'">
                {{ $t('menus.add') }}
                <v-btn color="primary" outlined @click="menuList()">
                    {{ $t('menus.list') }}
                </v-btn>
            </div>
            <div class="card-header" v-else-if="this.route == 'edit-menu'">
                {{ $t('menus.edit') }}
                <v-btn color="primary" outlined @click="menuList()">
                    {{ $t('menus.list') }}
                </v-btn>
            </div>

            <div class="card-body">
                <v-form v-if="(this.route == 'add-menu' && !load) || (this.route == 'edit-menu' && load)">
                    <div class="row">
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_en"
                                :error-messages="nameEnErrors" :label="$t('menus.name_en')"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-text-field outlined :reverse="direction" required v-model="name_ar"
                                :error-messages="nameArErrors" :label="$t('menus.name_ar')"></v-text-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <v-file-input append-icon="mdi-camera" prepend-icon="" :label="$t('menus.image')"
                                accept="image/png, image/jpeg, image/bmp" :reverse="direction" v-model="image" outlined
                                :error-messages="imageErrors" @change="changeImage"></v-file-input>
                            <v-img :src="display_image" max-height="200" contain style="margin-bottom: 30px"
                                v-if="display_image != ''"></v-img>
                        </div>
                        <div class="col-lg-6">
                            <v-select outlined v-model="resturant_id" :reverse="direction" :items="resturant_list" readonly
                                item-text="name" item-value="id" :label="$t('menus.restaurant')"></v-select>
                        </div>
                    </div>
                    <v-btn @click="submit" :disabled="isSubmit && !response" color="primary" light style="margin-top: 15px">
                        <v-progress-circular :size="20" v-if="isSubmit && !response" indeterminate
                            color="white"></v-progress-circular>
                        {{ $t('submit') }}
                    </v-btn>
                    <v-snackbar right top color="green" text v-model="snackbar" :timeout="3000">
                        {{ $t('success_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-snackbar right top color="red" text v-model="error_snackbar" :timeout="3000">
                        {{ $t('error_message') }}
                        <template v-slot:action="{ attrs }">
                            <v-btn color="red" text v-bind="attrs" @click="error_snackbar = false">
                                {{ $t('close') }}
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-form>
                <div class="progress-container" v-else>
                    <v-progress-circular indeterminate color="primary" :size="50"
                        style="margin-top: 100px"></v-progress-circular>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, requiredIf } from 'vuelidate/lib/validators'
import Breadcrumbs from "@/components/Breadcrumbs.vue"
export default {
    name: 'Addmenus',
    components: {
        Breadcrumbs
    },
    mixins: [validationMixin],

    data: () => ({
        name_en: '',
        name_ar: '',
        image: null,
        response: false,
        isSubmit: false,
        snackbar: false,
        error_snackbar: false,

        bread_add_ar: [],
        bread_edit_en: [],
        bread_edit_ar: [],
        direction: false,
        lang: '',
        edit: false,
        route: '',
        id: '',
        load: false,
        resturant_list: [],
        resturant_id: '',
        bread_add_en: [],
        display_image: '',
        orginal_image: ''
    }),
    watch: {
        resturant_id(newVal) {
            this.bread_add_en = [
                {
                    text: 'Restaurant',
                    disabled: false,
                    href: '/resturant/list',
                },
                {
                    text: 'Menu',
                    disabled: true,
                    href: '/menu/list/' + newVal,
                }
            ]
            this.bread_add_ar = [
                {
                    text: 'مطعم',
                    disabled: false,
                    href: '/resturant/list',
                },
                {
                    text: 'قائمة الطعام',
                    disabled: true,
                    href: '/menu/list/' + newVal,
                }
            ]
            this.bread_edit_en = [
                {
                    text: 'Restaurant',
                    disabled: false,
                    href: '/resturant/list',
                },
                {
                    text: 'Menu',
                    disabled: true,
                    href: '/menu/list/' + newVal,
                }
            ]
            this.bread_edit_ar = [
                {
                    text: 'مطعم',
                    disabled: false,
                    href: '/resturant/list',
                },
                {
                    text: 'قائمة الطعام',
                    disabled: true,
                    href: '/menu/list/' + newVal,
                }
            ]
        },
    },

    validations: {
        name_en: { required, minLength: minLength(3) },
        name_ar: { required, minLength: minLength(3) },
        image: {
            required: requiredIf(function (item) {
                return !item.edit
            })
        },
    },

    computed: {
        nameEnErrors() {
            const errors = []
            if (!this.$v.name_en.$dirty) return errors
            !this.$v.name_en.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_en.required && errors.push('Name is required')
            return errors
        },
        nameArErrors() {
            const errors = []
            if (!this.$v.name_ar.$dirty) return errors
            !this.$v.name_ar.minLength && errors.push('Name must be at least 3 characters')
            !this.$v.name_ar.required && errors.push('Name is required')
            return errors
        },
        imageErrors() {
            const errors = []
            if (!this.$v.image.$dirty) return errors
            !this.$v.image.required && errors.push('This field is required')
            return errors
        },
    },

    methods: {
        changeImage(event) {
            if (event != null) {
                this.display_image = URL.createObjectURL(event);
            }
            else {
                this.display_image = this.orginal_image
            }
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        submit() {
            this.$v.$touch()
            // console.log(this.$v.$error)
            if (!this.$v.$error) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('name[en]', this.name_en)
            formData.append('name[ar]', this.name_ar)
            formData.append('lang', "en")
            if (this.image != null)
                formData.append('image', this.image)
            formData.append('resturant_id', this.resturant_id)
            if (this.edit == true) {
                this.editData(formData)
            }
            else {
                this.addData(formData)
            }
        },
        addData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/menus", formData)
                .then(res => {
                    console.log(res.data)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'menu-list', params: { id: this.resturant_id } })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        menuList() {
            this.$router.replace({ name: 'menu-list', params: { id: this.resturant_id } })
        },
        editData(formData) {
            this.axios.post(this.$store.state.url + "/api/v1/admin/menus/" + this.id, formData)
                .then(res => {
                    console.log(res)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                        this.$router.replace({ name: 'menu-list', params: { id: this.resturant_id } })
                    }
                }).catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.response = false
                    this.isSubmit = false
                });
        },
        initEdit() {
            this.id = this.$route.params.id
            this.edit = true
            this.axios.get(this.$store.state.url + "/api/v1/admin/menus/" + this.id)
                .then(res => {
                    this.load = true
                    console.log(res.data.list)
                    this.name_ar = res.data.list.name_ar
                    this.name_en = res.data.list.name_en
                    this.resturant_id = res.data.list.resturant_id
                    this.display_image = res.data.list.media_urls[0]
                    this.orginal_image = res.data.list.media_urls[0]
                })
        },
        getResturant() {
            this.axios.get(this.$store.state.url + "/api/v1/admin/resturants?lang=" + this.lang)
                .then(res => {
                    this.resturant_list = res.data.list
                });
        },

    },
    mounted() {
        this.initDir()
        this.route = this.$route.name
        this.getResturant()
        if (this.route == "edit-menu")
            this.initEdit()
        if (this.route == "add-menu")
            this.resturant_id = this.$route.params.id
    }

};
</script>

<style lang="scss">
@import url(../../assets/Css/main.css);
</style>
