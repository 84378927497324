<template>
  <div>
    <sidebar-menu :rtl="direction" :menu="menu" widthCollapsed="65px" width="300px" @toggle-collapse="onToggleCollapse">
      <template v-slot:header>
        <div class="row logo">
          <div calss="col-lg-3">
            <img src="../assets/img/white.png" alt="Logo" />
          </div>
          <div class="col-lg-9 name-email" style=" font-size: 22px;color: #fff; font-weight: bold;margin-top: 10px">
            Check In Tourism
          </div>
        </div>
        <!-- <div class="row header">
          <div calss="col-lg-4">
            <div class="user-image">
              <b-icon icon="person-fill"></b-icon>
            </div>
          </div>
          <div class="col-lg-5 name-email">
            <p class="name">
              {{ name }}
            </p>
            <P class="email">
              {{ email }}
            </P>
          </div>
        </div> -->
      </template>
    </sidebar-menu>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  data: () => ({
    direction: false,
    name: null,
    email: null,
    lang: null,
    my_permission: [],
    dashboard: {
      href: "/",
      title: "Dashboard",
      icon: "fa fa-dashboard",
      index: '0'
    },
    role: {
      title: "Roles & Permissions",
      icon: "fas fa-user-shield",
      child: [
        {
          href: "/role/list",
          title: "List"
        }
      ],
      index: '1'
    },
    users: {
      title: "Users",
      icon: "fas fa-user-friends",
      child: [
        {
          href: "/user/add",
          title: "Add"
        },
        {
          href: "/user/list",
          title: "List"
        }
      ],
      index: '2'
    },
    state: {
      title: "State",
      icon: "fas fa-globe-asia",
      child: [
        {
          href: "/state/add",
          title: "Add"
        },
        {
          href: "/state/list",
          title: "List"
        }
      ],
      index: '3'
    },
    city: {
      title: "City",
      icon: "fas fa-city",
      child: [
        {
          href: "/city/add",
          title: "Add"
        },
        {
          href: "/city/list",
          title: "List"
        }
      ],
      index: '4'
    },
    attribute: {
      title: "Attribute",
      icon: "fas fa-columns",
      child: [
        {
          href: "/attributes/add",
          title: "Add"
        },
        {
          href: "/attributes/list",
          title: "List"
        }
      ],
      index: '5'
    },
    service_category: {
      title: "Service Category",
      icon: "fas fa-table",
      child: [
        {
          href: "/category/add",
          title: "Add"
        },
        {
          href: "/category/list",
          title: "List"
        }
      ],
      index: '6'
    },
    public_service: {
      title: "Public Service",
      icon: "fas fa-umbrella-beach",
      child: [
        {
          href: "/service/add",
          title: "Add"
        },
        {
          href: "/service/list",
          title: "List"
        }
      ],
      index: '7'
    },
    resturant: {
      title: "Restaurant",
      icon: "fas fa-coffee",
      child: [
        {
          href: "/resturant/add",
          title: "Add"
        },
        {
          href: "/resturant/list",
          title: "List"
        }
      ],
      index: '8'
    },
    occasions: {
      title: "Occasions",
      icon: "fas fa-birthday-cake",
      child: [
        {
          href: "/occasions/add",
          title: "Add"
        },
        {
          href: "/occasions/list",
          title: "List"
        }
      ],
      index: '9'
    },
    tour: {
      title: "Tour Guide & Translator",
      icon: "fas fa-user-tie",
      child: [
        {
          href: "/tour/add",
          title: "Add"
        },
        {
          href: "/tour/list",
          title: "List"
        }
      ],
      index: '10'
    },
    banners: {
      title: "Banners",
      icon: "	fas fa-ad",
      child: [
        {
          href: "/banners/add",
          title: "Add"
        },
        {
          href: "/banners/list",
          title: "List"
        }
      ],
      index: '11'
    },
    customer: {
      title: "Customer",
      icon: "fas fa-users",
      child: [
        {
          href: "/customer/list",
          title: "List"
        }
      ],
      index: '12'
    },





    dashboard_ar: {
      href: "/",
      title: "لوحة التحكم",
      icon: "fa fa-dashboard",
      index: '0'
    },
    role_ar: {
      title: "الادوار والصلاحيات",
      icon: "fas fa-user-shield",
      child: [
        {
          href: "/role/list",
          title: "عرض"
        }
      ],
      index: '1'
    },
    users_ar: {
      title: "المستخدمين",
      icon: "fas fa-user-friends",
      child: [
        {
          href: "/user/add",
          title: "إضافة"
        },
        {
          href: "/user/list",
          title: "عرض"
        }
      ],
      index: '2'
    },
    state_ar: {
      title: "الولايات",
      icon: "fas fa-globe-asia",
      child: [
        {
          href: "/state/add",
          title: "إضافة"
        },
        {
          href: "/state/list",
          title: "عرض"
        }
      ],
      index: '3'
    },
    city_ar: {
      title: "المدن",
      icon: "fas fa-city",
      child: [
        {
          href: "/city/add",
          title: "إضافة"
        },
        {
          href: "/city/list",
          title: "عرض"
        }
      ],
      index: '4'
    },
    attribute_ar: {
      title: "المواصفات",
      icon: "fas fa-columns",
      child: [
        {
          href: "/attributes/add",
          title: "إضافة"
        },
        {
          href: "/attributes/list",
          title: "عرض"
        }
      ],
      index: '5'
    },
    service_category_ar: {
      title: "تصنيف الخدمات",
      icon: "fas fa-table",
      child: [
        {
          href: "/category/add",
          title: "إضافة"
        },
        {
          href: "/category/list",
          title: "عرض"
        }
      ],
      index: '6'
    },
    public_service_ar: {
      title: "خدمات عامة",
      icon: "fas fa-umbrella-beach",
      child: [
        {
          href: "/service/add",
          title: "إضافة"
        },
        {
          href: "/service/list",
          title: "عرض"
        }
      ],
      index: '7'
    },
    resturant_ar: {
      title: "المطاعم",
      icon: "fas fa-coffee",
      child: [
        {
          href: "/resturant/add",
          title: "إضافة"
        },
        {
          href: "/resturant/list",
          title: "عرض"
        }
      ],
      index: '8'
    },
    occasions_ar: {
      title: "مناسبات",
      icon: "fas fa-birthday-cake",
      child: [
        {
          href: "/occasions/add",
          title: "إضافة"
        },
        {
          href: "/occasions/list",
          title: "عرض"
        }
      ],
      index: '9'
    },
    tour_ar: {
      title: "دليل سياحي وترجمة",
      icon: "fas fa-user-tie",
      child: [
        {
          href: "/tour/add",
          title: "إضافة"
        },
        {
          href: "/tour/list",
          title: "عرض"
        }
      ],
      index: '10'
    },
    banners_ar: {
      title: "اعلانات",
      icon: "	fas fa-ad",
      child: [
        {
          href: "/banners/add",
          title: "إضافة"
        },
        {
          href: "/banners/list",
          title: "عرض"
        }
      ],
      index: '11'
    },
    customer_ar: {
      title: "الزبائن",
      icon: "fas fa-users",
      child: [
        {
          href: "/customer/list",
          title: "عرض"
        }
      ],
      index: '12'
    },




    menu: [],
    isAdmin: false
  }),
  methods: {
    onToggleCollapse(collapsed) {
      this.$store.state.isCollapsed = collapsed
    },

    initMenu() {
      if(this.isAdmin)
        this.menu.push(this.dashboard)

      this.my_permission.forEach(item => {
        if (item.name == "roles_manager")
          this.menu.push(this.role)
        else if (item.name == "users_manager")
          this.menu.push(this.users)
        else if (item.name == "attributes_manager")
          this.menu.push(this.attribute)
        else if (item.name == "countries_manager")
          this.menu.push(this.state)
        else if (item.name == "cities_manager")
          this.menu.push(this.city)
        else if (item.name == "resturants_manager")
          this.menu.push(this.resturant)
        else if (item.name == "customers_manager")
          this.menu.push(this.customer)
        else if (item.name == "banners_manager")
          this.menu.push(this.banners)
        else if (item.name == "serviceCategories_manager")
          this.menu.push(this.service_category)
        else if (item.name == "services_manager")
          this.menu.push(this.public_service)
        else if (item.name == "occasions_manager")
          this.menu.push(this.occasions)
        else if (item.name == "tourGuides_manager")
          this.menu.push(this.tour)
      }),
        this.menu.sort((a, b) => a.index - b.index);
    },
    initMenu_ar() {
      if(this.isAdmin)
        this.menu.push(this.dashboard_ar)

      this.my_permission.forEach(item => {
        if (item.name == "roles_manager")
          this.menu.push(this.role_ar)
        else if (item.name == "users_manager")
          this.menu.push(this.users_ar)
        else if (item.name == "attributes_manager")
          this.menu.push(this.attribute_ar)
        else if (item.name == "countries_manager")
          this.menu.push(this.state_ar)
        else if (item.name == "cities_manager")
          this.menu.push(this.city_ar)
        else if (item.name == "resturants_manager")
          this.menu.push(this.resturant_ar)
        else if (item.name == "customers_manager")
          this.menu.push(this.customer_ar)
        else if (item.name == "banners_manager")
          this.menu.push(this.banners_ar)
        else if (item.name == "serviceCategories_manager")
          this.menu.push(this.service_category_ar)
        else if (item.name == "services_manager")
          this.menu.push(this.public_service_ar)
        else if (item.name == "occasions_manager")
          this.menu.push(this.occasions_ar)
        else if (item.name == "tourGuides_manager")
          this.menu.push(this.tour_ar)
      }),
        this.menu.sort((a, b) => a.index - b.index);
    },
    getPer() {
      const role_name = sessionStorage.getItem("name")
      if (role_name == "administrator")
        this.isAdmin = true
      this.my_permission = JSON.parse(sessionStorage.getItem("permission"))
      if (this.lang == "en")
        this.initMenu()
      else
        this.initMenu_ar()
    },
  },
  mounted() {
    this.lang = localStorage.getItem('lang')
    if (this.lang == 'en')
      this.direction = false
    else
      this.direction = true
    this.getPer()
  },
}

</script>


<style lang="scss">
html:lang(en) .v-sidebar-menu.vsm_rtl .vsm--icon {
  margin-left: 10px !important;
  margin-right: 0px !important
}

html:lang(ar) .v-sidebar-menu.vsm_rtl .vsm--icon {
  margin-right: 8px !important;
  margin-left: 10px !important
}

html:lang(en) .logo {
  flex-wrap: nowrap !important;
  margin-left: 3px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
}

html:lang(ar) .logo {
  flex-wrap: nowrap !important;
  margin-right: 7px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.logo img {
  width: 55px
}

@media (max-width: 767px) {
  .v-sidebar-menu {
    box-shadow: 5px 0 10px #a7a6a6;
  }
}

.v-sidebar-menu .bi-person-fill {
  font-size: 50px;
  transition: all .3s;
}

.v-sidebar-menu .header {
  padding: 20px;
  align-items: center;
  color: white;
}

.v-sidebar-menu .name,
.v-sidebar-menu .email {
  margin-bottom: 0px !important
}

.v-sidebar-menu .email {
  font-size: 13px;
}

.v-sidebar-menu .name-email {
  transition: all .1s;
  transition-delay: 5s;
}

.v-sidebar-menu.vsm_collapsed {
  .name-email {
    display: none;
    transition: all .1s;
    transition-delay: 5s;
  }

  .bi-person-fill {
    font-size: 45px;
    transition: all .3s;
  }

  .logo img {
    width: 48px
  }

  html:lang(en) .logo {
    margin-left: 5px !important;
  }

  html:lang(ar) .logo {
    margin-right: 5px !important;
  }

}

.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background-color: transparent !important;
}

.v-sidebar-menu .vsm--scroll-wrapper {
  margin-right: 0px !important;
}

html:lang(ar) .v-sidebar-menu .vsm--scroll-wrapper {
  margin-left: 0px !important;
}

.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  background-color: var(--v-secondary-base) !important;
}


.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover,
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover {
  background-color: var(--v-secondary-base) !important;
}



.v-sidebar-menu.vsm_white-theme .vsm--mobile-bg {
  background-color: #fff !important;
  // border: 1px solid var(--v-primary-base) !important;
}


.v-sidebar-menu.vsm_white-theme .vsm--link {
  color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
  color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1,
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  color: #fff !important;
  background-color: var(--v-secondary-base) !important;
}

.v-sidebar-menu.vsm_collapsed .vsm--link_active .vsm--icon {
  color: #fff !important
}

.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link_level-1:hover .vsm--icon {
  color: var(--v-secondary-base) !important
}

.v-sidebar-menu.v-sidebar-menu.vsm_white-theme.vsm_collapsed .vsm--link_level-1.vsm--link_hover .vsm--icon {
  color: var(--v-secondary-base) !important
}

.v-sidebar-menu.vsm_white-theme.vsm_rtl .vsm--link_level-1.vsm--link_active,
.v-sidebar-menu.vsm_white-theme.vsm_rtl .vsm--link_level-1.vsm--link_exact-active {
  box-shadow: -3px 0px 0px 0px var(--v-secondary-base) inset !important;
}

html:lang(en) .v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
html:lang(en) .v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  box-shadow: 3px 0px 0px 0px var(--v-secondary-base) inset !important;
}

html:lang(ar) .v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
html:lang(ar) .v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  box-shadow: -3px 0px 0px 0px var(--v-secondary-base) inset !important;
}


.v-sidebar-menu .vsm--mobile-bg {
  background-color: var(--v-secondary-base) !important;
}

.v-sidebar-menu {
  background-color: var(--v-primary-base) !important;
}

.v-sidebar-menu .vsm--dropdown .vsm--list {
  background-color: var(--v-primary-base) !important;
}

.v-sidebar-menu .vsm--toggle-btn {
  background-color: var(--v-primary-base) !important;
}
</style>
